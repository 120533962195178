import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import { Tabs, Tab, Tooltip } from "@material-ui/core";

const useStyles = makeStyles({
  tabContainer: {
    "& > div > div > button": {
      backgroundColor: "white",
    },
  },
});

const TabPanel = (props) => {
  const { children, value, index } = props;

  if (value === index) {
    return <div>{children}</div>;
  } else {
    return null;
  }
};

/**
A component to render tabs like dividers in a ring binder.
Each tab name should be unique, and you should have a 1 to 1 mapping of children
to tabs. Use like this:

  <OrderTabs tabs={[ { label: "Lunch", disabled: false }, { label: "Tea", disabled: false }]}>
    <LunchOrder/>
    <TeaOrder />
  </OrderTabs >
**/

export const OrderTabs = (props) => {
  const {
    tabs,
    children,
    disableKeyboardShortcuts,
    onTabSwitch = () => undefined,
    // this is obvs a smell that the tooltip sits at a higher level of abstraction.
    // Arguably tooltip should also go when keyboard shortcuts go.
    showTooltip = true,
  } = props;
  const [value, setValue] = React.useState(0);
  const { tabContainer } = useStyles();

  const switchTabs = (event, newValue) => {
    setValue(newValue);
    onTabSwitch(newValue);
  };

  if (children.length !== tabs.length) {
    throw Error(
      "You must have a tab for every child, and a child for every tab. Check the docs for examples.",
    );
  }

  // Add event listeners for key presses so we can l for lunch and t for tea
  React.useEffect(() => {
    function downHandler({ key }) {
      if (key === "t") {
        setValue(1);
      }
      if (key === "l") {
        setValue(0);
      }
    }

    if (!disableKeyboardShortcuts) {
      window.addEventListener("keydown", downHandler);
      // Remove event listeners on cleanup
      return () => {
        window.removeEventListener("keydown", downHandler);
      };
    }
  }, [disableKeyboardShortcuts]);

  return (
    <React.Fragment>
      <Tabs className={`${tabContainer} hide-for-print`} value={value} onChange={switchTabs}>
        {tabs.map(({ label, disabled }) => {
          const shortcut = { Tea: "T", Lunch: "L" };
          if (showTooltip) {
            return (
              <Tooltip
                key={label}
                enterDelay={400}
                leaveDelay={0}
                title={`Press the '${shortcut[label]}' key to swtich to this tab.`}
                placement="bottom"
              >
                <Tab disableFocusRipple disableRipple label={label} disabled={disabled} />
              </Tooltip>
            );
          } else {
            return (
              <Tab
                key={label}
                disableFocusRipple
                disableRipple
                label={label}
                disabled={disabled}
              />
            );
          }
        })}
      </Tabs>
      {children.map((child, index) => {
        return (
          // This is fine as the order isn't going to change ever... I think.
          <TabPanel key={index} value={value} index={index}>
            {child}
          </TabPanel>
        );
      })}
    </React.Fragment>
  );
};
