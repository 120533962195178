import React from "react";
import { useParams } from "react-router-dom";
import { PageHeading } from "components/PageHeading";
import moment from "moment";
import { CentredErrorIcon } from "components/CentredErrorIcon";
import { DatePickerSection } from "components/DatePickerSection";
import { gql } from "@apollo/client";
import styled from "styled-components";
import { GraphqlQuery } from "graphqlUtils/GraphqlQuery";
import { find } from "lodash";
import pink from "@material-ui/core/colors/pink";
import Numeral from "numeral";
import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@material-ui/core";

const TableWrapper = styled.div`
  background-color: white;
  margin-bottom: 15px;
  margin-top: 15px;
  max-height: 66vh;
  // This gets vertical lines on the columns.
  td,
  th {
    border: 1px solid rgba(224, 224, 224, 1);
  }
`;

const PrintCSS = styled.div`
  @media print {
    orientation: portrait;
    .hide-for-print {
      display: none;
    }
    @page :first {
      margin-top: 0px;
    }
    @page {
      margin: 0px;
    }
  }
`;

const COMPONENT_TOTALS = gql`
  query AllTheTotals($date: Date!) {
    kitchenTotalsForDate(date: $date) {
      id
      name
      total
      tinCounts {
        id
        tinId
        numberOfTins
        tinCapacity
      }
      portions(date: $date) {
        id
        portionSize
        portionUnit
      }
    }
  }
`;

const TINS = gql`
  query Tins {
    tins {
      id
      name
    }
  }
`;

const ReportForDay = (props) => {
  const { date } = props;
  return (
    <GraphqlQuery
      fetchPolicy="network-only"
      query={COMPONENT_TOTALS}
      variables={{ date }}
      withLoading
      withError
    >
      {({ data, error }) => {
        if (error) return <CentredErrorIcon />;

        return (
          <TableContainer component={TableWrapper}>
            <GraphqlQuery variables={{}} query={TINS} withError withLoading>
              {({ data: tinData, error: tinError }) => {
                if (tinError) return <CentredErrorIcon />;
                return (
                  <React.Fragment>
                    <Table style={{ marginBottom: "30px" }} stickyHeader size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell align="center" colSpan={8}>
                            Summary
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Component</TableCell>
                          <TableCell align="center">Single Portion Size</TableCell>
                          <TableCell align="center">Tinned Portions</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {data.kitchenTotalsForDate.map((comp) => {
                          const [firstPortion] = comp.portions;
                          const unit = firstPortion && firstPortion.portionUnit;
                          const size = firstPortion && firstPortion.portionSize;

                          const tinnedPortions = comp.tinCounts.reduce((acc, tinCount) => {
                            if (tinCount) {
                              return acc + tinCount.numberOfTins * tinCount.tinCapacity;
                            } else {
                              return acc;
                            }
                          }, 0);

                          return (
                            <TableRow hover key={comp.id}>
                              <TableCell>
                                <Typography>{comp.name}</Typography>
                              </TableCell>
                              <TableCell align="center">
                                <Typography>
                                  {size} {unit}
                                </Typography>
                              </TableCell>
                              <TableCell align="center">
                                <Typography>{Numeral(tinnedPortions).format("0,0")}</Typography>
                                <Typography style={{ color: pink[500] }}>
                                  {Numeral(tinnedPortions * size).format("0,0.[000]")} {unit}
                                </Typography>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                    <Table stickyHeader size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell align="center" colSpan={tinData.tins.length * 2 + 1}>
                            Tins
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell />
                          {tinData.tins.map((tin) => {
                            return (
                              <TableCell align="center" colSpan={2} key={tin.id}>
                                {tin.name}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                        <TableRow hover>
                          <TableCell>Component</TableCell>
                          {tinData.tins.map((tin) => {
                            return (
                              <React.Fragment key={tin.id}>
                                <TableCell align="center">No. of Tins</TableCell>
                                <TableCell align="center">Tin Weight</TableCell>
                              </React.Fragment>
                            );
                          })}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {data.kitchenTotalsForDate.map((comp) => {
                          const [firstPortion] = comp.portions;
                          const unit = firstPortion && firstPortion.portionUnit;
                          const size = firstPortion && firstPortion.portionSize;

                          return (
                            <TableRow hover key={comp.id}>
                              <TableCell>
                                <Typography>{comp.name}</Typography>
                              </TableCell>
                              {tinData.tins.map((tin) => {
                                const tinCount = find(
                                  comp.tinCounts,
                                  (tc) => tc.tinId === tin.id,
                                );
                                if (tinCount) {
                                  return (
                                    <React.Fragment key={tin.id}>
                                      <TableCell align="center">
                                        <Typography>{tinCount.numberOfTins}</Typography>
                                      </TableCell>
                                      <TableCell align="center">
                                        <Typography>
                                          {Numeral((tinCount.tinCapacity || 0) * size).format(
                                            "0,0.[000]",
                                          )}{" "}
                                          {unit}
                                        </Typography>
                                      </TableCell>
                                    </React.Fragment>
                                  );
                                } else {
                                  return <TableCell align="center" key={tin.id} />;
                                }
                              })}
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </React.Fragment>
                );
              }}
            </GraphqlQuery>
          </TableContainer>
        );
      }}
    </GraphqlQuery>
  );
};

export const KitchenDistributionReport = () => {
  const { date } = useParams();
  const monday = moment(date, "YYYY-MM-DD", true).startOf("isoWeek");
  const dates = [0, 1, 2, 3, 4].map((diff) => {
    // We have to clone because moment is mutable, yuck!
    return monday.clone().add(moment.duration({ days: diff }));
  });

  return (
    <PrintCSS>
      <PageHeading
        maxWidth="lg"
        noHeadingOnPrint
        heading="Kitchen Distribution Report"
        className="hide-for-print"
      >
        <DatePickerSection
          type="weekly"
          maxDate={moment().add(3, "month").startOf("isoWeek").format("YYYY-MM-DD")}
          earliestDate="2020-03-23"
          disableArrowKeys={false}
        />
        {dates.map((d) => {
          return (
            <Paper
              style={{
                padding: "30px",
                marginBottom: "30px",
                pageBreakInside: "avoid",
              }}
              key={d.format("YYYY-MM-DD")}
            >
              <Typography variant="h5">{d.format("dddd Do MMMM")}</Typography>
              <ReportForDay date={d.format("YYYY-MM-DD")} />
            </Paper>
          );
        })}
      </PageHeading>
    </PrintCSS>
  );
};
