import React from "react";
import { ApolloClient } from "@apollo/client/core";
import * as AbsintheSocket from "@absinthe/socket";
import { createAbsintheSocketLink } from "@absinthe/socket-apollo-link";
import { Socket as PhoenixSocket } from "phoenix";
import { onError } from "@apollo/client/link/error";
import { setContext } from "@apollo/client/link/context";
import { useAuth0 } from "./auth0";
import possibleTypes from "possible_types.json";
import { ApolloProvider, InMemoryCache, split, HttpLink, ApolloLink } from "@apollo/client";
import { hasSubscription } from "@jumpn/utils-graphql";

const uri = `${process.env.REACT_APP_BASE_BACKEND_URL}/api`;

// We should probably use BatchHttpLink but burn that bridge later.
const httpLink = new HttpLink({
  uri: uri,
});

export const ApolloSetup = (props) => {
  const { getTokenSilently, loading, logout } = useAuth0();
  const [token, setToken] = React.useState();

  React.useEffect(() => {
    const callAPI = async () => {
      const token = await getTokenSilently();
      setToken(token);
    };
    if (!loading) {
      callAPI();
    }
  }, [loading, getTokenSilently]);

  const setHeaders = setContext(async () => {
    const token = await getTokenSilently();
    return {
      // Setting origin is probably done automatically but meh.
      headers: {
        Origin: window.location.origin,
        authorization: `Bearer ${token}`,
      },
    };
  });

  // 'Afterware' is very similar to a middleware, except that an afterware runs after a request has
  // been made. I think we could leverage this to deal with the situation where a nursery / user is
  // removed from the system, but they still have a local session. The server can return unauthorized
  // then we can catch that in afterware and log them out.

  // This does not actually do anything but log the error to the console, which is fine for dev
  // but is it a good idea for prod? Will it leak info? Who's to say.
  const logErrors = onError((stuff) => {
    const { graphQLErrors, networkError } = stuff;
    if (graphQLErrors && process.env.NODE_ENV !== "production")
      graphQLErrors.forEach(({ message, locations, path }) =>
        console.error(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
        ),
      );
    if (networkError && networkError.statusCode === 401) {
      logout();
      console.error(`[Unauthorized]: ${networkError}`);
    }
    if (networkError) console.error(`[Network error]: ${networkError}`);
  });

  const absintheSocketLink = createAbsintheSocketLink(
    AbsintheSocket.create(
      new PhoenixSocket(process.env.REACT_APP_GRAPHQL_SOCKET_URI, {
        params: () => ({ token }),
      }),
    ),
    // This is onError
    (stuff) => console.error("Absinthe Socket Connection Error!", stuff),
  );

  const link = split(
    (operation) => hasSubscription(operation.query),
    ApolloLink.from([logErrors, setHeaders, absintheSocketLink]),
    ApolloLink.from([logErrors, setHeaders, httpLink]),
  );

  const client = new ApolloClient({
    connectToDevTools: true,
    link,
    cache: new InMemoryCache({ possibleTypes }),
  });

  return <ApolloProvider client={client}>{props.children}</ApolloProvider>;
};
