import React from "react";
import styled from "styled-components";
import { useTheme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";
import { GraphqlQuery } from "graphqlUtils/GraphqlQuery";
import { GraphqlMutation } from "graphqlUtils/GraphqlMutation";
import { H3 } from "components/typography/H3";
import { InfoNextToText } from "components/InfoNextToText";
import { ulid } from "ulid";
import { isEmpty, some } from "lodash";
import {
  DialogTitle,
  DialogContent,
  DialogActions,
  Dialog,
  TextField,
  Typography,
  useMediaQuery,
  Button,
  Grid,
} from "@material-ui/core";
import { CentredErrorIcon } from "components/CentredErrorIcon";
import { LunchMenu } from "containers/menus/LunchMenu";
import { ACTIVE_MEAL_COMPONENTS, CREATE_SUB_MENU } from "containers/menus/queries";
import { TeaMenu } from "containers/menus/TeaMenu";

const Div = styled.div`
  margin-right: 30px;
  margin-top: 30px;
  & button {
    margin-right: 15px;
  }
`;

const useStyles = makeStyles({
  inputStyles: { marginBottom: "15px", width: "75%", maxWidth: "400px" },
  dialog: { padding: "30px 0px 30px 0px" },
  unresolvedCount: { textAlign: "center" },
  heading: { marginBottom: "10px" },
  headingGroup: { width: "100%", maxWidth: "750px", margin: "0px 0 15px 0" },
  marginBottom: { marginBottom: "15px" },
});

export const AddSubMenu = (props) => {
  const { open, onCancel, refetchQueries, onSubmit, menuId } = props;

  const defaultState = {
    meals: [
      {
        dayName: "MONDAY",
        type: "LUNCH",
        componentsWithSubstitutions: [{ id: ulid(), substitutions: [] }],
      },
      {
        dayName: "TUESDAY",
        type: "LUNCH",
        componentsWithSubstitutions: [{ id: ulid(), substitutions: [] }],
      },
      {
        dayName: "WEDNESDAY",
        type: "LUNCH",
        componentsWithSubstitutions: [{ id: ulid(), substitutions: [] }],
      },
      {
        dayName: "THURSDAY",
        type: "LUNCH",
        componentsWithSubstitutions: [{ id: ulid(), substitutions: [] }],
      },
      {
        dayName: "FRIDAY",
        type: "LUNCH",
        componentsWithSubstitutions: [{ id: ulid(), substitutions: [] }],
      },
    ],
  };

  const defaultTeaState = {
    meals: [
      {
        dayName: "MONDAY",
        type: "TEA",
        componentsWithoutSubstitutions: [{ id: ulid(), substitutions: [] }],
      },
      {
        dayName: "TUESDAY",
        type: "TEA",
        componentsWithoutSubstitutions: [{ id: ulid(), substitutions: [] }],
      },
      {
        dayName: "WEDNESDAY",
        type: "TEA",
        componentsWithoutSubstitutions: [{ id: ulid(), substitutions: [] }],
      },
      {
        dayName: "THURSDAY",
        type: "TEA",
        componentsWithoutSubstitutions: [{ id: ulid(), substitutions: [] }],
      },
      {
        dayName: "FRIDAY",
        type: "TEA",
        componentsWithoutSubstitutions: [{ id: ulid(), substitutions: [] }],
      },
    ],
  };

  const [menuName, updateMenuName] = React.useState("");
  const [lunchMenu, setLunchMenuData] = React.useState(defaultState);
  const [teaMenu, setTeaMenuData] = React.useState(defaultTeaState);
  const { inputStyles, dialog, headingGroup, heading, marginBottom } = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const requiredFieldsEmpty =
    !menuName ||
    some(lunchMenu.meals, (meal) =>
      some(meal.componentsWithSubstitutions, (component) => {
        if (isEmpty(component.substitutions)) {
          return !component.mealComponent;
        } else {
          return (
            some(component.substitutions, (sub) => !sub.substitution) || !component.mealComponent
          );
        }
      }),
    ) ||
    some(teaMenu.meals, (meal) =>
      some(meal.componentsWithoutSubstitutions, (component) => {
        return !component.mealComponent;
      }),
    );

  return (
    <Dialog
      transitionDuration={0}
      disableBackdropClick
      disableEscapeKeyDown
      fullScreen={fullScreen}
      fullWidth
      maxWidth="lg"
      open={open}
      PaperProps={{ className: dialog }}
    >
      <DialogTitle disableTypography>
        <Typography variant="h4" component="h4">
          Create A Sub Menu
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid item sm={6}>
            <TextField
              autoFocus
              className={inputStyles}
              required
              variant="outlined"
              label="Enter sub menu name..."
              value={menuName}
              onChange={(event) => updateMenuName(event.target.value)}
            />
          </Grid>
        </Grid>

        <GraphqlQuery
          withLoading
          withError
          // Can this not come from above and just be passed in? Then edit and create
          // can share? Might just have to be clever about
          query={ACTIVE_MEAL_COMPONENTS}
          fetchPolicy="network-only"
          variables={{ active: true }}
        >
          {({ data, error }) => {
            if (error) {
              return <CentredErrorIcon />;
            }
            const { mealComponents } = data;

            return (
              <React.Fragment>
                <Grid container></Grid>
                <Grid item xs={12} className={headingGroup}>
                  <H3 className={heading}>Lunch</H3>
                  <Typography variant="body2">
                    <InfoNextToText />
                    &nbsp;&nbsp;Meal components work in a waterfall manner. If a child cannot have
                    the meal component we will attempt to give them the substitute below it, and
                    so on until we find one that they can have. That means the order of the
                    substitutions is significant.
                  </Typography>
                </Grid>
                <LunchMenu
                  meals={lunchMenu.meals}
                  options={mealComponents}
                  onChange={(stuff) => {
                    setLunchMenuData({ ...lunchMenu, meals: stuff });
                  }}
                />
                <Grid item xs={12} className={marginBottom} />
                <Grid item xs={12} className={headingGroup}>
                  <H3 className={heading}>Tea</H3>
                  <Typography variant="body2">
                    <InfoNextToText />
                    &nbsp;&nbsp; The tea menu doesn&apos;t currently have substitutes, but you can
                    specify the teas available below.
                  </Typography>
                </Grid>
                <TeaMenu
                  meals={teaMenu.meals}
                  options={mealComponents}
                  onChange={(stuff) => {
                    setTeaMenuData({ ...teaMenu, meals: stuff });
                  }}
                />
              </React.Fragment>
            );
          }}
        </GraphqlQuery>
      </DialogContent>
      <DialogActions>
        <GraphqlMutation mutation={CREATE_SUB_MENU} withError>
          {(createSubMenu, { loading }) => {
            const create = async () => {
              const teaMeals = teaMenu.meals.map(
                ({ dayName, type, componentsWithoutSubstitutions }) => {
                  const components = componentsWithoutSubstitutions.map(
                    ({ mealComponent }, columnNumber) => {
                      return {
                        columnNumber,
                        mealComponentId: mealComponent.id,
                        substitutions: [],
                      };
                    },
                  );
                  // Bit confusing but on read they are componentsWithoutSubstitutions but for speed
                  // of development the input remains componentsWithSubstitutions
                  return { dayName, type, componentsWithSubstitutions: components };
                },
              );

              const meals = lunchMenu.meals.map(
                ({ dayName, type, componentsWithSubstitutions }) => {
                  const components = componentsWithSubstitutions.map(
                    ({ mealComponent, substitutions }, columnNumber) => {
                      const subs = substitutions.map(({ order, substitutionId }) => ({
                        order,
                        substitutionId,
                      }));
                      return {
                        columnNumber,
                        mealComponentId: mealComponent.id,
                        substitutions: subs,
                      };
                    },
                  );

                  return { dayName, type, componentsWithSubstitutions: components };
                },
              );

              const variables = {
                input: { name: menuName, menuId, meals: [...meals, ...teaMeals] },
              };

              const result = await createSubMenu({ variables, refetchQueries });
              if (result.data) {
                onSubmit(result.data.createSubMenu.id);
              }
            };

            return (
              <Div>
                <Button disabled={loading} variant="outlined" onClick={onCancel} color="primary">
                  Cancel
                </Button>
                <Button
                  disabled={requiredFieldsEmpty || loading}
                  variant="outlined"
                  onClick={create}
                  color="secondary"
                >
                  Save And Check Unresolved
                </Button>
              </Div>
            );
          }}
        </GraphqlMutation>
      </DialogActions>
    </Dialog>
  );
};
