import { createTheme, responsiveFontSizes } from "@material-ui/core/styles";

// The default Material UI Breakpoints are:
// sm: 600px
// md: 960px
// lg: 1280px
// xl: 1920px
const breakpoints = {
  keys: ["xs", "sm", "md", "lg", "xl"],
  values: {
    xs: 480,
    sm: 600,
    md: 800,
    lg: 1280,
    xl: 1920,
  },
};

export const theme = responsiveFontSizes(
  createTheme({
    breakpoints,
    // This disables transitions which just makes things feel a bit snappier.
    // Also it's faster on mobile.
    transitions: {
      // So we have `transition: none;` everywhere
      create: () => "none",
    },
  }),
);
