import { gql } from "@apollo/client";
import React from "react";
import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
import { InfoNextToText } from "components/InfoNextToText";
import { H3 } from "components/typography/H3";
import { useTheme } from "@material-ui/core/styles";
import { GraphqlInput } from "components/inputs/Inputs";
import { GraphqlMutation } from "graphqlUtils/GraphqlMutation";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { GraphqlQuery } from "graphqlUtils/GraphqlQuery";
import { ulid } from "ulid";
import { some } from "lodash";
import {
  Container,
  DialogTitle,
  DialogContent,
  DialogActions,
  Dialog,
  Typography,
  Chip,
  useMediaQuery,
  Button,
  IconButton,
} from "@material-ui/core";

const UPDATE_MEAL = gql`
  mutation UpdateMealComponent($input: UpdateMealComponentInput!) {
    updateMealComponent(input: $input) {
      id
      name
      active
      sensitivityInformation {
        id
        sensitivityName
        sensitivityId
        mealComponentId
        present
      }
    }
  }
`;

const SET_ACTIVE = gql`
  mutation UpdateMealComponent($input: SetActiveMealInput!) {
    setActiveMealComponent(input: $input) {
      id
      name
      active
      sensitivityInformation {
        id
        sensitivityName
        sensitivityId
        mealComponentId
        present
      }
    }
  }
`;

const DELETE_MEAL = gql`
  mutation DeleteSensitivity($id: ID!) {
    deleteMealComponent(id: $id) {
      id
    }
  }
`;

const useStyles = makeStyles({
  inputStyles: { marginBottom: "15px", width: "66%", marginRight: "30px" },
  nameBlurb: { marginBottom: "20px" },
  container: { paddingTop: "30px", maxWidth: "750px" },
  noBtn: { marginLeft: "50px", maxHeight: "35px" },
  yesBtn: { marginRight: "50px", maxHeight: "35px" },
  chip: { marginRight: "15px", marginBottom: "10px" },
  inactiveBtn: { maxHeight: "40px" },
});

const Form = styled.div`
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: center;
`;

const NextPrev = styled.div`
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: space-between;
  margin-top: 30px;
  margin-bottom: 30px;
`;

const InputWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const MEAL_COMPONENT = gql`
  query MealCoponent($id: ID!) {
    mealComponent(id: $id) {
      id
      name
      active
      sensitivityInformation {
        id
        sensitivityName
        sensitivityId
        mealComponentId
        present
      }
    }
  }
`;

export const EditMealComponentModal = (props) => {
  return (
    <GraphqlQuery
      fetchPolicy="network-only"
      query={MEAL_COMPONENT}
      variables={{ id: props.id }}
      withError
    >
      {({ data, error }) => {
        if (error) {
          return;
        }

        return <MealComponentModal {...props} mealComponent={data.mealComponent} />;
      }}
    </GraphqlQuery>
  );
};

const MealComponentModal = (props) => {
  const { open, onCancel, onSubmit, refetchQueries, mealComponent, sensitivities } = props;
  const { nameBlurb, inputStyles, container, noBtn, yesBtn, chip } = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [mealIndex, setMealIndex] = React.useState(0);
  const [formState, setFormState] = React.useState({});
  const [focused, setFocused] = React.useState(false);
  const [sensitivityList, setSensitivityList] = React.useState(sensitivities);
  const [sensitivityInformation, setSensitivityInformation] = React.useState({});

  React.useEffect(() => {
    const mciSensitivityNames = mealComponent.sensitivityInformation.map(
      ({ sensitivityName }) => sensitivityName,
    );
    const newSens = sensitivities
      .filter(({ name }) => !some(mciSensitivityNames, (sensName) => sensName === name))
      .map(({ id, name }) => ({
        id: ulid(),
        present: null,
        sensitivityId: id,
        mealComponentId: mealComponent.id,
        sensitivityName: name,
      }));

    const mapped = mealComponent.sensitivityInformation.map(
      ({ id, present, sensitivityName, mealComponentId, sensitivityId }) => ({
        id,
        sensitivityName,
        sensitivityId,
        mealComponentId,
        present,
      }),
    );
    const allSensitivityInformation = [...mapped, ...newSens];

    setSensitivityList(allSensitivityInformation);
    setSensitivityInformation(
      mealComponent.sensitivityInformation.reduce((acc, info) => {
        const mealInfo = {
          [info.id]: {
            id: info.id,
            sensitivityName: info.sensitivityName,
            sensitivityId: info.sensitivityId,
            mealComponentId: info.mealComponentId,
            present: info.present,
          },
        };
        return { ...acc, ...mealInfo };
      }, {}),
    );

    setFormState({
      name: mealComponent.name,
      active: mealComponent.active,
      sensitivityInformation: allSensitivityInformation,
    });
  }, [mealComponent, setSensitivityInformation, setSensitivityList, setFormState, sensitivities]);

  // eslint-disable-next-line
  const activeMealComponentInfo = sensitivityList[mealIndex] || {};

  const checked = sensitivityInformation[activeMealComponentInfo.id]
    ? sensitivityInformation[activeMealComponentInfo.id].present
    : null;

  const setActiveComponent = React.useCallback(
    (present) => {
      const mealInfo = {
        [activeMealComponentInfo.id]: {
          sensitivityName: activeMealComponentInfo.sensitivityName,
          sensitivityId: activeMealComponentInfo.sensitivityId,
          mealComponentId: activeMealComponentInfo.mealComponentId,
          present,
        },
      };

      setSensitivityInformation({ ...sensitivityInformation, ...mealInfo });
    },
    [setSensitivityInformation, activeMealComponentInfo, sensitivityInformation],
  );

  const yes = React.useCallback(() => {
    const newMealComponentInformation = formState.sensitivityInformation.map((info) => {
      if (info.id === activeMealComponentInfo.id) {
        return { ...info, present: true };
      } else {
        return info;
      }
    });

    setFormState({ ...formState, sensitivityInformation: newMealComponentInformation });
    setActiveComponent(true);
  }, [setActiveComponent, setFormState, formState, activeMealComponentInfo]);

  const no = React.useCallback(() => {
    const newMealComponentInformation = formState.sensitivityInformation.map((info) => {
      if (info.id === activeMealComponentInfo.id) {
        return { ...info, present: false };
      } else {
        return info;
      }
    });

    setFormState({ ...formState, sensitivityInformation: newMealComponentInformation });
    setActiveComponent(false);
  }, [setActiveComponent, setFormState, formState, activeMealComponentInfo]);

  const maxIndex = sensitivityList.length - 1;
  const onLastPage = mealIndex === maxIndex;

  const next = React.useCallback(() => {
    if (onLastPage) {
      return;
    }
    setMealIndex(mealIndex + 1);
  }, [setMealIndex, onLastPage, mealIndex]);

  const previous = React.useCallback(() => {
    if (mealIndex <= 0) {
      return;
    }
    const minIndex = 0;
    const newIndex = mealIndex - 1;
    if (newIndex > minIndex) {
      setMealIndex(newIndex);
    } else {
      setMealIndex(minIndex);
    }
  }, [setMealIndex, mealIndex]);

  // Add event listeners for key presses so we can left / right arrow through orders
  React.useEffect(() => {
    function downHandler({ key }) {
      if (focused) {
        return;
      }
      if (key === "ArrowLeft") {
        previous();
      }
      if (key === "ArrowRight") {
        next();
      }
      if (key === "y") {
        yes();
      }
      if (key === "n") {
        no();
      }
    }

    if (open) {
      window.addEventListener("keydown", downHandler);
      // Remove event listeners on cleanup
      return () => {
        window.removeEventListener("keydown", downHandler);
      };
    }
  }, [next, previous, open, yes, no, focused]);

  const resetForm = () => {
    setSensitivityInformation({});
    setFormState({});
    setMealIndex(0);
  };

  const sensitivityInfo = Object.entries(sensitivityInformation).map((key_value) => {
    return key_value[1];
  });

  // const inactiveTip = `Making a meal component inactive will prevent it from being able to appear on a menu.`;

  return (
    <Dialog
      onClose={(event, reason) => {
        if (reason !== "backdropClick") return;
      }}
      disableEscapeKeyDown
      fullScreen={fullScreen}
      fullWidth
      maxWidth="md"
      open={open}
    >
      <Container className={container}>
        <DialogTitle disableTypography>
          <Typography variant="h4" component="h4">
            Edit a Meal Component
          </Typography>
        </DialogTitle>
        <DialogContent>
          <InputWrapper>
            <GraphqlInput
              className={inputStyles}
              required
              onFocus={() => setFocused(true)}
              label="Change the name..."
              value={mealComponent.name}
              onBlur={(name) => {
                setFocused(false);
                setFormState({ ...formState, name });
              }}
            />
            {/*            <Tooltip title={inactiveTip} placement="bottom-start">
              <Button
                className={inactiveBtn}
                onClick={() => setFormState({ ...formState, active: !formState.active })}
                variant="outlined"
                color={formState.active ? "primary" : "secondary"}
              >
                Inactive
              </Button>
            </Tooltip>
*/}{" "}
          </InputWrapper>
        </DialogContent>
        <DialogContent className={nameBlurb}>
          <H3>Meal Components</H3>
          <Typography variant="body2" component="p">
            <InfoNextToText /> When you add a meal component you must check all of the currently
            active sensitivities and tell us if the meal contains any of them.
          </Typography>
          <Typography variant="body2" component="p">
            You can use the arrow keys to move through the list.
          </Typography>
        </DialogContent>
        <DialogContent>
          {sensitivityInfo
            .filter(({ present }) => present)
            .map((thing) => {
              const { sensitivityId, sensitivityName } = thing;
              return (
                <Chip
                  clickable={false}
                  className={chip}
                  key={sensitivityId}
                  variant="outlined"
                  color="primary"
                  label={sensitivityName}
                />
              );
            })}
        </DialogContent>
        <DialogContent>
          <Form>
            <H3>
              Does {mealComponent.name} contain {activeMealComponentInfo.sensitivityName}?
            </H3>
          </Form>
        </DialogContent>
        <DialogContent>
          <NextPrev>
            <IconButton disabled={mealIndex <= 0} onClick={previous}>
              <ArrowBackIosIcon />
            </IconButton>
            <Button
              onClick={no}
              variant="contained"
              className={noBtn}
              color={checked === false ? "secondary" : "primary"}
            >
              No
            </Button>
            <Button
              onClick={yes}
              variant="contained"
              className={yesBtn}
              color={checked ? "secondary" : "primary"}
            >
              Yes
            </Button>
            <IconButton disabled={mealIndex >= maxIndex} onClick={next}>
              <ArrowForwardIosIcon />
            </IconButton>
          </NextPrev>
        </DialogContent>
        <DialogActions>
          <GraphqlMutation withLoading withError mutation={DELETE_MEAL}>
            {(deleteMealComponent) => {
              return (
                <Button
                  color="secondary"
                  onClick={async () => {
                    const prompt = window.confirm(
                      `WARNING! This will permanently delete the meal component from the whole system.

This can't be undone and is not allowed if the component is on an existing menu.

Are you sure?
 `,
                    );
                    if (prompt) {
                      const result = await deleteMealComponent({
                        variables: { id: mealComponent.id },
                        refetchQueries,
                      });

                      if (result.data) {
                        onSubmit();
                      }
                    }
                  }}
                >
                  Delete Forever
                </Button>
              );
            }}
          </GraphqlMutation>
          <Button
            onClick={() => {
              resetForm();
              onCancel();
            }}
            color="primary"
          >
            Cancel
          </Button>
          <GraphqlMutation mutation={SET_ACTIVE} withError>
            {(setActive) => {
              return (
                <GraphqlMutation mutation={UPDATE_MEAL} withError>
                  {(updateMeal) => {
                    const setActiveVariables = {
                      input: {
                        id: mealComponent.id,
                        active: formState.active,
                        sensitivityInformation: formState.sensitivityInformation.map(
                          ({ id, present, sensitivityId, mealComponentId }) => ({
                            id,
                            present,
                            sensitivityId,
                            mealComponentId,
                          }),
                        ),
                      },
                    };

                    const variables = {
                      input: {
                        id: mealComponent.id,
                        name: formState.name,
                        sensitivityInformation: formState.sensitivityInformation.map(
                          ({ id, present, sensitivityId, mealComponentId }) => ({
                            id,
                            present,
                            sensitivityId,
                            mealComponentId,
                          }),
                        ),
                      },
                    };

                    const submit = async () => {
                      const data = await updateMeal({ variables, refetchQueries });
                      const result = await setActive({
                        variables: setActiveVariables,
                        refetchQueries,
                      });
                      if (data.data && result.data) {
                        onSubmit();
                      }
                    };

                    return (
                      <Button
                        disabled={some(
                          formState.sensitivityInformation.map(({ present }) => present),
                          (presence) => presence === null,
                        )}
                        onClick={submit}
                        color="primary"
                      >
                        Save
                      </Button>
                    );
                  }}
                </GraphqlMutation>
              );
            }}
          </GraphqlMutation>
        </DialogActions>
      </Container>
    </Dialog>
  );
};
