import React from "react";
import { Route, Switch } from "react-router-dom";
import { NurseryOrderPage } from "pages/nursery/Order";
import { LoggedInRoute } from "containers/routes/LoggedInRoute";
import { FourOhFour } from "components/FourOhFour";
import { Settings } from "pages/nursery/Settings";
import { NurseryDietAdvicePage } from "pages/nursery/NurseryDietAdvicePage";
import { DeliveryFormPage } from "pages/nursery/DeliveryFormPage";
import { NurseryPortionInfo } from "pages/admin/NurseryPortionInfo";
import { ReadOnlyTemperaturesPage } from "pages/deliveryDriver/ReadOnlyTemperaturesPage";
import { CurrentMenu } from "containers/menus/CurrentMenu";
import { HMClosures } from "pages/nursery/HMClosures";
import { PDFHome } from "containers/PDFHome";
import { Faqs } from "pages/nursery/Faqs";

export const NurseryHome = () => {
  return (
    <Switch>
      <LoggedInRoute exact path="/" component={PDFHome} />
      <LoggedInRoute exact path="/faqs" component={Faqs} />
      <LoggedInRoute exact path="/nursery/:nurseryId/date/:date" component={NurseryOrderPage} />
      <LoggedInRoute exact path="/temperatures/date/:date" component={ReadOnlyTemperaturesPage} />
      <LoggedInRoute
        exact
        path="/diet-advice/:nurseryId/date/:date"
        component={NurseryDietAdvicePage}
      />
      <LoggedInRoute
        exact
        path="/delivery-forms/:nurseryId/date/:date"
        component={DeliveryFormPage}
      />
      <LoggedInRoute exact path="/hungry-monsters-closures" component={HMClosures} />
      <LoggedInRoute
        exact
        path="/nursery/:nurseryId/portion-information/date/:date"
        component={NurseryPortionInfo}
      />
      <LoggedInRoute exact path="/current-menu" component={CurrentMenu} />
      <LoggedInRoute exact path="/settings" component={Settings} />
      <Route component={FourOhFour} />
    </Switch>
  );
};
