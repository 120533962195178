import { gql } from "@apollo/client";
import React from "react";
import moment from "moment";
import { groupBy } from "lodash";
import { makeStyles } from "@material-ui/core/styles";
import {
  Typography,
  Grid,
  FormControl,
  Select,
  InputLabel,
  Chip,
  Accordion,
  AccordionSummary,
} from "@material-ui/core";
import { H3 } from "components/typography/H3";
import { GraphqlMutation } from "graphqlUtils/GraphqlMutation";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import { InfoNextToText } from "components/InfoNextToText";
import { DaysOpenForm, DaysClosedForm } from "components/StartEndDateSelector";

const CLOSE_THE_NURSERY = gql`
  mutation SpecifyDaysClosed($input: SpecifyDaysClosedInput!) {
    specifyDaysClosed(input: $input) {
      id
      date
      closedFor
    }
  }
`;

const OPEN_THE_NURSERY = gql`
  mutation SpecifyDaysOpen($input: SpecifyDaysOpenInput) {
    specifyDaysOpen(input: $input) {
      id
      date
      openFor
    }
  }
`;

const useStyles = makeStyles({
  contractFormControl: { width: "100%" },
  h3Style: { marginBottom: "15px" },
  chip: { marginRight: "15px", marginBottom: "15px" },
  closedFor: { marginTop: "15px", width: "257px" },
});

const DELETE_DAYS_CLOSED = gql`
  mutation DeleteDaysClosed($input: SpecifyDaysClosedInput!) {
    deleteDaysClosed(input: $input) {
      id
      date
      closedFor
    }
  }
`;

const DELETE_DAYS_OPEN = gql`
  mutation DeleteDaysOpen($input: SpecifyDaysOpenInput) {
    deleteDaysOpen(input: $input) {
      id
      date
      openFor
    }
  }
`;

const UPDATE_CONTRACT = gql`
  mutation UpdateContract($input: UpdateNurseryInput!) {
    updateNursery(input: $input) {
      id
      contractType
    }
  }
`;

const Permanent = (props) => {
  const { refetchQueries, nursery } = props;
  const { h3Style, chip } = useStyles();
  // This means we have to sort it later by meh.
  const groupedDates = groupBy(nursery.daysClosed, ({ date }) =>
    moment(date).format("MMMM YYYY"),
  );

  return (
    <Grid container spacing={1}>
      <Grid className={h3Style} item xs={12}>
        <H3 id="day-closed">Days Closed</H3>
        <GraphqlMutation mutation={CLOSE_THE_NURSERY}>
          {(mutation, { loading }) => {
            const onSubmit = async (state) => {
              const variables = { input: { nurseryId: nursery.id, ...state } };
              const result = await mutation({ variables, refetchQueries });
              return result;
            };
            return <DaysClosedForm disableSubmit={loading} onSubmit={onSubmit} />;
          }}
        </GraphqlMutation>
      </Grid>
      <Grid className={h3Style} item xs={12}>
        <Typography variant="body1" component="p">
          {nursery.name} will not auto-submit or receive order reminders for the days specified
          below.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        {Object.entries(groupedDates)
          .sort((left, right) => {
            // We can compare any date in each as we care about comparing the month and year only
            // Just so vile. Fuck you javascript with your fucking shit sorting capabilities.
            return moment(left[1][0].date).isSameOrBefore(moment(right[1][0].date)) ? 1 : -1;
          })
          .map(([groupName, datesClosed]) => {
            const sortedDays = datesClosed.sort((left, right) => {
              // Just so vile. Fuck you javascript with your fucking shit sorting capabilities.
              return moment(left.date).isAfter(moment(right.date), "day") ? -1 : 1;
            });

            const lunchClosures = sortedDays.filter(({ closedFor }) => closedFor === "lunch");
            const teaClosures = sortedDays.filter(({ closedFor }) => closedFor === "tea");
            const bothClosures = sortedDays.filter(
              ({ closedFor }) => closedFor === "lunch_and_tea",
            );

            return (
              <Accordion className={h3Style} key={groupName}>
                <AccordionSummary expandIcon={<ExpandLessIcon />}>
                  <Typography variant="button">{groupName}</Typography>
                </AccordionSummary>
                {[lunchClosures, teaClosures, bothClosures].map((daysClosed, index) => {
                  const label = ["Lunch Closures", "Tea Closures", "Lunch and Tea Closures"];
                  return (
                    <React.Fragment key={index}>
                      <Typography style={{ marginLeft: "16px" }} variant="button">
                        {label[index]}
                      </Typography>
                      <div
                        style={{ paddingLeft: "16px", paddingRight: "16px", marginTop: "10px" }}
                      >
                        <Grid className={h3Style} item>
                          {daysClosed.map((dayClosed) => {
                            const { date, id, closedFor } = dayClosed;
                            return (
                              <GraphqlMutation key={id} mutation={DELETE_DAYS_CLOSED}>
                                {(deleteThing, { loading }) => {
                                  const variables = {
                                    input: {
                                      nurseryId: nursery.id,
                                      startDate: date,
                                      closedFor: closedFor.toUpperCase(),
                                      endDate: date,
                                    },
                                  };

                                  const onDelete = async () => {
                                    await deleteThing({ variables, refetchQueries });
                                  };
                                  return (
                                    <Chip
                                      clickable={false}
                                      disabled={loading}
                                      className={chip}
                                      variant="outlined"
                                      color="primary"
                                      label={moment(date).format("MMMM Do YYYY")}
                                      onDelete={onDelete}
                                    />
                                  );
                                }}
                              </GraphqlMutation>
                            );
                          })}
                        </Grid>
                      </div>
                    </React.Fragment>
                  );
                })}
              </Accordion>
            );
          })}
      </Grid>
    </Grid>
  );
};

const Temporary = (props) => {
  const { refetchQueries, nursery } = props;
  const { h3Style, chip } = useStyles();
  // This means we have to sort it later by meh.
  const groupedDates = groupBy(nursery.daysOpen, ({ date }) => moment(date).format("MMMM YYYY"));

  return (
    <Grid container spacing={3}>
      <Grid className={h3Style} item xs={12}>
        <H3>Days Open</H3>
        <GraphqlMutation mutation={OPEN_THE_NURSERY}>
          {(mutation, { loading }) => {
            const onSubmit = async (state) => {
              const variables = { input: { nurseryId: nursery.id, ...state } };
              const result = await mutation({ variables, refetchQueries });
              return result;
            };
            return <DaysOpenForm disableSubmit={loading} onSubmit={onSubmit} />;
          }}
        </GraphqlMutation>
      </Grid>
      <Grid className={h3Style} item xs={12}>
        <Typography variant="body1" component="p">
          {nursery.name} will receive order reminders for the day(s) specified below. Auto
          submission will be switched on for these days also.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        {Object.entries(groupedDates)
          .sort((left, right) => {
            // We can compare any date in each as we care about comparing the month and year only
            // Just so vile. Fuck you javascript with your fucking shit sorting capabilities.
            return moment(left[1][0].date).isSameOrBefore(moment(right[1][0].date)) ? 1 : -1;
          })
          .map(([groupName, datesClosed]) => {
            const sortedDays = datesClosed.sort((left, right) => {
              // Just so vile. Fuck you javascript with your fucking shit sorting capabilities.
              return moment(left.date).isAfter(moment(right.date), "day") ? -1 : 1;
            });

            const lunchOpens = sortedDays.filter(({ openFor }) => openFor === "lunch");
            const teaOpens = sortedDays.filter(({ openFor }) => openFor === "tea");
            const bothOpens = sortedDays.filter(({ openFor }) => openFor === "lunch_and_tea");

            return (
              <Accordion className={h3Style} key={groupName}>
                <AccordionSummary expandIcon={<ExpandLessIcon />}>
                  <Typography variant="button">{groupName}</Typography>
                </AccordionSummary>
                {[lunchOpens, teaOpens, bothOpens].map((daysOpen, index) => {
                  const label = ["Lunch Openings", "Tea Openings", "Lunch and Tea Openings"];
                  return (
                    <React.Fragment key={index}>
                      <Typography style={{ marginLeft: "16px" }} variant="button">
                        {label[index]}
                      </Typography>
                      <div
                        style={{ paddingLeft: "16px", paddingRight: "16px", marginTop: "10px" }}
                      >
                        <Grid className={h3Style} item>
                          {daysOpen.map((dayOpen) => {
                            const { date, id, openFor } = dayOpen;
                            return (
                              <GraphqlMutation key={id} mutation={DELETE_DAYS_OPEN}>
                                {(deleteThing, { loading }) => {
                                  const variables = {
                                    input: {
                                      nurseryId: nursery.id,
                                      openFor: openFor.toUpperCase(),
                                      startDate: date,
                                      endDate: date,
                                    },
                                  };

                                  const onDelete = async () => {
                                    await deleteThing({ variables, refetchQueries });
                                  };
                                  return (
                                    <Chip
                                      disabled={loading}
                                      className={chip}
                                      variant="outlined"
                                      color="primary"
                                      label={moment(date).format("MMMM Do YYYY")}
                                      onDelete={onDelete}
                                    />
                                  );
                                }}
                              </GraphqlMutation>
                            );
                          })}
                        </Grid>
                      </div>
                    </React.Fragment>
                  );
                })}
              </Accordion>
            );
          })}
      </Grid>
    </Grid>
  );
};

export const ContractSelect = (props) => {
  const { onChange, contractType, className, nullable } = props;

  return (
    <FormControl className={className}>
      <InputLabel required>Contract type</InputLabel>
      <Select
        native
        value={contractType || ""}
        onChange={(event) => onChange(event.target.value)}
      >
        {nullable && <option value={null}></option>}
        <option value="PERMANENT">Permanent</option>
        <option value="TEMPORARY">Temporary</option>
      </Select>
    </FormControl>
  );
};

export const Contract = (props) => {
  const { nursery, refetchQueries, showContractSelect = true } = props;
  const { contractFormControl } = useStyles();
  return (
    <Grid container spacing={3}>
      {showContractSelect && (
        <React.Fragment>
          <Grid item xs={12} sm={6} md={4}>
            <GraphqlMutation mutation={UPDATE_CONTRACT} withError>
              {(updateContract) => {
                const onChange = async (contractType) => {
                  const variables = { input: { id: nursery.id, contractType } };
                  await updateContract({ variables });
                };
                return (
                  <ContractSelect
                    className={contractFormControl}
                    onChange={onChange}
                    contractType={nursery.contractType}
                  />
                );
              }}
            </GraphqlMutation>
          </Grid>
          <Grid item xs={12} sm={6} md={8}>
            <Typography variant="body2" component="p">
              <InfoNextToText /> Changing the contract type will affect future orders only. If you
              have created an order already for a given week, you will still receive reminders for
              that order, and it will still auto submit.
            </Typography>
          </Grid>
        </React.Fragment>
      )}
      <Grid item md={12}>
        {nursery.contractType === "PERMANENT" && (
          <Permanent refetchQueries={refetchQueries} nursery={nursery} />
        )}
        {nursery.contractType === "TEMPORARY" && (
          <Temporary refetchQueries={refetchQueries} nursery={nursery} />
        )}
      </Grid>
    </Grid>
  );
};
