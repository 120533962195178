import { gql } from "@apollo/client";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { PageHeading } from "components/PageHeading";
import { GraphqlQuery } from "graphqlUtils/GraphqlQuery";
import { GraphqlMutation } from "graphqlUtils/GraphqlMutation";
import { H2 } from "components/typography/H2";
import WarningIcon from "@material-ui/icons/Warning";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import { AddDriverModal } from "containers/users/AddDriverModal";
import { AddAdminModal } from "containers/users/AddAdminModal";
import {
  Table,
  TableContainer,
  IconButton,
  TableCell,
  TableBody,
  TableRow,
  Link as MaterialUiLink,
  TableHead,
  Checkbox,
  Chip,
  Button,
} from "@material-ui/core";

const TableWrapper = styled.div`
  background-color: white;
  margin-bottom: 30px;
  max-height: 450px;
`;

const Center = styled.div`
  display: flex;
  justify-content: center;
`;

const ADMINS = gql`
  query AdminUsers {
    admins {
      id
      role
      email
      name
      nurseryId
      active
    }
  }
`;

const DELIVERY_DRIVERS = gql`
  query DriverUsers {
    deliveryDrivers {
      id
      role
      email
      name
      active
    }
  }
`;
const NURSERY_USERS = gql`
  query NurseryUsers {
    nurseryUsers {
      id
      role
      email
      name
      active
      areRemindersOn
      nursery {
        id
        name
      }
    }
  }
`;

const DELETE_NURSERY_USER = gql`
  mutation DeleteNurseryUser($id: ID!) {
    deleteNurseryContact(id: $id) {
      id
    }
  }
`;

const DELETE_DRIVER = gql`
  mutation DeleteDeliveryDriver($id: ID!) {
    deleteDeliveryDriver(id: $id) {
      id
    }
  }
`;

const DELETE_ADMIN_USER = gql`
  mutation DeleteAdmin($id: ID!) {
    deleteAdmin(id: $id) {
      id
    }
  }
`;

const RE_INVITE_USER = gql`
  mutation ReInviteUser($id: ID!) {
    reinviteUser(id: $id)
  }
`;

const useStyles = makeStyles({
  addBtn: { marginBottom: "30px" },
  deleteBtn: { paddingTop: "0px", paddingBottom: "0px" },
});

const TOGGLE_ACTIVE = gql`
  mutation ToggleActive($id: ID!) {
    toggleActive(id: $id) {
      id
      role
      email
      name
      active
      areRemindersOn
      nursery {
        id
        name
      }
    }
  }
`;

const NurseryUsersTable = () => {
  const { deleteBtn } = useStyles();
  return (
    <GraphqlQuery query={NURSERY_USERS} variables={{}} withLoading withError>
      {({ data, error }) => {
        if (error) {
          return (
            <Center>
              <WarningIcon />
            </Center>
          );
        }
        return (
          <TableContainer component={TableWrapper}>
            <Table stickyHeader size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Re-send Invites</TableCell>
                  <TableCell>Nursery</TableCell>
                  <TableCell>Reminders on?</TableCell>
                  <TableCell>Toggle Active</TableCell>
                  <TableCell>Delete</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.nurseryUsers.map((user) => {
                  const { email, name, id, areRemindersOn, nursery } = user;
                  return (
                    <TableRow key={id}>
                      <TableCell>{name}</TableCell>
                      <TableCell>
                        <MaterialUiLink rel="noopener noreferrer" href={`mailto:${email}`}>
                          {email}
                        </MaterialUiLink>
                      </TableCell>
                      <TableCell>
                        <GraphqlMutation mutation={RE_INVITE_USER} withError withLoading>
                          {(resSendConfirmation, { loading }) => {
                            const onClick = async () => {
                              await resSendConfirmation({ variables: { id } });
                            };
                            return (
                              <Button
                                disabled={loading}
                                size="small"
                                color="primary"
                                variant="outlined"
                                onClick={onClick}
                              >
                                resend
                              </Button>
                            );
                          }}
                        </GraphqlMutation>
                      </TableCell>
                      <TableCell>
                        <Link to={`/nursery/${nursery.id}`}>
                          <MaterialUiLink component="p">{nursery.name}</MaterialUiLink>
                        </Link>
                      </TableCell>
                      <TableCell align="center">
                        <Chip
                          clickable={false}
                          size="small"
                          variant="outlined"
                          label={areRemindersOn ? "On" : "Off"}
                          color={areRemindersOn ? "primary" : "secondary"}
                        />
                      </TableCell>
                      <TableCell>
                        <GraphqlMutation mutation={TOGGLE_ACTIVE}>
                          {(mutation, { loading }) => {
                            return (
                              <Checkbox
                                disabled={loading}
                                checked={user.active}
                                onClick={() => {
                                  mutation({
                                    variables: { id },
                                  });
                                }}
                              ></Checkbox>
                            );
                          }}
                        </GraphqlMutation>
                      </TableCell>
                      <TableCell>
                        <GraphqlMutation mutation={DELETE_NURSERY_USER}>
                          {(mutation) => {
                            return (
                              <IconButton
                                className={deleteBtn}
                                onClick={() => {
                                  const confirm = window.confirm(
                                    `This will delete ${name} from HM permanently meaning they can't log in at all.`,
                                  );
                                  if (confirm) {
                                    mutation({
                                      variables: { id },
                                      refetchQueries: [{ query: NURSERY_USERS }],
                                    });
                                  }
                                }}
                              >
                                <DeleteIcon />
                              </IconButton>
                            );
                          }}
                        </GraphqlMutation>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        );
      }}
    </GraphqlQuery>
  );
};

const AdminUsersTable = () => {
  const { deleteBtn } = useStyles();
  return (
    <GraphqlQuery query={ADMINS} variables={{}} withLoading withError>
      {({ data, error }) => {
        if (error) {
          return (
            <Center>
              <WarningIcon />
            </Center>
          );
        }
        return (
          <TableContainer component={TableWrapper}>
            <Table stickyHeader size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Delete</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.admins.map((user) => {
                  const { email, name, id } = user;
                  return (
                    <TableRow key={id}>
                      <TableCell>{name}</TableCell>
                      <TableCell>
                        <MaterialUiLink rel="noopener noreferrer" href={`mailto:${email}`}>
                          {email}
                        </MaterialUiLink>
                      </TableCell>
                      <TableCell>
                        <GraphqlMutation mutation={DELETE_ADMIN_USER}>
                          {(mutation) => {
                            return (
                              <IconButton
                                className={deleteBtn}
                                onClick={() => {
                                  const confirm = window.confirm(
                                    `This will delete ${name} from HM permanently meaning they can't log in at all.`,
                                  );
                                  if (confirm) {
                                    mutation({
                                      variables: { id },
                                      refetchQueries: [{ query: ADMINS }],
                                    });
                                  }
                                }}
                              >
                                <DeleteIcon />
                              </IconButton>
                            );
                          }}
                        </GraphqlMutation>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        );
      }}
    </GraphqlQuery>
  );
};

const DeliveryDriversTable = () => {
  const { deleteBtn } = useStyles();

  return (
    <GraphqlQuery query={DELIVERY_DRIVERS} variables={{}} withLoading withError>
      {({ data, error }) => {
        if (error) {
          return (
            <Center>
              <WarningIcon />
            </Center>
          );
        }
        return (
          <TableContainer component={TableWrapper}>
            <Table stickyHeader size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Role</TableCell>
                  <TableCell>Delete</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.deliveryDrivers.map((user) => {
                  const { email, name, id } = user;
                  return (
                    <TableRow key={id}>
                      <TableCell>{name}</TableCell>
                      <TableCell>
                        <MaterialUiLink rel="noopener noreferrer" href={`mailto:${email}`}>
                          {email}
                        </MaterialUiLink>
                      </TableCell>
                      <TableCell>
                        <GraphqlMutation mutation={DELETE_DRIVER}>
                          {(mutation) => {
                            return (
                              <IconButton
                                className={deleteBtn}
                                onClick={() => {
                                  const confirm = window.confirm(
                                    `This will delete ${name} from HM permanently meaning they can't log in at all`,
                                  );
                                  if (confirm) {
                                    mutation({
                                      variables: { id },
                                      refetchQueries: [{ query: DELIVERY_DRIVERS }],
                                    });
                                  }
                                }}
                              >
                                <DeleteIcon />
                              </IconButton>
                            );
                          }}
                        </GraphqlMutation>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        );
      }}
    </GraphqlQuery>
  );
};

export const UserManagement = () => {
  const [driverModal, setDriverModal] = React.useState(false);
  const openDriverModal = () => setDriverModal(true);
  const closeDriverModal = () => setDriverModal(false);

  const [adminModal, setAdminModal] = React.useState(false);
  const openAdminModal = () => setAdminModal(true);
  const closeAdminModal = () => setAdminModal(false);

  const { addBtn } = useStyles();

  return (
    <PageHeading heading="User Management">
      <H2>Delivery Drivers</H2>
      <DeliveryDriversTable />
      <Button
        className={addBtn}
        variant="outlined"
        color="primary"
        aria-label="add driver"
        onClick={openDriverModal}
      >
        Add Delivery Driver&nbsp;&nbsp;
        <AddIcon />
      </Button>
      <AddDriverModal
        open={driverModal}
        onCancel={closeDriverModal}
        onSubmit={closeDriverModal}
        refetchQueries={[{ query: DELIVERY_DRIVERS }]}
      />
      <H2>Admins</H2>
      <AdminUsersTable />
      <Button
        className={addBtn}
        variant="outlined"
        color="primary"
        aria-label="add admin"
        onClick={openAdminModal}
      >
        Add Admin&nbsp;&nbsp;
        <AddIcon />
      </Button>
      <AddAdminModal
        open={adminModal}
        onCancel={closeAdminModal}
        onSubmit={closeAdminModal}
        refetchQueries={[{ query: ADMINS }]}
      />
      <H2>Nursery Users</H2>
      <NurseryUsersTable />
    </PageHeading>
  );
};
