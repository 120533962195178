import { useSubscription } from "@apollo/client";

/**
A custom hook that is a thin wrapper around Apollo. It ensures that we can capture errors though so
we don't have to do that in every component and means if Apollo change their API (again) we can just
update here.

See also the GraphqlSubscription component which uses this and optionally renders an error and a loading
state.

### Examples

  useSubscription({ subscription: MY_SUBSCRIPTION, variables: {} })
**/

export function useGraphqlSubscription(args) {
  const { subscription, variables, onSubscriptionData } = args;
  const result = useSubscription(subscription, { variables, onSubscriptionData });
  return result;
}
