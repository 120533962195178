import React from "react";
import { Container, Paper, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  paper: { padding: "50px", textAlign: "center" },
  weirdFlexButOkay: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
  },
});

export const FourOhFour = () => {
  const { paper, weirdFlexButOkay } = useStyles();

  return (
    <Container maxWidth="md" className={weirdFlexButOkay}>
      <Paper className={paper}>
        <Typography variant="h5">
          No page with that url can be found, did you mistype it?
        </Typography>
      </Paper>
    </Container>
  );
};
