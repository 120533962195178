import React from "react";
import { PageHeading } from "components/PageHeading";
import moment from "moment";
import { GraphqlMutation } from "graphqlUtils/GraphqlMutation";
import { sortBy, find } from "lodash";
import { GraphqlQuery } from "graphqlUtils/GraphqlQuery";
import { gql } from "@apollo/client";
import { useParams } from "react-router-dom";
import { CentredErrorIcon } from "components/CentredErrorIcon";
import { DatePickerSection } from "components/DatePickerSection";
import { useTheme } from "@material-ui/core/styles";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import styled from "styled-components";
import pink from "@material-ui/core/colors/pink";
import { useGraphqlSubscription } from "graphqlUtils/useGraphqlSubscription";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  Dialog,
  useMediaQuery,
  TableRow,
  Typography,
  DialogContent,
  IconButton,
  DialogTitle,
  Link,
  DialogActions,
  Tooltip,
  Button,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";
import { InfoNextToText } from "components/InfoNextToText";
import { Loading } from "components/Loading";

const NURSERIES_WITH_DELIVERIES = gql`
  query NurseriesWithDeliveriesForDay($date: Date!) {
    nurseriesWithDeliveriesForDay(date: $date) {
      id
      name
      lunchDelivery(date: $date) {
        id
        portioned
        # These are LDIs - lunch delivery items. So the count here is really the number of
        # ordered items.
        regularMealItems(date: $date) {
          id
          count
          mealComponent {
            id
          }
          lunchDeliveryPortions {
            id
            count
            tinId
            tinName
          }
        }
      }
    }
  }
`;

// Really this is packed and not portioned. But it returns a list of nurseries so the
// response needs to match exactly to the query above ^^
const PORTIONED_SUBSCRIPTION = gql`
  subscription PortionedNurseriesSubscription($date: Date!) {
    portionedNurseriesSubscription(date: $date) {
      id
      name
      lunchDelivery(date: $date) {
        id
        portioned
        # These are LDIs - lunch delivery items. So the count here is really the number of
        # ordered items.
        regularMealItems(date: $date) {
          id
          count
          mealComponent {
            id
          }
          lunchDeliveryPortions {
            id
            count
            tinId
            tinName
          }
        }
      }
    }
  }
`;

const TableWrapper = styled.div`
  background-color: white;
  margin-bottom: 15px;
  margin-top: 15px;
  max-height: 66vh;
  // This gets vertical lines on the columns.
  td,
  th {
    border: 1px solid rgba(224, 224, 224, 1);
  }
`;

const TINS = gql`
  query Tins {
    tins {
      id
      name
    }
  }
`;

const REGULAR_MEALS = gql`
  query PastRegularLunchMealForDate($date: Date!) {
    pastRegularLunchMealForDate(date: $date) {
      id
      date
      type
      pastAvailableMealComponents {
        id
        mealComponent {
          id
          name
          portions(date: $date) {
            id
            tinType
            portionSize
            portionUnit
            tinId
            count
          }
        }
      }
    }
  }
`;

const SET_PORTIONED = gql`
  mutation SetPortioned($id: ID!) {
    setLunchDeliveryPortioned(id: $id) {
      id
      portioned
    }
  }
`;

// This is all such a mess. Soz.

const PortionInformationTable = (props) => {
  const { tins, deliveryItems } = props;
  const tinPortion = (item, tinId) => {
    return find(item.lunchDeliveryPortions, (portion) => portion.tinId === tinId);
  };
  const componentPortion = (item, tinId) => {
    return find(item.mealComponent.portions, (portion) => portion.tinId === tinId);
  };

  return (
    <TableContainer component={TableWrapper}>
      <Table stickyHeader size="small">
        <TableHead>
          <TableRow>
            <TableCell>Meal Component</TableCell>
            <TableCell align="center">Portion Size</TableCell>
            {tins.map(({ name, id }) => {
              return (
                <TableCell align="center" key={id}>
                  {name} Tin
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {deliveryItems
            /* We only want to show the portioned HOT components */
            .filter((di) => {
              const firstPortion = di.mealComponent.portions[0];
              return (
                di.lunchDeliveryPortions.length > 0 &&
                firstPortion &&
                firstPortion.tinType === "round_up"
              );
            })
            .map((item) => {
              /* They should all be the same so getting the first should be fine. */
              const firstPortion = item.mealComponent.portions[0];
              return (
                <TableRow hover key={item.id}>
                  <TableCell>{item.mealComponent.name}</TableCell>
                  <TableCell align="center">
                    {firstPortion && firstPortion.portionSize && firstPortion.portionUnit
                      ? `${firstPortion.portionSize} ${firstPortion.portionUnit}`
                      : "-"}
                  </TableCell>
                  {tins.map((tin) => {
                    const currentTinPortion = tinPortion(item, tin.id);
                    const currentComponentPortion = componentPortion(item, tin.id);

                    if (currentTinPortion && currentComponentPortion) {
                      return (
                        <React.Fragment key={tin.id}>
                          {currentTinPortion ? (
                            <TableCell align="center" key={currentTinPortion.id}>
                              <Tooltip
                                enterDelay={200}
                                leaveDelay={0}
                                title={`This tin fits ${currentComponentPortion.count} portions of ${item.mealComponent.name} per tin`}
                              >
                                {currentTinPortion.count > 0 ? (
                                  <Typography variant="body2">
                                    <span style={{ fontSize: "16px", color: pink[500] }}>
                                      {currentTinPortion.count}
                                    </span>{" "}
                                    x {currentComponentPortion.count} portions
                                  </Typography>
                                ) : (
                                  <Typography variant="body2">-</Typography>
                                )}
                              </Tooltip>
                            </TableCell>
                          ) : (
                            <TableCell align="center" key={tin.id}>
                              -
                            </TableCell>
                          )}
                        </React.Fragment>
                      );
                    } else {
                      return (
                        <React.Fragment key={tin.id}>
                          <TableCell align="center"> - </TableCell>
                        </React.Fragment>
                      );
                    }
                  })}
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const PORTION_INFO = gql`
  query DeliveryForNursery($nurseryId: ID!, $date: Date!) {
    deliveryForNursery(nurseryId: $nurseryId, date: $date) {
      ... on NoDelivery {
        message
      }
      ... on Delivery {
        id
        lunchDelivery {
          id
          regularMealItems(date: $date) {
            id
            count
            lunchDeliveryPortions {
              id
              count
              tinId
              tinName
              lunchDeliveryItemId
            }
            mealComponent {
              id
              name
              portions(date: $date) {
                id
                portionSize
                portionUnit
                tinType
                tinId
                count
              }
            }
          }
        }
      }
    }
  }
`;

const NurseryPortionBreakdown = (props) => {
  const { date, nursery } = props;
  return (
    <GraphqlQuery fetchPolicy="network-only" variables={{}} query={TINS} withError withLoading>
      {({ data: tinData, error: tinError }) => {
        if (tinError) return <CentredErrorIcon />;
        const tins = sortBy(tinData.tins, (t) => t.name);
        return (
          <GraphqlQuery
            variables={{ date, nurseryId: nursery.id }}
            query={PORTION_INFO}
            withError
            withLoading
          >
            {({ data, error, loading }) => {
              if (error) return <CentredErrorIcon />;
              if (loading) return <Loading />;
              const { deliveryForNursery } = data;

              switch (deliveryForNursery.__typename) {
                case "Delivery":
                  return (
                    <React.Fragment>
                      <Typography variant="h5" component="h5" style={{ marginBottom: "15px" }}>
                        Regular Meals
                      </Typography>
                      <PortionInformationTable
                        tins={tins}
                        deliveryItems={deliveryForNursery.lunchDelivery.regularMealItems}
                      />
                    </React.Fragment>
                  );
                case "NoDelivery":
                  return (
                    <Typography>
                      <InfoNextToText />
                      &nbsp; There are no deliveries for today.
                    </Typography>
                  );
                default:
                  return <CentredErrorIcon />;
              }
            }}
          </GraphqlQuery>
        );
      }}
    </GraphqlQuery>
  );
};

const NurseryRow = (props) => {
  const { nursery, openModal } = props;
  const { lunchDelivery } = nursery;
  const { portioned } = lunchDelivery;
  const textColor = portioned ? "textSecondary" : "primary";
  const [disabled, setDisabled] = React.useState(false);

  return (
    <TableRow hover={!portioned}>
      <TableCell onClick={openModal}>
        <Link color={textColor}>{nursery.name}</Link>
      </TableCell>
      <GraphqlMutation mutation={SET_PORTIONED} withError withLoading={false}>
        {(mutation, { loading }) => {
          const onChange = async () => {
            // If we are disabled we already pressed, so return. That means we send one
            // request at a time and wait for it to finish before sending another.
            if (disabled) return;
            setDisabled(true);
            await mutation({ variables: { id: lunchDelivery.id } });
            setDisabled(false);
          };
          return (
            <TableCell align="center" onClick={onChange}>
              <Checkbox
                disabled={loading || disabled}
                disableRipple
                variant="outlined"
                checked={portioned}
                onChange={(e) => e.preventDefault()}
              />
            </TableCell>
          );
        }}
      </GraphqlMutation>
    </TableRow>
  );
};

const NextPrev = styled.div`
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: space-between;
  margin-bottom: 30px;
`;

const Div = styled.div`
  display: flex;
  flex-direction: row;
  align-content: center;
`;

const PortionNurseryModal = (props) => {
  const { open, onCancel, startingNurseryIndex, nurseries } = props;
  const { date } = useParams();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [currentIndex, setCurrentIndex] = React.useState(0);
  const maxIndex = nurseries.length - 1;
  const minIndex = 0;
  const [disabled, setDisabled] = React.useState(false);

  const next = React.useCallback(() => {
    if (currentIndex >= maxIndex) return;
    const newIndex = currentIndex + 1;
    if (newIndex < maxIndex) {
      setCurrentIndex(newIndex);
    } else {
      setCurrentIndex(maxIndex);
    }
  }, [currentIndex, setCurrentIndex, maxIndex]);

  const previous = React.useCallback(() => {
    if (currentIndex <= 0) return;

    const newIndex = currentIndex - 1;
    if (newIndex > minIndex) {
      setCurrentIndex(newIndex);
    } else {
      setCurrentIndex(minIndex);
    }
  }, [setCurrentIndex, currentIndex, minIndex]);

  React.useEffect(() => {
    setCurrentIndex(startingNurseryIndex);
  }, [setCurrentIndex, startingNurseryIndex]);

  React.useEffect(() => {
    function downHandler({ key }) {
      if (key === "ArrowLeft") {
        previous();
      }
      if (key === "ArrowRight") {
        next();
      }
    }

    if (open) {
      window.addEventListener("keydown", downHandler);
      // Remove event listeners on cleanup
      return () => {
        window.removeEventListener("keydown", downHandler);
      };
    }
  }, [next, previous, open]);

  const currentNursery = nurseries[currentIndex];

  return (
    <Dialog
      scroll="paper"
      disableBackdropClick
      disableEscapeKeyDown
      fullWidth
      maxWidth="xl"
      fullScreen={fullScreen}
      open={open}
    >
      <DialogContent>
        <DialogTitle disableTypography>
          <Div>
            <Typography variant="h4" component="h4" style={{ marginRight: "15px" }}>
              {currentNursery.name} Portion Info
            </Typography>
            <GraphqlMutation mutation={SET_PORTIONED} withError withLoading={false}>
              {(mutation, { loading }) => {
                const onChange = async () => {
                  if (disabled) return;
                  setDisabled(true);
                  await mutation({ variables: { id: currentNursery.lunchDelivery.id } });
                  setDisabled(false);
                };
                return (
                  <FormControlLabel
                    label="Portioned?"
                    control={
                      <Checkbox
                        disabled={loading || disabled}
                        variant="outlined"
                        checked={currentNursery.lunchDelivery.portioned}
                        onChange={onChange}
                      />
                    }
                  />
                );
              }}
            </GraphqlMutation>
          </Div>
        </DialogTitle>
      </DialogContent>
      <DialogContent>
        <NextPrev>
          <IconButton disabled={currentIndex <= 0} onClick={previous}>
            <ArrowBackIosIcon />
          </IconButton>
          <div style={{ width: "100%" }}>
            <NurseryPortionBreakdown date={date} nursery={currentNursery} />
          </div>
          <IconButton disabled={currentIndex >= maxIndex} onClick={next}>
            <ArrowForwardIosIcon />
          </IconButton>
        </NextPrev>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onCancel} color="primary">
          close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const BorderedCell = styled(TableCell)`
  border: 1px solid rgba(224, 224, 224, 1);
`;

export const PackingReport = () => {
  const { date } = useParams();
  const [open, setModalOpen] = React.useState(false);
  const [startingNurseryIndex, setStartingNurseryIndex] = React.useState();
  const closeModal = () => {
    setStartingNurseryIndex(null);
    setModalOpen(false);
  };
  const openModal = (nurseryIndex) => () => {
    setStartingNurseryIndex(nurseryIndex);
    setModalOpen(true);
  };

  useGraphqlSubscription({
    subscription: PORTIONED_SUBSCRIPTION,
    variables: { date },
    onSubscriptionData: ({ client, subscriptionData }) => {
      client.writeQuery({
        query: NURSERIES_WITH_DELIVERIES,
        variables: { date },
        data: {
          nurseriesWithDeliveriesForDay: subscriptionData.data.portionedNurseriesSubscription,
        },
      });
    },
  });

  return (
    <PageHeading
      maxWidth="lg"
      noHeadingOnPrint
      heading="Packing Report"
      className="hide-for-print"
    >
      <DatePickerSection
        type="daily"
        maxDate={moment().add(3, "month").startOf("isoWeek").format("YYYY-MM-DD")}
        earliestDate="2020-03-23"
        disableArrowKeys={open}
      />
      <GraphqlQuery query={TINS} variables={{}} withLoading withError>
        {({ data: tinData, error: tinError }) => {
          if (tinError) return <CentredErrorIcon />;
          const { tins } = tinData;

          return (
            <GraphqlQuery
              query={NURSERIES_WITH_DELIVERIES}
              fetchPolicy="network-only"
              variables={{ date }}
              withLoading
              withError
            >
              {({ data, error }) => {
                if (error) return <CentredErrorIcon />;
                const { nurseriesWithDeliveriesForDay } = data;

                const portioned = (t, pamc, condition) => {
                  return nurseriesWithDeliveriesForDay.reduce((acc, n) => {
                    if (condition(n.lunchDelivery)) {
                      const mealItem = find(
                        n.lunchDelivery.regularMealItems,
                        (i) => i.mealComponent.id === pamc.mealComponent.id,
                      );

                      const ldp = find(
                        mealItem.lunchDeliveryPortions,
                        (ldp) => ldp.tinId === t.id,
                      );
                      if (ldp) {
                        return acc + ldp.count;
                      } else {
                        return acc;
                      }
                    } else {
                      return acc;
                    }
                  }, 0);
                };

                return (
                  <GraphqlQuery
                    query={REGULAR_MEALS}
                    fetchPolicy="network-only"
                    variables={{ date }}
                    withLoading={false}
                    withError
                  >
                    {({ data: mealData, error: mealError, loading }) => {
                      if (mealError) return <CentredErrorIcon />;
                      if (loading) return;
                      const { pastRegularLunchMealForDate } = mealData;

                      if (pastRegularLunchMealForDate) {
                        const { pastAvailableMealComponents } = pastRegularLunchMealForDate;

                        return (
                          <React.Fragment>
                            <TableContainer>
                              <Table size="small">
                                <TableHead>
                                  <TableRow>
                                    <BorderedCell>Component</BorderedCell>
                                    {tins.map((t) => {
                                      return (
                                        <BorderedCell colSpan="2" align="center" key={t.id}>
                                          {t.name} Tin
                                        </BorderedCell>
                                      );
                                    })}
                                  </TableRow>
                                  <TableRow>
                                    <BorderedCell />
                                    {tins.map((t) => {
                                      return (
                                        <React.Fragment key={t.id}>
                                          <BorderedCell align="center">Portioned</BorderedCell>
                                          <BorderedCell align="center">Remaining</BorderedCell>
                                        </React.Fragment>
                                      );
                                    })}
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {pastAvailableMealComponents
                                    .filter((pamc) => {
                                      const firstPortion = pamc.mealComponent.portions[0];
                                      return firstPortion && firstPortion.tinType === "round_up";
                                    })
                                    .map((pamc) => {
                                      return (
                                        <TableRow key={pamc.id}>
                                          <BorderedCell>{pamc.mealComponent.name}</BorderedCell>
                                          {tins.map((t) => {
                                            return (
                                              <React.Fragment key={t.id}>
                                                <BorderedCell align="center">
                                                  {portioned(t, pamc, (x) => x.portioned)}
                                                </BorderedCell>
                                                <BorderedCell align="center">
                                                  {portioned(t, pamc, (x) => !x.portioned)}
                                                </BorderedCell>
                                              </React.Fragment>
                                            );
                                          })}
                                        </TableRow>
                                      );
                                    })}
                                </TableBody>
                              </Table>
                            </TableContainer>

                            <TableContainer component={TableWrapper}>
                              <Table stickyHeader size="small">
                                <TableHead>
                                  <TableRow>
                                    <TableCell>Nursery</TableCell>
                                    <TableCell>Portioned?</TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {nurseriesWithDeliveriesForDay.map((n, index) => {
                                    return (
                                      <NurseryRow
                                        openModal={openModal(index)}
                                        key={n.id}
                                        nursery={n}
                                      />
                                    );
                                  })}
                                </TableBody>
                              </Table>
                            </TableContainer>
                            {open && (
                              <PortionNurseryModal
                                startingNurseryIndex={startingNurseryIndex}
                                nurseries={nurseriesWithDeliveriesForDay}
                                open={open}
                                onCancel={closeModal}
                                onSubmit={closeModal}
                              />
                            )}
                          </React.Fragment>
                        );
                      } else {
                        return (
                          <Typography>
                            <InfoNextToText />
                            &nbsp; There are no deliveries for today.
                          </Typography>
                        );
                      }
                    }}
                  </GraphqlQuery>
                );
              }}
            </GraphqlQuery>
          );
        }}
      </GraphqlQuery>
    </PageHeading>
  );
};
