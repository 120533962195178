import React from "react";
import { Typography } from "@material-ui/core";

// Styled component variant - easy to do media queries...?
// const H1 = styled.h1`
//   font-size: 2em;
//   ${media.tablet`
//     font-size: 28px;
//   `}
//   ${media.desktop`
//     font-size: 32px;
//   `}
// `;

export const H1 = (props) => {
  const { children, styles, ...rest } = props;
  // How do we make this responsive?
  return (
    // the variant is essentially the styles.
    // h1 is massive for some reason...
    <Typography variant="h2" component="h1" styles={styles} {...rest}>
      {children}
    </Typography>
  );
};
