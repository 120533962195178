import { gql } from "@apollo/client";
import React from "react";
import styled from "styled-components";
import { useTheme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";
import { KeyboardDatePicker } from "@material-ui/pickers";
import {
  DialogTitle,
  DialogContent,
  DialogActions,
  Dialog,
  TextField,
  Typography,
  useMediaQuery,
  Button,
} from "@material-ui/core";
import { GraphqlMutation } from "graphqlUtils/GraphqlMutation";
import { media } from "media";

const DELETE_MENU = gql`
  mutation DeleteMenu($id: ID!) {
    deleteMenu(id: $id) {
      id
      name
    }
  }
`;

const UPDATE_MENU = gql`
  mutation UpdateMenu($input: UpdateMenuInput!) {
    updateMenu(input: $input) {
      id
      name
      activeUntil
      activeFrom
    }
  }
`;

const StartEndDate = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ${media.sm`
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 75%;
  `}
`;

const P = styled.p`
  font-size: 16px;
  position: relative;
  top: 16px;
  ${media.sm`
    top: 0;
    margin-bottom: 0;
  `}
`;

const useStyles = makeStyles({
  inputStyles: { marginBottom: "15px", width: "66%", maxWidth: "400px" },
  dialog: { padding: "30px 0px 30px 0px" },
  actions: { "& > button": { marginRight: "10px" } },
});

export const EditMenuModal = (props) => {
  const { open, onSubmit, onCancel, menu, refetchQueries } = props;
  const { inputStyles, dialog, actions } = useStyles();
  const theme = useTheme();
  const defaultState = {
    activeFrom: menu.activeFrom,
    activeUntil: menu.activeUntil,
  };
  const [dates, setDates] = React.useState(defaultState);

  const [currentName, updateName] = React.useState(menu.name);
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  React.useEffect(() => updateName(menu.name), [menu.name, updateName]);
  React.useEffect(
    () =>
      setDates({
        activeFrom: menu.activeFrom,
        activeUntil: menu.activeUntil,
      }),
    [menu, setDates],
  );

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      fullScreen={fullScreen}
      fullWidth
      maxWidth="md"
      open={open}
      PaperProps={{ className: dialog }}
    >
      <DialogTitle disableTypography>
        <Typography variant="h4" component="h4">
          Edit Menu {currentName}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <TextField
          required
          className={inputStyles}
          label="name"
          value={currentName}
          onChange={(event) => updateName(event.target.value)}
        />
      </DialogContent>
      <DialogContent>
        <StartEndDate>
          <KeyboardDatePicker
            clearable
            allowKeyboardControl
            variant="dialog"
            label="Start Date"
            format="MMMM Do YYYY"
            value={dates.activeFrom}
            onChange={(activeFrom) => {
              if (activeFrom) {
                setDates({ ...dates, activeFrom: activeFrom.format("YYYY-MM-DD") });
              } else {
                setDates(defaultState);
              }
            }}
          />
          <P>To</P>
          <KeyboardDatePicker
            clearable
            disabled={!dates.activeFrom}
            allowKeyboardControl
            variant="dialog"
            minDate={dates.activeFrom}
            label="End date"
            format="MMMM Do YYYY"
            value={dates.activeUntil}
            onChange={(activeUntil) => {
              if (activeUntil) {
                setDates({ ...dates, activeUntil: activeUntil.format("YYYY-MM-DD") });
              } else {
                setDates({ ...dates, activeUntil });
              }
            }}
          />
        </StartEndDate>
      </DialogContent>
      <DialogActions className={actions}>
        <GraphqlMutation mutation={DELETE_MENU} withError>
          {(mutation) => {
            const onClick = async () => {
              const confirm = window.confirm(
                `This will permanently delete menu ${menu.name}. Are you sure?`,
              );
              if (confirm) {
                const result = await mutation({ variables: { id: menu.id }, refetchQueries });
                if (result.data) {
                  onSubmit();
                }
              }
            };
            return (
              <Button color="secondary" variant="outlined" onClick={onClick}>
                Delete Menu
              </Button>
            );
          }}
        </GraphqlMutation>
        <Button color="default" variant="outlined" onClick={onCancel}>
          Cancel
        </Button>
        <GraphqlMutation mutation={UPDATE_MENU} withError>
          {(mutation) => {
            const onClick = async () => {
              const result = await mutation({
                variables: { input: { id: menu.id, ...dates, name: currentName } },
                refetchQueries,
              });

              if (result.data) {
                onSubmit();
              }
            };
            return (
              <Button color="primary" variant="outlined" onClick={onClick}>
                Save
              </Button>
            );
          }}
        </GraphqlMutation>
      </DialogActions>
    </Dialog>
  );
};
