import { gql } from "@apollo/client";
import React from "react";
import { FourOhFour } from "components/FourOhFour";
import { GraphqlQuery } from "graphqlUtils/GraphqlQuery";
import { AdminHome } from "pages/admin/AdminHome";
import { NurseryHome } from "pages/nursery/NurseryHome";
import { Loading } from "components/Loading";
import { UserContext } from "contexts";
import { DeliveryDriverHome } from "pages/deliveryDriver/DeliveryDriverHome";

const USER = gql`
  query User {
    user {
      id
      role
      nurseryId
    }
  }
`;

const HomePageSelector = () => {
  const user = React.useContext(UserContext);
  const { role } = user;
  switch (role) {
    case "admin":
      return <AdminHome />;
    case "nursery":
      return <NurseryHome />;
    case "delivery_driver":
      return <DeliveryDriverHome />;
    default:
      return <FourOhFour />;
  }
};

export const HomePage = () => {
  return (
    <GraphqlQuery query={USER} withLoading withError>
      {({ data, loading, error }) => {
        if (loading) {
          return <Loading />;
        }

        if (error) {
          return <div>{error.message}</div>;
        }

        return (
          <UserContext.Provider value={data.user}>
            <HomePageSelector />
          </UserContext.Provider>
        );
      }}
    </GraphqlQuery>
  );
};
