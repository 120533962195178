import React from "react";
import { PageHeading } from "components/PageHeading";
import styled from "styled-components";
import { gql } from "@apollo/client";
import { GraphqlQuery } from "graphqlUtils/GraphqlQuery";
import { CentredErrorIcon } from "components/CentredErrorIcon";
import { Loading } from "components/Loading";
import AddIcon from "@material-ui/icons/Add";
import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { TextInput } from "components/inputs/Inputs";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  useMediaQuery,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Divider,
  Link,
  IconButton,
} from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import { GraphqlMutation } from "graphqlUtils/GraphqlMutation";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import DeleteIcon from "@material-ui/icons/Delete";
import ReactMarkdown from "react-markdown";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import { NewTabLink } from "components/NewTabLink";

const FAQS = gql`
  query Questions {
    questionCategories {
      id
      name
      order
      questions {
        id
        text
        order
        answer {
          id
          text
        }
      }
    }
  }
`;

const CREATE_CATEGORY = gql`
  mutation CreateCategory($input: CreateCategoryInput!) {
    createFaqCategory(input: $input) {
      id
      name
      order
    }
  }
`;

const DELETE_CATEGORY = gql`
  mutation DeleteCategory($id: ID!) {
    deleteFaqCategory(id: $id) {
      id
    }
  }
`;

const useStyles = makeStyles({
  dialogContent: { display: "flex", flexDirection: "column" },
  inputStyle: { width: "100%", maxWidth: "350px", marginBottom: "15px" },
  noBefore: { "&:before": { content: "none" } },
  overflow: { overflow: "auto" },
});

const CreateCategoryModal = (props) => {
  const { open, onCancel, refetchQueries, onSubmit } = props;
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { inputStyle, dialogContent } = useStyles();
  const [name, updateName] = React.useState();
  const resetForm = () => updateName("");

  return (
    <Dialog
      scroll="paper"
      disableBackdropClick
      disableEscapeKeyDown
      fullScreen={fullScreen}
      fullWidth
      open={open}
    >
      <DialogTitle disableTypography>
        <Typography variant="h4" component="h4">
          Create Question Category
        </Typography>
      </DialogTitle>
      <DialogContent className={dialogContent}>
        <TextInput
          required
          autoFocus
          variant="outlined"
          className={inputStyle}
          label="Name..."
          value={name}
          onChange={(event) => updateName(event.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            resetForm();
            onCancel();
          }}
          color="primary"
        >
          Cancel
        </Button>
        <GraphqlMutation mutation={CREATE_CATEGORY} withError>
          {(createCategory) => {
            const submit = async () => {
              if (!name) return;
              const variables = { input: { name } };
              const result = await createCategory({ variables, refetchQueries });
              if (result.data) {
                resetForm();
                onSubmit();
              }
              return result;
            };

            return (
              <Button disabled={!name} onClick={submit} color="primary">
                Create
              </Button>
            );
          }}
        </GraphqlMutation>
      </DialogActions>
    </Dialog>
  );
};

const UPDATE_CATEGORY = gql`
  mutation UpdateCategory($input: UpdateCategoryInput!) {
    updateFaqCategory(input: $input) {
      id
      name
      order
    }
  }
`;

const EditCategoryModal = (props) => {
  const { open, onCancel, refetchQueries, onSubmit, category } = props;
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { inputStyle, dialogContent } = useStyles();
  const [name, updateName] = React.useState();
  const resetForm = () => updateName("");

  React.useEffect(() => {
    updateName(category.name);
  }, [category.name]);

  return (
    <Dialog
      scroll="paper"
      disableBackdropClick
      disableEscapeKeyDown
      fullScreen={fullScreen}
      fullWidth
      open={open}
    >
      <DialogTitle disableTypography>
        <Typography variant="h4" component="h4">
          Edit Category
        </Typography>
      </DialogTitle>
      <DialogContent className={dialogContent}>
        <TextInput
          required
          autoFocus
          variant="outlined"
          className={inputStyle}
          label="Name..."
          value={name}
          onChange={(event) => updateName(event.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            resetForm();
            onCancel();
          }}
          color="primary"
        >
          Cancel
        </Button>
        <GraphqlMutation mutation={UPDATE_CATEGORY} withError>
          {(update) => {
            const submit = async () => {
              if (!name) return;
              const variables = { input: { id: category.id, name } };
              const result = await update({ variables, refetchQueries });
              if (result.data) {
                resetForm();
                onSubmit();
              }
              return result;
            };

            return (
              <Button disabled={!name} onClick={submit} color="primary">
                Save
              </Button>
            );
          }}
        </GraphqlMutation>
      </DialogActions>
    </Dialog>
  );
};

const QuestionDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const CREATE_QUESTION = gql`
  mutation CreateQuestion($input: CreateQuestionInput!) {
    createQuestion(input: $input) {
      id
      text
    }
  }
`;

const DELETE_QUESTION = gql`
  mutation DeleteQuestion($id: ID!) {
    deleteQuestion(id: $id) {
      id
    }
  }
`;

const QuestionInputDiv = styled.div`
  display: flex;
  width: 100%;
  max-width: 450px;
  margin-top: 10px;
`;

const EditQuestion = (props) => {
  const { open, onCancel, refetchQueries, onSubmit, question } = props;
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [text, updateText] = React.useState();

  React.useEffect(() => {
    updateText(question.text);
  }, [question.text]);

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      scroll="paper"
      disableBackdropClick
      disableEscapeKeyDown
      fullScreen={fullScreen}
      open={open}
    >
      <DialogTitle disableTypography>
        <Typography variant="h4" component="h4">
          Edit Question
        </Typography>
      </DialogTitle>
      <DialogContent>
        <TextInput
          required
          multiline
          variant="outlined"
          style={{ marginBottom: "15px", width: "100%" }}
          label="Provide the question below"
          value={text}
          onChange={(e) => updateText(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} color="primary">
          Cancel
        </Button>
        <GraphqlMutation mutation={UPDATE_QUESTION} withError>
          {(update) => {
            const submit = async () => {
              if (!text) return;
              const variables = { input: { text, id: question.id } };
              const result = await update({ variables, refetchQueries });
              if (result.data) {
                onSubmit();
              }
            };

            return (
              <Button disabled={!text} onClick={submit} color="primary">
                Save
              </Button>
            );
          }}
        </GraphqlMutation>
      </DialogActions>
    </Dialog>
  );
};

const QuestionCreate = (props) => {
  const { category, refetchQueries } = props;
  const [question, setQuestion] = React.useState();

  return (
    <GraphqlMutation mutation={CREATE_QUESTION} withError>
      {(create, { loading }) => {
        const onClick = async () => {
          if (!question) return;
          const variables = { input: { faqCategoryId: category.id, text: question } };
          const result = await create({ variables, refetchQueries });

          if (result.data) {
            setQuestion("");
          }
        };

        return (
          <QuestionInputDiv>
            <TextInput
              required
              disabled={loading}
              style={{ width: "100%", maxWidth: "450px" }}
              variant="outlined"
              label="Add a new question"
              value={question}
              onChange={(e) => setQuestion(e.target.value)}
            />
            <IconButton style={{ marginleft: "15px" }} disabled={loading} onClick={onClick}>
              <AddIcon />
            </IconButton>
          </QuestionInputDiv>
        );
      }}
    </GraphqlMutation>
  );
};

const CREATE_ANSWER = gql`
  mutation CreateAnswer($input: CreateAnswerInput!) {
    createAnswer(input: $input) {
      id
      text
    }
  }
`;

const Flexin = styled.div`
  display: flex;
  flex-direction: column;
`;

const CreateAnswerModal = (props) => {
  const { open, onCancel, refetchQueries, onSubmit, question } = props;
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [text, updateText] = React.useState();

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      scroll="paper"
      disableBackdropClick
      disableEscapeKeyDown
      fullScreen={fullScreen}
      open={open}
    >
      <DialogTitle disableTypography>
        <Typography variant="h4" component="h4">
          Provide Answer
        </Typography>
        <Typography variant="body1" component="h4">
          You may use markdown to give the answer styles and to enable things like hyperlinks in
          the answers.{" "}
          <Link
            href="https://www.markdownguide.org/basic-syntax/"
            rel="noreferrer"
            target="_blank"
          >
            Find out more about what you can do with markdown here.
          </Link>
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Flexin>
          <TextInput
            required
            multiline
            style={{ marginBottom: "15px" }}
            variant="outlined"
            label="Provide the answer below"
            value={text}
            onChange={(e) => updateText(e.target.value)}
          />
          <Typography variant="button">Live Preview</Typography>
          <Divider />
          {/* This div make react markdown extend down the page properly*/}
          <div>
            <ReactMarkdown renderers={{ link: NewTabLink }}>{text}</ReactMarkdown>
          </div>
        </Flexin>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} color="primary">
          Cancel
        </Button>
        <GraphqlMutation mutation={CREATE_ANSWER} withError>
          {(update) => {
            const submit = async () => {
              if (!text) return;
              const variables = { input: { questionId: question.id, text } };
              const result = await update({ variables, refetchQueries });
              if (result.data) {
                onSubmit();
              }
            };

            return (
              <Button disabled={!text} onClick={submit} color="primary">
                Save
              </Button>
            );
          }}
        </GraphqlMutation>
      </DialogActions>
    </Dialog>
  );
};

const UPDATE_ANSWER = gql`
  mutation UpdateAnswer($input: UpdateAnswerInput!) {
    updateAnswer(input: $input) {
      id
      text
    }
  }
`;

const EditAnswerModal = (props) => {
  const { open, onCancel, refetchQueries, onSubmit, answer } = props;
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [text, updateText] = React.useState();

  React.useEffect(() => {
    updateText(answer.text);
  }, [answer.text]);

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      scroll="paper"
      disableBackdropClick
      disableEscapeKeyDown
      fullScreen={fullScreen}
      open={open}
    >
      <DialogTitle disableTypography>
        <Typography variant="h4" component="h4">
          Edit Answer
        </Typography>
        <Typography variant="body1" component="h4">
          You may use markdown to give the answer styles and to enable things like hyperlinks in
          the answers.{" "}
          <Link href="https://www.markdownguide.org/basic-syntax/" target="_blank">
            Find out more about what you can do with markdown here.
          </Link>
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Flexin>
          <TextInput
            required
            multiline
            variant="outlined"
            style={{ marginBottom: "15px" }}
            label="Edit the answer below"
            value={text}
            onChange={(e) => updateText(e.target.value)}
          />
          <Typography variant="button" style={{ width: "100%" }}>
            Live Preview
          </Typography>
          <Divider />
          <div>
            <ReactMarkdown components={{ a: NewTabLink }}>{text}</ReactMarkdown>
          </div>
        </Flexin>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} color="primary">
          Cancel
        </Button>
        <GraphqlMutation mutation={UPDATE_ANSWER} withError>
          {(update) => {
            const submit = async () => {
              if (!text) return;
              const variables = { input: { text, id: answer.id } };
              const result = await update({ variables, refetchQueries });
              if (result.data) {
                onSubmit();
              }
            };

            return (
              <Button disabled={!text} onClick={submit} color="primary">
                Save
              </Button>
            );
          }}
        </GraphqlMutation>
      </DialogActions>
    </Dialog>
  );
};

const EditBox = styled.div`
  display: flex;
  align-items: flex-start;
`;

const DELETE_ANSWER = gql`
  mutation DeleteAnswer($id: ID!) {
    deleteAnswer(id: $id) {
      id
    }
  }
`;

const Answer = (props) => {
  const { question, refetchQueries } = props;
  const [open, setOpen] = React.useState(false);
  const [editOpen, setEditOpen] = React.useState(false);

  /* If there is no answer provide an option to add one */
  /* This should be modal so we can preview the markdown */
  /* The editor just needs state which is the text of the thing */
  /* and that gets rendered in a preview bit. The text area input*/

  const openCreateModal = () => setOpen(true);
  const closeCreateModal = () => setOpen(false);
  const closeEditModal = () => setEditOpen(false);
  const openEditModal = () => setEditOpen(true);

  return (
    <div style={{ width: "100%" }}>
      {question.answer && (
        <EditBox>
          <GraphqlMutation mutation={DELETE_ANSWER}>
            {(deleteAnswer, { loading }) => {
              const onClick = () => {
                deleteAnswer({ variables: { id: question.answer.id }, refetchQueries });
              };
              return (
                <IconButton
                  disabled={loading}
                  style={{ paddingTop: "0px" }}
                  variant="outlined"
                  onClick={onClick}
                >
                  <DeleteIcon style={{ fontSize: "20px" }} />
                </IconButton>
              );
            }}
          </GraphqlMutation>
          <div
            style={{
              width: "100%",
              borderRadius: "4px",
              borderTop: "1px solid rgba(0, 0, 0, 0.23)",
              borderBottom: "1px solid rgba(0, 0, 0, 0.23)",
              padding: "0px 10px",
              overflow: "auto",
            }}
          >
            <ReactMarkdown components={{ a: NewTabLink }}>{question.answer.text}</ReactMarkdown>
          </div>
          <IconButton style={{ paddingTop: "0px" }} variant="outlined" onClick={openEditModal}>
            <EditOutlinedIcon style={{ fontSize: "20px" }} />
          </IconButton>
        </EditBox>
      )}
      {question.answer ? null : (
        <Button variant="outlined" onClick={openCreateModal}>
          Provide Answer
        </Button>
      )}
      {open && (
        <CreateAnswerModal
          open={open}
          question={question}
          onSubmit={closeCreateModal}
          onCancel={closeCreateModal}
          refetchQueries={refetchQueries}
        />
      )}
      {editOpen && (
        <EditAnswerModal
          open={editOpen}
          answer={question.answer}
          onSubmit={closeEditModal}
          onCancel={closeEditModal}
        />
      )}
    </div>
  );
};

const UPDATE_QUESTION = gql`
  mutation UpdateQuestion($input: UpdateQuestionInput!) {
    updateQuestion(input: $input) {
      id
      text
      order
    }
  }
`;

export const SetFaqs = () => {
  const [open, setOpen] = React.useState(false);
  const [editModalOpen, setEditModal] = React.useState(false);
  const [editingCat, setCatToEdit] = React.useState();
  const { noBefore, overflow } = useStyles();
  const refetchQueries = [{ query: FAQS, variables: {} }];

  const [editQuestionOpen, setEditQuestionOpen] = React.useState(false);
  const [editingQuestion, setQToEdit] = React.useState();

  const closeEditQModal = () => {
    setEditQuestionOpen(false);
    setQToEdit(null);
  };

  const openEditQModal = (e, question) => {
    e.preventDefault();
    e.stopPropagation();
    setQToEdit(question);
    setEditQuestionOpen(true);
  };

  const openEditCategory = (cat) => {
    setCatToEdit(cat);
    setEditModal(true);
  };

  const openCreateCategory = () => setOpen(true);
  const closeCreateCategory = () => setOpen(false);
  const closeEditModal = () => {
    setEditModal(false);
    setCatToEdit(null);
  };

  return (
    <PageHeading maxWidth="md" heading="Create FAQs">
      <Typography variant="body1" style={{ marginBottom: "30px" }}>
        Here you can add any number of FAQs for clients to look at. You may use markdown to style
        any answer and to enable things like hyperlinks in the answers.{" "}
        <Link href="https://www.markdownguide.org/basic-syntax/" target="_blank">
          Find out more about what you can do with markdown here.
        </Link>
      </Typography>
      <GraphqlQuery query={FAQS} variables={{}}>
        {({ loading, data, error }) => {
          if (error) return <CentredErrorIcon />;
          if (loading) return <Loading />;

          const { questionCategories } = data;

          return questionCategories.map((cat) => {
            return (
              <Accordion
                key={cat.id}
                className={noBefore}
                style={{
                  backgroundColor: "#fafafa",
                  marginBottom: "15px",
                  boxShadow: "none",
                  border: "1px solid #757575",
                }}
              >
                <AccordionSummary
                  classes={{ content: overflow }}
                  style={{ overflow: "auto" }}
                  expandIcon={<ExpandLessIcon />}
                >
                  <GraphqlMutation mutation={DELETE_CATEGORY} withError>
                    {(deleteCat, { loading }) => {
                      const onClick = async (e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        await deleteCat({
                          variables: { id: cat.id },
                          refetchQueries,
                        });
                      };
                      return (
                        <IconButton
                          style={{ padding: "0px", marginRight: "10px" }}
                          disabled={loading}
                          onClick={onClick}
                        >
                          <DeleteIcon style={{ fontSize: "20px" }} />
                        </IconButton>
                      );
                    }}
                  </GraphqlMutation>
                  <Typography variant="button">{cat.name}</Typography>
                  <IconButton
                    style={{
                      padding: "0px",
                      marginLeft: "10px",
                    }}
                    disabled={loading}
                    onClick={(e) => {
                      e.preventDefault();
                      openEditCategory(cat);
                    }}
                  >
                    <EditOutlinedIcon style={{ fontSize: "20px" }} />
                  </IconButton>
                </AccordionSummary>
                <AccordionDetails>
                  <QuestionDiv>
                    {cat.questions.map((question) => {
                      return (
                        <Accordion
                          key={question.id}
                          style={{ marginBottom: "15px", width: "100%" }}
                        >
                          <AccordionSummary
                            classes={{ content: overflow }}
                            style={{ overflow: "auto" }}
                            expandIcon={<ExpandLessIcon />}
                          >
                            <GraphqlMutation mutation={DELETE_QUESTION} withError>
                              {(deleteQ, { loading }) => {
                                const onClick = async (e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  await deleteQ({
                                    variables: { id: question.id },
                                    refetchQueries,
                                  });
                                };
                                return (
                                  <IconButton
                                    style={{
                                      padding: "0px",
                                      marginRight: "10px",
                                    }}
                                    disabled={loading}
                                    onClick={onClick}
                                  >
                                    <DeleteIcon style={{ fontSize: "20px" }} />
                                  </IconButton>
                                );
                              }}
                            </GraphqlMutation>
                            <Typography variant="button">
                              <b>Q:</b>&nbsp;{question.text}
                            </Typography>
                            <IconButton
                              style={{
                                padding: "0px",
                                marginLeft: "10px",
                              }}
                              disabled={loading}
                              onClick={(e) => openEditQModal(e, question)}
                            >
                              <EditOutlinedIcon style={{ fontSize: "20px" }} />
                            </IconButton>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Answer question={question} refetchQueries={refetchQueries} />
                          </AccordionDetails>
                        </Accordion>
                      );
                    })}
                    {/* This is a bit inefficient but meh until we need to change it.*/}
                    <QuestionCreate category={cat} refetchQueries={refetchQueries} />
                  </QuestionDiv>
                </AccordionDetails>
              </Accordion>
            );
          });
        }}
      </GraphqlQuery>
      <Button variant="outlined" color="primary" aria-label="add" onClick={openCreateCategory}>
        Add Category&nbsp;&nbsp;
        <AddIcon />
      </Button>
      {editQuestionOpen && (
        <EditQuestion
          open={editQuestionOpen}
          question={editingQuestion}
          onCancel={closeEditQModal}
          onSubmit={closeEditQModal}
          refetchQueries={refetchQueries}
        />
      )}

      {open && (
        <CreateCategoryModal
          open={open}
          onCancel={closeCreateCategory}
          onSubmit={closeCreateCategory}
          refetchQueries={refetchQueries}
        />
      )}
      {editModalOpen && (
        <EditCategoryModal
          open={editModalOpen}
          category={editingCat}
          onCancel={closeEditModal}
          onSubmit={closeEditModal}
          refetchQueries={refetchQueries}
        />
      )}
    </PageHeading>
  );
};
