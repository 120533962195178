import { gql } from "@apollo/client";
import React, { Fragment } from "react";
import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
import { Checkbox } from "components/inputs/Inputs";
import { GraphqlMutation } from "graphqlUtils/GraphqlMutation";
import { Icons } from "icons/Icons";
import EditIcon from "@material-ui/icons/Edit";
import { EditChildModal } from "containers/orders/EditChildModal";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Button,
  Typography,
} from "@material-ui/core";
import { isEqual } from "lodash";

const TableWrapper = styled.div`
  background-color: #fafafa;
`;

const useStyles = makeStyles({
  narrowCellStyle: {
    width: "50px",
  },
  childName: {
    width: "255px",
  },
  icon: {
    height: "20px",
    position: "relative",
  },
  editIcon: {
    height: "20px",
    width: "20px",
  },
});

const NameEdit = styled.div`
  display: flex;
  align-items: center;
`;

const DELETE_CHILD = gql`
  mutation DeleteChildWithSensitivity($input: DeleteChildInput!) {
    deleteChildWithSensitivity(input: $input) {
      id
    }
  }
`;

const ChildCell = (props) => {
  const {
    closed,
    isIn,
    id,
    className,
    isSubmitted,
    childId,
    onAttendanceUpdate,
    __typename,
    loading,
  } = props;

  return (
    <TableCell>
      {closed ? (
        <Typography>&nbsp;-</Typography>
      ) : (
        <Checkbox
          disabled={
            loading ||
            __typename === "NonEditableLunchOrder" ||
            __typename === "NonEditableTeaOrder" ||
            isSubmitted
          }
          className={className}
          checked={isIn}
          color="primary"
          onClick={() => onAttendanceUpdate(id, childId)}
        />
      )}
    </TableCell>
  );
};

// This is very important to not have a suuuperr slow app when there are 30+ children
const OptimizedCell = React.memo(ChildCell, (prevProps, nextProps) => {
  return (
    prevProps.isIn === nextProps.isIn &&
    prevProps.closed === nextProps.closed &&
    prevProps.__typename === nextProps.__typename &&
    prevProps.isSubmitted === nextProps.isSubmitted &&
    prevProps.loading === nextProps.loading
  );
});

const ChildRow = (props) => {
  const { narrowCellStyle, icon, childName, editIcon } = useStyles();
  const {
    onAttendanceUpdate,
    openEditModal,
    params,
    loading,
    name,
    id,
    Mon,
    Tues,
    Weds,
    Thurs,
    Fri,
    sensitivities,
    refetchQueries,
    allPast,
  } = props;

  return (
    <TableRow hover key={id}>
      <TableCell className={childName}>
        <NameEdit>
          <Button
            onClick={() => openEditModal({ name, sensitivities, id })}
            variant="text"
            color="default"
          >
            <EditIcon className={editIcon} />
          </Button>
          <Typography variant="body2" component="p">
            {name}
          </Typography>
        </NameEdit>
      </TableCell>
      <OptimizedCell
        {...Mon}
        loading={loading}
        className={narrowCellStyle}
        childId={id}
        onAttendanceUpdate={onAttendanceUpdate}
      />
      <OptimizedCell
        {...Tues}
        loading={loading}
        className={narrowCellStyle}
        childId={id}
        onAttendanceUpdate={onAttendanceUpdate}
      />
      <OptimizedCell
        {...Weds}
        loading={loading}
        className={narrowCellStyle}
        childId={id}
        onAttendanceUpdate={onAttendanceUpdate}
      />
      <OptimizedCell
        {...Thurs}
        loading={loading}
        className={narrowCellStyle}
        childId={id}
        onAttendanceUpdate={onAttendanceUpdate}
      />
      <OptimizedCell
        {...Fri}
        loading={loading}
        className={narrowCellStyle}
        childId={id}
        onAttendanceUpdate={onAttendanceUpdate}
      />
      <TableCell align="left">
        {!allPast && (
          <GraphqlMutation mutation={DELETE_CHILD}>
            {(mutation, { loading }) => {
              return (
                <IconButton
                  disabled={loading}
                  style={{ padding: "0px" }}
                  aria-label="delete"
                  onClick={() => {
                    const confirm = window.confirm(
                      `This will delete ${name} from this order and will remove them from\
 the template used for future orders.

If you have already created a future order, they will still appear there.

Are you sure you want to delete this child?`,
                    );
                    if (confirm) {
                      mutation({
                        variables: {
                          input: {
                            id,
                            startDate: params.date,
                            nurseryId: params.nurseryId,
                          },
                        },
                        refetchQueries,
                      });
                    }
                  }}
                >
                  <Icons.DeleteIcon className={icon} />
                </IconButton>
              );
            }}
          </GraphqlMutation>
        )}
      </TableCell>
    </TableRow>
  );
};

// This is very important to not have a suuuperr slow app when there are 30+ children
const OptimizedRow = React.memo(ChildRow, (prevProps, nextProps) => {
  // We need to return FALSE if we want to render, and TRUE if we don't want to re-render.

  return (
    prevProps.Mon.isIn === nextProps.Mon.isIn &&
    prevProps.Tues.isIn === nextProps.Tues.isIn &&
    prevProps.Weds.isIn === nextProps.Weds.isIn &&
    prevProps.Thurs.isIn === nextProps.Thurs.isIn &&
    prevProps.Fri.isIn === nextProps.Fri.isIn &&
    prevProps.Mon.isSubmitted === nextProps.Mon.isSubmitted &&
    prevProps.Tues.isSubmitted === nextProps.Tues.isSubmitted &&
    prevProps.Weds.isSubmitted === nextProps.Weds.isSubmitted &&
    prevProps.Thurs.isSubmitted === nextProps.Thurs.isSubmitted &&
    prevProps.Fri.isSubmitted === nextProps.Fri.isSubmitted &&
    prevProps.Mon.closed === nextProps.Mon.closed &&
    prevProps.Tues.closed === nextProps.Tues.closed &&
    prevProps.Weds.closed === nextProps.Weds.closed &&
    prevProps.Thurs.closed === nextProps.Thurs.closed &&
    prevProps.Fri.closed === nextProps.Fri.closed &&
    prevProps.loading === nextProps.loading &&
    isEqual(prevProps.sensitivities, nextProps.sensitivities)
  );
});

export const ChildrenWithSensitivitiesTable = (props) => {
  const {
    rows,
    refetchQueries,
    allPast,
    onModalOpen,
    onModalClose,
    onAttendanceUpdate,
    params,
    loading,
  } = props;
  const [isOpen, setModalOpen] = React.useState(false);
  const [editingChild, setChildToEdit] = React.useState("");

  const openEditModal = ({ name, sensitivities, id }) => {
    onModalOpen();
    setChildToEdit({ name, sensitivities, id });
    setModalOpen(true);
  };

  const closeModal = () => {
    onModalClose();
    setModalOpen(false);
  };

  const orderDays = ["Mon", "Tues", "Weds", "Thurs", "Fri"];

  return (
    <Fragment>
      {isOpen && (
        <EditChildModal
          child={editingChild}
          open={isOpen}
          onCancel={closeModal}
          onSubmit={closeModal}
          refetchQueries={refetchQueries}
        />
      )}
      <TableContainer component={TableWrapper}>
        <Table stickyHeader size="small">
          <TableHead>
            <TableRow>
              <TableCell>&nbsp;</TableCell>
              {orderDays.map((day) => {
                return <TableCell key={day}>{day}</TableCell>;
              })}
              <TableCell>&nbsp;</TableCell>
            </TableRow>
          </TableHead>
          <TableBody style={{ maxHeight: "400px" }}>
            {rows.map((row) => {
              return (
                <OptimizedRow
                  key={row.id}
                  loading={loading}
                  allPast={allPast}
                  onAttendanceUpdate={onAttendanceUpdate}
                  openEditModal={openEditModal}
                  params={params}
                  refetchQueries={refetchQueries}
                  {...row}
                />
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Fragment>
  );
};
