import React from "react";
import { useLocation, useParams, useHistory } from "react-router-dom";
import styled from "styled-components";
import moment from "moment";
import { media } from "media";
import { makeStyles } from "@material-ui/core/styles";
import { H3 } from "components/typography/H3";
import { KeyboardDatePicker } from "@material-ui/pickers";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";

import { Button, IconButton } from "@material-ui/core";

const DateDiv = styled.div`
  margin-bottom: 30px;
  max-width: 755px;
  ${media.sm`
  display: flex;
  justify-content: space-between;
  align-items: center;
  `}
`;

const ButtonGroup = styled.div`
  display: flex;
  align-items: center;
`;

const useStyles = makeStyles({
  chevron: {
    padding: "0px",
  },
  chevronRight: {
    padding: "0px",
    paddingLeft: "5px",
    marginRight: "15px",
  },
  submitButton: {
    marginLeft: "20px",
  },
});

/**
 * Provides the date shifting capabilities you see on a few different pages.
 * Relies on the url where this component is being used having the date at the
 * end of it, e.g.:  nursery/my_nursery_id/date/2020-03-23
 *
 * Allows navigating left and right through the weeks using arrow keys and
 * by clicking the chevrons. Accepts an optional minimum date which we will
 * not be able to scroll back past.
 *
 *
 */
export const DatePickerSection = (props) => {
  const { type = "daily" } = props;

  switch (type) {
    case "daily":
      return <DailyDatePickerSection {...props} />;
    case "monthly":
      return <MonthlyDatePickerSection {...props} />;
    case "weekly":
      return <WeeklyDatePickerSection {...props} />;
    default:
      throw new Error("Unsupported Date Picker Type :(");
  }
};

const MonthlyDatePickerSection = (props) => {
  const { chevron, chevronRight } = useStyles();
  // We may have to change this if we want to input old data...
  const { earliestDate = "2020-03-23", disableArrowKeys, maxDate } = props;
  const location = useLocation();
  const { date: paramDate } = useParams();
  const history = useHistory();
  const [open, setOpen] = React.useState(false);
  const date = moment(paramDate, "YYYY-MM-DD", true);
  const minDate = moment(earliestDate, "YYYY-MM-DD", true).startOf("month").format();
  const startOfMaxWeek = moment(maxDate, "YYYY-MM-DD", true).startOf("month").format();
  const showBackChevron = date.isAfter(minDate, true);
  const beforeMaxDate = date.startOf("month").isBefore(startOfMaxWeek);

  const handleDateChange = React.useCallback(
    (newDate) => {
      history.push(location.pathname.split("/").slice(0, -1).join("/") + `/${newDate}`);
    },
    [history, location],
  );

  const previousOrder = React.useCallback(() => {
    const lastWeek = date.subtract(1, "month").startOf("month");
    if (lastWeek.isSameOrAfter(minDate)) {
      handleDateChange(lastWeek.format("YYYY-MM-DD"));
    }
  }, [minDate, date, handleDateChange]);

  const nextOrder = React.useCallback(() => {
    const nextWeek = date.add(1, "month").startOf("month");
    if (nextWeek.isSameOrBefore(startOfMaxWeek)) {
      handleDateChange(nextWeek.format("YYYY-MM-DD"));
    }
  }, [date, startOfMaxWeek, handleDateChange]);

  const jumpToToday = () => {
    const today = moment().startOf("month").format("YYYY-MM-DD");
    handleDateChange(today);
  };

  // Add event listeners for key presses so we can left / right arrow through orders
  React.useEffect(() => {
    function downHandler({ key }) {
      if (open) return;
      if (key === "ArrowLeft") {
        if (showBackChevron) {
          previousOrder();
        }
      }
      if (key === "ArrowRight") {
        if (beforeMaxDate) {
          nextOrder();
        }
      }
    }

    if (!disableArrowKeys) {
      window.addEventListener("keydown", downHandler);
      // Remove event listeners on cleanup
      return () => {
        window.removeEventListener("keydown", downHandler);
      };
    }
  }, [previousOrder, beforeMaxDate, nextOrder, showBackChevron, disableArrowKeys, open]);

  return (
    <DateDiv className="hide-for-print">
      <H3 className="hide-for-print">
        {date.startOf("month").format("Do MMM")} - {date.endOf("month").format("Do MMM YYYY")}
      </H3>
      <ButtonGroup>
        {showBackChevron && (
          <IconButton className={`${chevron} hide-for-print`} onClick={previousOrder}>
            <ArrowBackIosIcon />
          </IconButton>
        )}
        <Button color="primary" className="hide-for-print" onClick={jumpToToday}>
          Jump to Today
        </Button>

        <IconButton className={`${chevronRight} hide-for-print`} onClick={nextOrder}>
          {beforeMaxDate && <ArrowForwardIosIcon />}
        </IconButton>

        <KeyboardDatePicker
          allowKeyboardControl
          animateYearScrolling
          className="hide-for-print"
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          minDate={minDate}
          maxDate={maxDate}
          format="MMMM Do YYYY"
          value={date.startOf("month").format()}
          onChange={(date) => {
            handleDateChange(
              date
                // this ensures the start of the week is Monday and not Sunday like a psychopath.
                .startOf("month")
                .format("YYYY-MM-DD"),
            );
          }}
          KeyboardButtonProps={{
            "aria-label": "change date",
          }}
        />
      </ButtonGroup>
    </DateDiv>
  );
};

const DailyDatePickerSection = (props) => {
  const { chevron, chevronRight } = useStyles();
  const { earliestDate = "2020-03-23", disableArrowKeys, maxDate } = props;
  const location = useLocation();
  const { date: paramDate } = useParams();
  const history = useHistory();
  const [open, setOpen] = React.useState(false);
  const date = moment(paramDate, "YYYY-MM-DD", true);
  const minDate = moment(earliestDate, "YYYY-MM-DD", true).format();
  const showBackChevron = date.isAfter(minDate, true);

  const handleDateChange = React.useCallback(
    (newDate) => {
      history.push(location.pathname.split("/").slice(0, -1).join("/") + `/${newDate}`);
    },
    [history, location],
  );

  const previousOrder = React.useCallback(() => {
    const yesterday = date.subtract(1, "day");
    if (yesterday.isSameOrAfter(minDate)) {
      handleDateChange(yesterday.format("YYYY-MM-DD"));
    }
  }, [minDate, date, handleDateChange]);

  const nextOrder = React.useCallback(() => {
    const tomorrow = date.add(1, "day").format("YYYY-MM-DD");
    handleDateChange(tomorrow);
  }, [date, handleDateChange]);

  const jumpToToday = () => {
    const today = moment().format("YYYY-MM-DD");
    handleDateChange(today);
  };

  // Add event listeners for key presses so we can left / right arrow through orders
  React.useEffect(() => {
    function downHandler({ key }) {
      // Prevents arrow keys if the date picker is open.
      if (open) return;
      if (key === "ArrowLeft") {
        if (showBackChevron) {
          previousOrder();
        }
      }
      if (key === "ArrowRight") {
        nextOrder();
      }
    }

    if (!disableArrowKeys) {
      window.addEventListener("keydown", downHandler);
      // Remove event listeners on cleanup
      return () => {
        window.removeEventListener("keydown", downHandler);
      };
    }
  }, [previousOrder, nextOrder, showBackChevron, disableArrowKeys, open]);

  return (
    <DateDiv className="hide-for-print">
      <H3>{date.format("dddd Do MMM")}</H3>
      <ButtonGroup>
        {showBackChevron && (
          <IconButton className={`${chevron} hide-for-print`} onClick={previousOrder}>
            <ArrowBackIosIcon />
          </IconButton>
        )}
        <Button color="primary" className="hide-for-print" onClick={jumpToToday}>
          Jump to Today
        </Button>
        <IconButton className={`${chevronRight} hide-for-print`} onClick={nextOrder}>
          <ArrowForwardIosIcon />
        </IconButton>

        <KeyboardDatePicker
          allowKeyboardControl
          animateYearScrolling
          className="hide-for-print"
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          minDate={minDate}
          maxDate={maxDate}
          format="MMMM Do YYYY"
          value={date.format()}
          onChange={(date) => {
            handleDateChange(date.format("YYYY-MM-DD"));
          }}
          KeyboardButtonProps={{
            "aria-label": "change date",
          }}
        />
      </ButtonGroup>
    </DateDiv>
  );
};

const WeeklyDatePickerSection = (props) => {
  const { chevron, chevronRight } = useStyles();
  // We may have to change this if we want to input old data...
  const { earliestDate = "2020-03-23", disableArrowKeys, maxDate } = props;
  const location = useLocation();
  const { date: paramDate } = useParams();
  const history = useHistory();
  const [open, setOpen] = React.useState(false);
  const date = moment(paramDate, "YYYY-MM-DD", true);
  const minDate = moment(earliestDate, "YYYY-MM-DD", true).startOf("isoWeek").format();
  const startOfMaxWeek = moment(maxDate, "YYYY-MM-DD", true).startOf("isoWeek").format();
  const showBackChevron = date.isAfter(minDate, true);
  const beforeMaxDate = date.startOf("isoWeek").isBefore(startOfMaxWeek);

  const handleDateChange = React.useCallback(
    (newDate) => {
      history.push(location.pathname.split("/").slice(0, -1).join("/") + `/${newDate}`);
    },
    [history, location],
  );

  const previousOrder = React.useCallback(() => {
    const lastWeek = date.subtract(7, "day").startOf("isoWeek");
    if (lastWeek.isSameOrAfter(minDate)) {
      handleDateChange(lastWeek.format("YYYY-MM-DD"));
    }
  }, [minDate, date, handleDateChange]);

  const nextOrder = React.useCallback(() => {
    const nextWeek = date.add(7, "day").startOf("isoWeek");
    if (nextWeek.isSameOrBefore(startOfMaxWeek)) {
      handleDateChange(nextWeek.format("YYYY-MM-DD"));
    }
  }, [date, startOfMaxWeek, handleDateChange]);

  const jumpToToday = () => {
    const today = moment().startOf("isoWeek").format("YYYY-MM-DD");
    handleDateChange(today);
  };

  // Add event listeners for key presses so we can left / right arrow through orders
  React.useEffect(() => {
    function downHandler({ key }) {
      if (open) return;
      if (key === "ArrowLeft") {
        if (showBackChevron) {
          previousOrder();
        }
      }
      if (key === "ArrowRight") {
        if (beforeMaxDate) {
          nextOrder();
        }
      }
    }

    if (!disableArrowKeys) {
      window.addEventListener("keydown", downHandler);
      // Remove event listeners on cleanup
      return () => {
        window.removeEventListener("keydown", downHandler);
      };
    }
  }, [previousOrder, beforeMaxDate, nextOrder, showBackChevron, disableArrowKeys, open]);

  return (
    <DateDiv className="hide-for-print">
      <H3 className="hide-for-print">
        {date.startOf("isoWeek").format("Do MMM")} - {date.endOf("isoWeek").format("Do MMM")}
      </H3>
      <ButtonGroup>
        {showBackChevron && (
          <IconButton className={`${chevron} hide-for-print`} onClick={previousOrder}>
            <ArrowBackIosIcon />
          </IconButton>
        )}
        <Button color="primary" className="hide-for-print" onClick={jumpToToday}>
          Jump to Today
        </Button>

        <IconButton className={`${chevronRight} hide-for-print`} onClick={nextOrder}>
          {beforeMaxDate && <ArrowForwardIosIcon />}
        </IconButton>

        <KeyboardDatePicker
          allowKeyboardControl
          animateYearScrolling
          className="hide-for-print"
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          minDate={minDate}
          maxDate={maxDate}
          format="MMMM Do YYYY"
          value={date.startOf("isoWeek").format()}
          onChange={(date) => {
            handleDateChange(
              date
                // this ensures the start of the week is Monday and not Sunday like a psychopath.
                .startOf("isoWeek")
                .format("YYYY-MM-DD"),
            );
          }}
          KeyboardButtonProps={{
            "aria-label": "change date",
          }}
        />
      </ButtonGroup>
    </DateDiv>
  );
};
