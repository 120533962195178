import { gql } from "@apollo/client";
import React from "react";
import moment from "moment";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { PageHeading } from "components/PageHeading";
import { GraphqlQuery } from "graphqlUtils/GraphqlQuery";
import { DatePickerSection } from "components/DatePickerSection";
import { CentredErrorIcon } from "components/CentredErrorIcon";
import { Typography } from "@material-ui/core";
import { OrderTabs } from "components/navigation/OrderTabs";
import { DietAdviceDayPanelLunch } from "pages/nursery/NurseryDietAdvicePage";
import { DietAdviceDayPanelTea } from "pages/nursery/NurseryDietAdvicePage";

const NURSERIES_WITH_LUNCH_DIET_ADVICE = gql`
  query NurseriesWithLunchDietAdvice($date: Date!) {
    nurseriesWithLunchDietAdvice(date: $date) {
      id
      name
      address
      phoneNumber
    }
  }
`;

const NURSERIES_WITH_TEA_DIET_ADVICE = gql`
  query NurseriesWithTeaDietAdvice($date: Date!) {
    nurseriesWithTeaDietAdvice(date: $date) {
      id
      name
      address
      phoneNumber
    }
  }
`;

const PrintCSS = styled.div`
  @media print {
    orientation: portrait;
    .hide-for-print {
      display: none;
    }
    @page :first {
      margin-top: 50px;
    }
    @page {
      margin: 50px 0px 50px 0px;
    }
  }
`;

const LUNCH_ORDER = gql`
  query LunchOrder($nurseryId: ID!, $date: Date!) {
    lunchOrder(date: $date, nurseryId: $nurseryId) {
      ... on EditableLunchOrder {
        id
        date
        closed
        submittedDatetime
        specialMeals
        regularMeal {
          id
          availableMealComponents {
            id
            mealComponent {
              id
              name
              sensitivities {
                id
                name
              }
            }
          }
        }
        attendingChildren {
          id
          name
          sensitivities {
            id
            name
          }
          childWithSensitivityId
          meal {
            id
            pastAvailableMealComponents {
              id
              mealComponent {
                id
                name
                sensitivities {
                  id
                  name
                }
              }
            }
          }
        }
      }
      ... on NonEditableLunchOrder {
        id
        date
        closed
        submittedDatetime
        specialMeals
        regularMeal {
          id
          pastAvailableMealComponents {
            id
            mealComponent {
              id
              name
              sensitivities {
                id
                name
              }
            }
          }
        }
        attendingChildren {
          id
          name
          sensitivities {
            id
            name
          }
          childWithSensitivityId
          meal {
            id
            pastAvailableMealComponents {
              id
              mealComponent {
                id
                name
                sensitivities {
                  id
                  name
                }
              }
            }
          }
        }
      }
    }
  }
`;

const TEA_ORDER = gql`
  query TeaOrder($date: Date!, $nurseryId: ID!) {
    teaOrder(date: $date, nurseryId: $nurseryId) {
      ... on EditableTeaOrder {
        id
        date
        closed
        submittedDatetime
        specialMeals
        regularMeal {
          id
          availableMealComponents {
            id
            mealComponent {
              id
              name
              sensitivities {
                id
                name
              }
            }
          }
        }
        attendingChildren {
          id
          name
          sensitivities {
            id
            name
          }
          childWithSensitivityId
          meal {
            id
            pastAvailableMealComponents {
              id
              mealComponent {
                id
                name
                sensitivities {
                  id
                  name
                }
              }
            }
          }
        }
      }
      ... on NonEditableTeaOrder {
        id
        date
        closed
        submittedDatetime
        specialMeals
        regularMeal {
          id
          pastAvailableMealComponents {
            id
            mealComponent {
              id
              name
              sensitivities {
                id
                name
              }
            }
          }
        }
        attendingChildren {
          id
          name
          sensitivities {
            id
            name
          }
          childWithSensitivityId
          meal {
            id
            pastAvailableMealComponents {
              id
              mealComponent {
                id
                name
                sensitivities {
                  id
                  name
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const PrintableDietAdvice = () => {
  const { date } = useParams();

  return (
    <PrintCSS>
      <PageHeading noHeadingOnPrint heading="Printable Diet Advice" className="hide-for-print">
        {/* Random earliest date but not wrong. No significance, just better to pick something. */}
        <DatePickerSection type="daily" earliestDate="2020-03-23" disableArrowKeys={false} />
        <Typography style={{ marginBottom: "30px" }} className="hide-for-print">
          Below is the Diet Advice for all nurseries that have it for the date shown. Each nursery
          will start on a new page when you print them.
        </Typography>
        <OrderTabs
          tabs={[
            // These are always false because you could still have an order open that needs
            // fulfilling after your provider for lunches has been removed.
            { label: "Lunch", disabled: false },
            { label: "Tea", disabled: false },
          ]}
        >
          <GraphqlQuery
            query={NURSERIES_WITH_LUNCH_DIET_ADVICE}
            variables={{ date }}
            withLoading
            withError
            fetchPolicy="network-only"
          >
            {({ data, error }) => {
              if (error) return <CentredErrorIcon />;

              return data.nurseriesWithLunchDietAdvice.map((n) => {
                return (
                  <section key={n.id}>
                    <GraphqlQuery
                      fetchPolicy="network-only"
                      query={LUNCH_ORDER}
                      variables={{ nurseryId: n.id, date }}
                    >
                      {({ data, error }) => {
                        if (error) return <CentredErrorIcon />;
                        const { lunchOrder } = data;

                        const label = moment(lunchOrder.date, "YYYY-MM-DD").format(
                          "dddd DD/MM/YY",
                        );

                        const regularMealComponents = () => {
                          if (!lunchOrder.regularMeal) {
                            return [];
                          }
                          switch (lunchOrder.regularMeal.__typename) {
                            case "AvailableMeal":
                              return lunchOrder.regularMeal.availableMealComponents.map(
                                ({ mealComponent: c }) => c,
                              );
                            case "PastAvailableMeal":
                              return lunchOrder.regularMeal.pastAvailableMealComponents.map(
                                ({ mealComponent: c }) => c,
                              );
                            default:
                              throw new Error("unsupported meal type");
                          }
                        };
                        const pageBreak = true;
                        return (
                          <div
                            style={{
                              pageBreakInside: "avoid",
                              breakAfter: pageBreak ? "page" : "auto",
                            }}
                            key={lunchOrder.id}
                          >
                            <Typography variant="button">
                              {n.name} - {label}
                            </Typography>
                            <DietAdviceDayPanelLunch
                              maxComponentCount={regularMealComponents().length}
                              regularMealComponents={regularMealComponents()}
                              label={label}
                              lunch={lunchOrder}
                            />
                          </div>
                        );
                      }}
                    </GraphqlQuery>
                  </section>
                );
              });
            }}
          </GraphqlQuery>
          <GraphqlQuery
            query={NURSERIES_WITH_TEA_DIET_ADVICE}
            variables={{ date }}
            withLoading
            withError
          >
            {({ data, error }) => {
              if (error) return <CentredErrorIcon />;
              return data.nurseriesWithTeaDietAdvice.map((n) => {
                return (
                  <section key={n.id}>
                    <GraphqlQuery
                      fetchPolicy="network-only"
                      query={TEA_ORDER}
                      variables={{ nurseryId: n.id, date }}
                    >
                      {({ data, error }) => {
                        if (error) return <CentredErrorIcon />;
                        const { teaOrder } = data;

                        const label = moment(teaOrder.date, "YYYY-MM-DD").format("dddd DD/MM/YY");

                        const regularMealComponents = () => {
                          if (!teaOrder.regularMeal) {
                            return [];
                          }
                          switch (teaOrder.regularMeal.__typename) {
                            case "AvailableMeal":
                              return teaOrder.regularMeal.availableMealComponents.map(
                                ({ mealComponent: c }) => c,
                              );
                            case "PastAvailableMeal":
                              return teaOrder.regularMeal.pastAvailableMealComponents.map(
                                ({ mealComponent: c }) => c,
                              );
                            default:
                              throw new Error("unsupported meal type");
                          }
                        };
                        const pageBreak = true;
                        return (
                          <div
                            style={{
                              pageBreakInside: "avoid",
                              breakAfter: pageBreak ? "page" : "auto",
                            }}
                            key={teaOrder.id}
                          >
                            <Typography variant="button">
                              {n.name} - {label}
                            </Typography>
                            <DietAdviceDayPanelTea
                              maxComponentCount={regularMealComponents().length}
                              regularMealComponents={regularMealComponents()}
                              label={label}
                              tea={teaOrder}
                            />
                          </div>
                        );
                      }}
                    </GraphqlQuery>
                  </section>
                );
              });
            }}
          </GraphqlQuery>
        </OrderTabs>
      </PageHeading>
    </PrintCSS>
  );
};
