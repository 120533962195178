import React from "react";
import styled from "styled-components";
import { TextInput, NumberInput } from "components/inputs/Inputs";
import { GraphqlMutation } from "graphqlUtils/GraphqlMutation";
import { useTheme } from "@material-ui/core/styles";
import { ulid } from "ulid";
import {
  Dialog,
  DialogTitle,
  Button,
  DialogContent,
  useMediaQuery,
  DialogActions,
  Typography,
} from "@material-ui/core";

const Div = styled.div`
  margin: 0 auto 20px auto;
  width: 600px;
`;

export const AddAdjustmentModal = (props) => {
  const { mutation, onCancel, refetchQueries, open, onSubmit, invoice, nurseryId, date } = props;
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [description, updateDesc] = React.useState("");
  const [quantity, updateQuantity] = React.useState(null);
  const [unitCost, setUnitCost] = React.useState(null);

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      fullScreen={fullScreen}
      fullWidth
      maxWidth="md"
      open={open}
    >
      <DialogTitle disableTypography>
        <Typography variant="h4" component="h4">
          Add An Invoice Adjustment
        </Typography>
      </DialogTitle>
      <Div>
        <TextInput
          required
          multiline
          style={{ marginBottom: "15px", width: "100%" }}
          label="Description"
          variant="outlined"
          value={description}
          onChange={(event) => updateDesc(event.target.value)}
        />
        <br />
        <NumberInput
          required
          style={{ marginRight: "15px" }}
          label="Quantity"
          variant="outlined"
          value={quantity}
          onChange={(value) => updateQuantity(value)}
          onBlur={(qty) => updateQuantity(qty)}
        />
        <NumberInput
          required
          label="Unit Cost"
          variant="outlined"
          value={unitCost}
          onChange={(value) => setUnitCost(value)}
          onBlur={(unitCost) => setUnitCost(unitCost)}
        />
        <TextInput
          disabled
          style={{ marginLeft: "10px", width: "121px" }}
          label="Total"
          variant="outlined"
          value={Math.round((unitCost || 0) * (quantity || 0) * 100 + Number.EPSILON) / 100}
          onChange={() => 1}
          onBlur={() => 1}
        />
      </Div>
      <DialogContent>
        <Typography variant="body1" component="p">
          Adjustments can be for positive of negative amounts, allowing you to add or remove cost.
          They all require a description and a quantity.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} color="primary">
          Cancel
        </Button>
        <GraphqlMutation mutation={mutation} withError>
          {(update) => {
            const submit = async () => {
              const lineItemsVars = invoice.lineItems.map(
                ({ id, description, quantity, unitCost }) => ({
                  id,
                  description,
                  quantity,
                  unitCost,
                }),
              );

              const variables = {
                input: {
                  id: invoice.id,
                  includeVat: invoice.includeVat,
                  lineItems: [
                    // Yea mad hacky should probably fix that somewhere. Could make it a decimal
                    // type. Or have the input actually work.
                    { description, quantity: parseInt(quantity), unitCost, id: ulid() },
                    ...lineItemsVars,
                  ],
                  nurseryId,
                  date,
                },
              };

              const result = await update({ variables, refetchQueries });
              if (result.data) {
                onSubmit();
              }
            };

            return (
              <Button
                disabled={!quantity || !description || !unitCost}
                onClick={submit}
                color="primary"
              >
                Create
              </Button>
            );
          }}
        </GraphqlMutation>
      </DialogActions>
    </Dialog>
  );
};
