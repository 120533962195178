import React, { useState } from "react";
import {
  TextField as MaterialuiTextInput,
  Checkbox as MaterialuiCheckbox,
  // Select,
  // InputLabel,
  // FormControl,
} from "@material-ui/core";

export const TextInput = (props) => {
  const {
    value,
    onSubmit,
    className,
    margin,
    dataTestid,
    variant,
    required,
    error,
    helperText,
    label,
    onChange,
    onBlur,
    inputProps,
    autoFocus = false,
    ...rest
  } = props;

  return (
    <MaterialuiTextInput
      label={label}
      error={error}
      autoFocus={autoFocus}
      helperText={helperText}
      required={required}
      data-testid={dataTestid}
      inputProps={inputProps}
      variant={variant}
      margin={margin}
      type="text"
      className={className}
      value={value}
      onChange={onChange}
      onSubmit={onSubmit}
      onBlur={onBlur}
      {...rest}
    />
  );
};

/**
This is an input that accepts the BE value as input, does some edits to it onChange,
then submits it onBlur. You can also not submit it onBlur, but that would be a different
component.

Crucially the onBlur function will want to submit the state of the component. But it is likely that
the parent component is using this will want that state to do things like optimistic updates.

So we pass the state to the onBlur function that gets passed in

### Examples

This is using a graphql mutation to update the field, not the onBlur function.

<GraphqlMutation withError mutation={UPDATE_REGULAR_NUMBERS}>
  {(mutation) => {
    return (
      <NumberInput
        margin="dense"
        className={inputStyles}
        number={regularMeals}
        onBlur={(state) => {
          return mutation({
            optimisticResponse: {
              __typename: "Mutation",
              updateRegularLunchNumbers: {
                // the old one...
                ...queryData,
                // Plus the new from state. But where is the state?
                // It's in the number component, gets passed to the onBlur
                regularMeals: state,
              },
            },
            variables: {
              input: {
                lunchOrderId: id,
                regularMeals: state,
              },
            },
          });
        }}
      />
    );
  }}
</GraphqlMutation>
**/
export const GraphqlInput = (props) => {
  const {
    value: text,
    onBlur,
    className,
    inputProps,
    margin,
    dataTestid,
    label,
    onChange = (fun, value) => fun(value),
    type,
    ...rest
  } = props;
  const [typedText, typeTheText] = useState(text);
  React.useEffect(() => {
    typeTheText(text);
  }, [text, typeTheText]);

  return (
    <MaterialuiTextInput
      inputProps={inputProps}
      data-testid={dataTestid}
      label={label}
      margin={margin}
      type={type}
      className={className}
      value={typedText}
      onChange={(event) => onChange(typeTheText, event.target.value)}
      onBlur={() => onBlur(typedText)}
      {...rest}
    />
  );
};

/**
 * This is the same as above but is better than using the above with just type="number" because it
 * fixes the bug where you can't delete 0 and the like.
 */
export const NumberInput = (props) => {
  const [typedNumber, typeTheNumber] = useState();
  const {
    value: number,
    onBlur,
    className,
    margin,
    dataTestid,
    label,
    inputProps,
    onChange = (v) => v,
    ...rest
  } = props;

  const realInputProps = {
    ...inputProps,
    "data-testid": dataTestid,
  };

  // IMPLEMENT MIN / MAX!!!!!

  const castValue = (value) => {
    // This lets us nullify a field and ensures only numbers are allowed.
    // I mean good god. WTF is up with browsers. It's probably very buggy.
    // eg allowing multiple `-` and `.`s. Nothing is perfect I guess...
    const newValue = value.replace(/[^\d.-]/g, "");
    if (newValue === "") return null;
    return newValue;
  };

  React.useEffect(() => {
    typeTheNumber(number);
  }, [number, typeTheNumber]);

  const onRealChange = (value) => {
    typeTheNumber(value);
    onChange(value);
  };

  return (
    <MaterialuiTextInput
      inputProps={realInputProps}
      label={label}
      margin={margin}
      // Type text otherwise the onChange doesn't work (letters come in as blank strings
      // which means you can't seem to differentiate between nullified field and a letter.
      // and you'd want to do different things in each case.
      // This does mean we need to implement min manually though UGH.
      type="text"
      className={className}
      // This silences a warning saying null for an input is not okay.
      value={typedNumber === null || typedNumber === undefined ? "" : typedNumber}
      onChange={(event) => {
        onRealChange(castValue(event.target.value));
      }}
      onBlur={() => onBlur(Number(typedNumber))}
      {...rest}
    />
  );
};

// /**
//  * This uses the native select for better mobile experience. It works the same as the other Graphql
//  * inputs, submitting onBlur
//  */

// export const GraphqlSelect = (props) => {
//   const { onBlur, label, options, defaultSelectedOption } = props;
//   const [selectedOption, selectOption] = useState(defaultSelectedOption);
//   React.useEffect(() => {
//     selectOption(defaultSelectedOption);
//   }, [defaultSelectedOption]);

//   return (
//     <FormControl>
//       <InputLabel>{label}</InputLabel>
//       <Select
//         native
//         value={selectedOption}
//         onChange={(event) => selectOption(event.target.value)}
//       >
//         {options.map(({ option, value }) => {
//           return (
//             <option key={value} value={value}>
//               {option}
//             </option>
//           );
//         })}
//       </Select>
//     </FormControl>
//   );
// };

/**
Checkbox state is always just the state that it's given and the onChange function can be a graphql
mutation or whatever really.
**/
export const Checkbox = (props) => {
  const { checked, color, onClick, className, inputProps, dataTestid, ...rest } = props;

  const realInputProps = {
    ...inputProps,
    "data-testid": dataTestid,
  };
  return (
    <MaterialuiCheckbox
      checked={checked}
      color={color}
      onClick={onClick}
      inputProps={realInputProps}
      className={className}
      {...rest}
    />
  );
};
