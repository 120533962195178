import { gql } from "@apollo/client";
import React from "react";
import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
import { InfoNextToText } from "components/InfoNextToText";
import { H3 } from "components/typography/H3";
import { useTheme } from "@material-ui/core/styles";
import { GraphqlInput } from "components/inputs/Inputs";
import { GraphqlMutation } from "graphqlUtils/GraphqlMutation";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { GraphqlQuery } from "graphqlUtils/GraphqlQuery";
import { ulid } from "ulid";
import { Loading } from "components/Loading";
import { some } from "lodash";
import {
  Container,
  DialogTitle,
  DialogContent,
  DialogActions,
  Dialog,
  Tooltip,
  Typography,
  Chip,
  useMediaQuery,
  Button,
  IconButton,
} from "@material-ui/core";

const UPDATE_SENSITIVITY = gql`
  mutation UpdateSensitivity($input: UpdateSensitivityInput!) {
    updateSensitivity(input: $input) {
      id
      name
      readOnly
      mealComponentInformation {
        id
        mealName
        present
        sensitivityId
        mealComponentId
      }
    }
  }
`;

const SET_ACTIVE = gql`
  mutation UpdateSensitivityActive($input: SetActiveSensitivityInput!) {
    setActiveSensitivity(input: $input) {
      id
      name
      active
      readOnly
      mealComponentInformation {
        id
        mealName
        present
        sensitivityId
        mealComponentId
      }
    }
  }
`;

const DELETE_SENSITIVITY = gql`
  mutation DeleteSensitivity($id: ID!) {
    deleteSensitivity(id: $id) {
      id
    }
  }
`;

const useStyles = makeStyles({
  inputStyles: { marginBottom: "15px", width: "60%", marginRight: "30px" },
  nameBlurb: { marginBottom: "20px" },
  container: { paddingTop: "30px", maxWidth: "750px" },
  noBtn: { marginLeft: "50px", maxHeight: "35px" },
  yesBtn: { marginRight: "50px", maxHeight: "35px" },
  chip: { marginRight: "15px", marginBottom: "10px" },
  inactiveBtn: { maxHeight: "40px" },
});

const Form = styled.div`
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: center;
`;

const NextPrev = styled.div`
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: space-between;
  margin-top: 30px;
  margin-bottom: 30px;
`;

const InputWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const SENSITIVITY = gql`
  query Sensitivity($id: ID!) {
    sensitivity(id: $id) {
      id
      name
      active
      readOnly
      mealComponentInformation {
        id
        mealName
        present
        sensitivityId
        mealComponentId
      }
    }
  }
`;

export const EditSensitivityModal = (props) => {
  return (
    <GraphqlQuery
      fetchPolicy="network-only"
      query={SENSITIVITY}
      variables={{ id: props.id }}
      withError
    >
      {({ data, error }) => {
        if (error) {
          return;
        }

        return <SensitivityModal {...props} sensitivity={data.sensitivity} />;
      }}
    </GraphqlQuery>
  );
};

const SensitivityModal = (props) => {
  const { open, onCancel, onSubmit, refetchQueries, sensitivity, mealComponents } = props;
  const { nameBlurb, inputStyles, container, noBtn, yesBtn, chip, inactiveBtn } = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [mealIndex, setMealIndex] = React.useState(0);
  const [formState, setFormState] = React.useState({});
  const [focused, setFocused] = React.useState(false);
  const [mealComponentList, setMealComponentList] = React.useState(mealComponents);
  const [mealComponentInformation, setMealComponentInformation] = React.useState({});

  React.useEffect(() => {
    const mciComponentNames = sensitivity.mealComponentInformation.map(
      ({ mealName }) => mealName,
    );
    const newSens = mealComponents
      .filter(({ name }) => !some(mciComponentNames, (compName) => compName === name))
      .map(({ id, name }) => ({
        id: ulid(),
        present: null,
        sensitivityId: sensitivity.id,
        mealComponentId: id,
        mealName: name,
      }));

    const mapped = sensitivity.mealComponentInformation.map(
      ({ id, present, mealName, mealComponentId, sensitivityId }) => ({
        id,
        mealName,
        sensitivityId,
        mealComponentId,
        present,
      }),
    );
    const allSensitivityInformation = [...mapped, ...newSens];

    setMealComponentList(allSensitivityInformation);
    setMealComponentInformation(
      sensitivity.mealComponentInformation.reduce((acc, info) => {
        const mealInfo = {
          [info.id]: {
            id: info.id,
            mealComponentId: info.mealComponentId,
            sensitivityId: info.sensitivityId,
            mealComponentName: info.mealName,
            present: info.present,
          },
        };
        return { ...acc, ...mealInfo };
      }, {}),
    );

    setFormState({
      name: sensitivity.name,
      active: sensitivity.active,
      readOnly: sensitivity.readOnly,
      mealComponentInformation: allSensitivityInformation,
    });
  }, [
    sensitivity,
    setMealComponentInformation,
    setMealComponentList,
    setFormState,
    mealComponents,
  ]);

  // eslint-disable-next-line
  const activeMealCompInfo = mealComponentList[mealIndex] || {};
  const checked = mealComponentInformation[activeMealCompInfo.id]
    ? mealComponentInformation[activeMealCompInfo.id].present
    : null;

  const setActiveComponent = React.useCallback(
    (present) => {
      const mealInfo = {
        [activeMealCompInfo.id]: {
          id: activeMealCompInfo.id,
          sensitivityId: activeMealCompInfo.sensitivityId,
          mealComponentId: activeMealCompInfo.mealComponentId,
          mealComponentName: activeMealCompInfo.mealName,
          present,
        },
      };

      setMealComponentInformation({ ...mealComponentInformation, ...mealInfo });
    },
    [setMealComponentInformation, activeMealCompInfo, mealComponentInformation],
  );

  const yes = React.useCallback(() => {
    const newMealComponentInformation = formState.mealComponentInformation.map((info) => {
      if (info.id === activeMealCompInfo.id) {
        return { ...info, present: true };
      } else {
        return info;
      }
    });

    setFormState({ ...formState, mealComponentInformation: newMealComponentInformation });
    setActiveComponent(true);
  }, [setActiveComponent, setFormState, formState, activeMealCompInfo]);

  const no = React.useCallback(() => {
    const newMealComponentInformation = formState.mealComponentInformation.map((info) => {
      if (info.id === activeMealCompInfo.id) {
        return { ...info, present: false };
      } else {
        return info;
      }
    });

    setFormState({ ...formState, mealComponentInformation: newMealComponentInformation });
    setActiveComponent(false);
  }, [setActiveComponent, setFormState, formState, activeMealCompInfo]);

  const maxIndex = mealComponentList.length - 1;
  const onLastPage = mealIndex === maxIndex;

  const next = React.useCallback(() => {
    if (onLastPage) {
      return;
    }
    setMealIndex(mealIndex + 1);
  }, [setMealIndex, onLastPage, mealIndex]);

  const previous = React.useCallback(() => {
    if (mealIndex <= 0) {
      return;
    }
    const minIndex = 0;
    const newIndex = mealIndex - 1;
    if (newIndex > minIndex) {
      setMealIndex(newIndex);
    } else {
      setMealIndex(minIndex);
    }
  }, [setMealIndex, mealIndex]);

  // Add event listeners for key presses so we can left / right arrow through orders
  // We need to be sure we don't listen when we are typing in the text field
  React.useEffect(() => {
    function downHandler({ key }) {
      if (focused) {
        return;
      }
      if (key === "ArrowLeft") {
        previous();
      }
      if (key === "ArrowRight") {
        next();
      }
      if (key === "y") {
        yes();
      }
      if (key === "n") {
        no();
      }
    }

    if (open) {
      window.addEventListener("keydown", downHandler);
      // Remove event listeners on cleanup
      return () => {
        window.removeEventListener("keydown", downHandler);
      };
    }
  }, [next, previous, open, yes, no, focused]);

  const resetForm = () => {
    setMealComponentInformation({});
    setFormState({});
    setMealIndex(0);
  };

  const mealInformation = Object.entries(mealComponentInformation).map((key_value) => {
    return key_value[1];
  });

  const inactiveTip = `Making a sensitivity inactive will prevent it from being checked for when deciding\
   if a child can eat a given meal.`;
  const readOnlyTip =
    "Read only sensitivities will be checked for when generating diet advice, but nurseries wont be able to select it as an option; only admins can";
  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      fullScreen={fullScreen}
      fullWidth
      maxWidth="md"
      open={open}
    >
      <Container className={container}>
        <DialogTitle disableTypography>
          <Typography variant="h4" component="h4">
            Edit a Sensitivity
          </Typography>
        </DialogTitle>
        <DialogContent>
          <InputWrapper>
            <GraphqlInput
              className={inputStyles}
              required
              onFocus={() => setFocused(true)}
              label="Change the name..."
              value={sensitivity.name}
              onBlur={(name) => {
                setFocused(false);
                setFormState({ ...formState, name });
              }}
            />
            <Tooltip title={inactiveTip} placement="bottom-start">
              <Button
                className={inactiveBtn}
                onClick={() => setFormState({ ...formState, active: !formState.active })}
                variant="outlined"
                color={formState.active ? "primary" : "secondary"}
              >
                Inactive
              </Button>
            </Tooltip>
            <Tooltip title={readOnlyTip} placement="bottom-start">
              <Button
                style={{ marginLeft: "5px" }}
                className={inactiveBtn}
                onClick={() => setFormState({ ...formState, readOnly: !formState.readOnly })}
                variant="outlined"
                color={formState.readOnly ? "secondary" : "primary"}
              >
                Read Only
              </Button>
            </Tooltip>
          </InputWrapper>
        </DialogContent>
        <DialogContent className={nameBlurb}>
          <H3>Meal Components</H3>
          <Typography variant="body2" component="p">
            <InfoNextToText /> When you add a sensitivity for a given week, you must check all of
            the meal components that are available that week, and tell us whether or not they
            contain the new sensitivity.
          </Typography>
          <Typography variant="body2" component="p">
            You can use the arrow keys to move through the list.
          </Typography>
        </DialogContent>
        <DialogContent>
          {mealInformation
            .filter(({ present }) => present)
            .map((thing) => {
              const { mealComponentId, mealComponentName } = thing;
              return (
                <Chip
                  clickable={false}
                  className={chip}
                  key={mealComponentId}
                  variant="outlined"
                  color="primary"
                  label={mealComponentName}
                />
              );
            })}
        </DialogContent>
        <DialogContent>
          <Form>
            <H3>
              Does {activeMealCompInfo.mealName} contain {sensitivity.name}?
            </H3>
          </Form>
        </DialogContent>
        <DialogContent>
          <NextPrev>
            <IconButton disabled={mealIndex <= 0} onClick={previous}>
              <ArrowBackIosIcon />
            </IconButton>
            <Button
              onClick={no}
              variant="contained"
              className={noBtn}
              color={checked === false ? "secondary" : "primary"}
            >
              No
            </Button>
            <Button
              onClick={yes}
              variant="contained"
              className={yesBtn}
              color={checked ? "secondary" : "primary"}
            >
              Yes
            </Button>
            <IconButton disabled={mealIndex >= maxIndex} onClick={next}>
              <ArrowForwardIosIcon />
            </IconButton>
          </NextPrev>
        </DialogContent>
        <DialogActions>
          <GraphqlMutation mutation={DELETE_SENSITIVITY}>
            {(deleteSens) => {
              return (
                <Button
                  color="secondary"
                  onClick={async () => {
                    const confirm = window.prompt(
                      `WARNING! This will permanently delete the sensitivity from the whole system, including\
 past orders.

 If the sensitivity is no longer checked for but it used to be, make it inactive instead. You
 should only delete if adding it was a mistake.

 Type DELETE below to confirm action.
 `,
                    );
                    if (confirm === "DELETE") {
                      const result = await deleteSens({
                        variables: { id: sensitivity.id },
                        refetchQueries,
                      });
                      if (result.data) {
                        onSubmit();
                      }
                    }
                  }}
                >
                  Delete Forever
                </Button>
              );
            }}
          </GraphqlMutation>
          <GraphqlMutation mutation={SET_ACTIVE} withError>
            {(setActive) => {
              return (
                <GraphqlMutation mutation={UPDATE_SENSITIVITY} withError>
                  {(updateSens, { loading }) => {
                    const setActiveVariables = {
                      input: {
                        id: sensitivity.id,
                        active: formState.active,
                        mealComponentInformation: formState.mealComponentInformation.map(
                          ({ id, present, sensitivityId, mealComponentId }) => ({
                            id,
                            present,
                            sensitivityId,
                            mealComponentId,
                          }),
                        ),
                      },
                    };

                    const variables = {
                      input: {
                        id: sensitivity.id,
                        name: formState.name,
                        readOnly: formState.readOnly,
                        mealComponentInformation: formState.mealComponentInformation.map(
                          ({ id, present, sensitivityId, mealComponentId }) => ({
                            id,
                            present,
                            sensitivityId,
                            mealComponentId,
                          }),
                        ),
                      },
                    };

                    const submit = async () => {
                      const data = await updateSens({ variables, refetchQueries });
                      const result = await setActive({
                        variables: setActiveVariables,
                        refetchQueries,
                      });

                      if (data.data && result.data) {
                        onSubmit();
                      }
                    };

                    return (
                      <>
                        <Button
                          disabled={loading}
                          onClick={() => {
                            resetForm();
                            onCancel();
                          }}
                          color="primary"
                        >
                          Cancel
                        </Button>
                        {loading ? (
                          <Loading />
                        ) : (
                          <Button
                            disabled={
                              loading ||
                              some(
                                formState.mealComponentInformation.map(({ present }) => present),
                                (presence) => presence === null,
                              )
                            }
                            onClick={submit}
                            color="primary"
                          >
                            Save
                          </Button>
                        )}
                      </>
                    );
                  }}
                </GraphqlMutation>
              );
            }}
          </GraphqlMutation>
        </DialogActions>
      </Container>
    </Dialog>
  );
};
