import { gql } from "@apollo/client";
import React from "react";
import { UserContext } from "contexts";
import { PageHeading } from "components/PageHeading";
import { NurseryDetails } from "containers/nurserySettings/NurseryDetails";
import { GraphqlQuery } from "graphqlUtils/GraphqlQuery";
import { NurseryContacts } from "containers/nurserySettings/NurseryContacts";
import { PasswordReset } from "containers/nurserySettings/PasswordReset";
import { Contract } from "containers/nurserySettings/Contract";

const NURSERY = gql`
  query nursery($id: ID!) {
    nursery(id: $id) {
      id
      name
      address
      isActive
      phoneNumber
      contractType
      lunchOrderMinimum
      teaOrderMinimum
      currentLunchProvider
      currentTeaProvider
      defaultLunchCostPerMeal
      defaultTeaCostPerMeal
      nurseryContacts {
        id
        name
        userId
        email
        shouldReceiveReminders
        agreedToReceiveReminders
      }
      daysClosed {
        id
        date
        closedFor
      }
      daysOpen {
        id
        date
        openFor
      }
    }
  }
`;

export const Settings = () => {
  const user = React.useContext(UserContext);
  // If we are here, we are a nursery contact so this will not be null.
  const { nurseryId } = user;
  const refetchQueries = [{ query: NURSERY, variables: { id: nurseryId } }];

  return (
    <PageHeading maxWidth="md" heading="Settings">
      <GraphqlQuery query={NURSERY} variables={{ id: nurseryId }} withError withLoading>
        {({ data }) => {
          if (!data) return null;
          const { nursery } = data;

          return (
            <>
              <NurseryDetails nursery={nursery} />
              <NurseryContacts
                nurseryId={nurseryId}
                refetchQueries={refetchQueries}
                nurseryContacts={nursery.nurseryContacts}
              />
              <PasswordReset />
              <Contract
                showContractSelect={false}
                nursery={nursery}
                refetchQueries={refetchQueries}
              />
            </>
          );
        }}
      </GraphqlQuery>
    </PageHeading>
  );
};
