import { gql } from "@apollo/client";
import React from "react";
import styled from "styled-components";
import { PageHeading } from "components/PageHeading";
import { GraphqlQuery } from "graphqlUtils/GraphqlQuery";
import { makeStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import { GraphqlMutation } from "graphqlUtils/GraphqlMutation";
import { CentredErrorIcon } from "components/CentredErrorIcon";
import { Typography, IconButton, Paper, Link, TextField, Button } from "@material-ui/core";
import { CreateInvoiceGroupModal } from "containers/invoiceGroup/CreateInvoiceGroupModal";
import { UpdateInvoiceGroupModal } from "containers/invoiceGroup/UpdateInvoiceGroupModal";
import Autocomplete from "@material-ui/lab/Autocomplete";
import DeleteIcon from "@material-ui/icons/Delete";
import { FilterableNurseriesTable } from "containers/nurseries/FilterableNurseriesTable";

const NURSERIES = gql`
  query Nurseries {
    nurseries {
      id
      name
      isActive
    }
  }
`;

const GROUPS = gql`
  query InvoiceGroups {
    invoiceGroups {
      id
      name
      nurseries {
        id
        name
        isActive
      }
    }
  }
`;

const ADD_TO_GROUP = gql`
  mutation AddNurseryToGroup($groupId: ID!, $nurseryIds: [ID!]!) {
    addNurseryToGroup(groupId: $groupId, nurseryIds: $nurseryIds) {
      id
      name
      nurseries {
        id
        name
        isActive
      }
    }
  }
`;

const DELETE_GROUP = gql`
  mutation DeleteInvoiceGroup($id: ID!) {
    deleteInvoiceGroup(id: $id) {
      id
      name
    }
  }
`;

const Div = styled.div`
  display: flex;
  justify-content: space-between;
`;

const useStyles = makeStyles({
  groupSection: { padding: "15px", marginBottom: "15px" },
  chip: { marginBottom: "5px", marginRight: "5px" },
  autocomplete: { margin: "30px" },
});

export const InvoiceGroups = () => {
  const [open, setCreateModal] = React.useState(false);
  const [editModalOpen, setEditModal] = React.useState(false);
  const [editingIg, setIgToEdit] = React.useState("");
  const { groupSection, autocomplete } = useStyles();

  const openEditModal = (invoiceGroup) => {
    setIgToEdit(invoiceGroup);
    setEditModal(true);
  };

  const openCreateModal = () => setCreateModal(true);
  const closeCreateModal = () => setCreateModal(false);
  const closeEditModal = () => setEditModal(false);

  return (
    <PageHeading maxWidth="md" heading="Invoice Groups">
      <Typography style={{ marginBottom: "15px" }}>
        Invoice groups let you combine several nursery’s orders into one invoice. This is useful
        if there is one parent company, or if the nursery splits their orders for some reason.
      </Typography>
      <Typography style={{ marginBottom: "15px" }}>
        The name of the group should exactly match the name of the contact on Xero. All nurseries
        must belong to at least one invoice group, even if that group consists of just them.
      </Typography>
      <Typography style={{ marginBottom: "15px" }}>
        An invoice group may only be deleted if there are no nurseries in the group, so if you
        wish to remove a group you must first move the nurseries into a different invoice group.
      </Typography>
      <GraphqlQuery query={GROUPS} withLoading withError>
        {({ data, error }) => {
          if (error) {
            return <CentredErrorIcon />;
          }
          const { invoiceGroups } = data;
          return (
            <GraphqlQuery query={NURSERIES} withLoading withError>
              {({ data, error }) => {
                if (error) {
                  return <CentredErrorIcon />;
                }
                const { nurseries } = data;
                const activeNurseries = nurseries.filter((n) => n.isActive === true);
                return (
                  <FilterableNurseriesTable
                    // This bit of gymnastics is the simplest way I could think to get
                    // the FilterableNurseriesTable to work as is. This is really bad tho.
                    data={invoiceGroups.reduce((acc, ig) => {
                      if (ig.nurseries.length > 0) {
                        return ig.nurseries.reduce(
                          (accum, n) => acc.concat([{ ...n, invoiceGroup: ig }]),
                          acc,
                        );
                      } else {
                        return acc.concat([{ invoiceGroup: ig }]);
                      }
                    }, [])}
                  >
                    {({ nurseriesList }) => {
                      return (
                        <GraphqlMutation mutation={ADD_TO_GROUP} withError>
                          {(addToGroup, { loading }) => {
                            // This has the nice benefit of also respecting the order of
                            // Nurseries... BUT we end up with dups I think.
                            return nurseriesList.map((nursery) => {
                              const group = nursery.invoiceGroup;
                              const refetchQueries = [{ query: GROUPS }];
                              const add = (nurseries) => {
                                const nurseryIds = nurseries.map(({ id }) => id);
                                addToGroup({
                                  variables: { groupId: group.id, nurseryIds },
                                  refetchQueries,
                                });
                              };

                              return (
                                <Paper className={groupSection} key={group.id}>
                                  <Div>
                                    <Link
                                      style={{
                                        textDecoration: "underline",
                                        marginBottom: "15px",
                                      }}
                                      href="#"
                                      onClick={(event) => {
                                        event.preventDefault();
                                        openEditModal(group);
                                      }}
                                    >
                                      <Typography variant="button">{group.name}</Typography>
                                    </Link>
                                    <GraphqlMutation mutation={DELETE_GROUP} withError>
                                      {(deleteTheGroup, { loading }) => {
                                        const onClick = async () => {
                                          await deleteTheGroup({
                                            variables: { id: group.id },
                                            refetchQueries,
                                          });
                                        };
                                        return (
                                          <IconButton
                                            disabled={loading || group.nurseries.length > 0}
                                            onClick={onClick}
                                          >
                                            <DeleteIcon />
                                          </IconButton>
                                        );
                                      }}
                                    </GraphqlMutation>
                                  </Div>
                                  <Autocomplete
                                    multiple
                                    blurOnSelect
                                    disableClearable
                                    disabled={loading}
                                    filterSelectedOptions
                                    className={autocomplete}
                                    value={group.nurseries}
                                    // We should only be able to choose from active nurseries
                                    // to avoid cluttering the UI
                                    options={activeNurseries}
                                    getOptionSelected={(option, value) => option.id === value.id}
                                    getOptionLabel={(option) => option.name}
                                    ChipProps={{
                                      variant: "outlined",
                                      color: "secondary",
                                      size: "small",
                                      onDelete: undefined,
                                    }}
                                    onChange={(_, values) => add(values)}
                                    renderInput={(params) => {
                                      return (
                                        <TextField
                                          {...params}
                                          required
                                          label="Select nurseries to join the group"
                                          variant="outlined"
                                          placeholder="Type to filter the list..."
                                        />
                                      );
                                    }}
                                  />
                                </Paper>
                              );
                            });
                          }}
                        </GraphqlMutation>
                      );
                    }}
                  </FilterableNurseriesTable>
                );
              }}
            </GraphqlQuery>
          );
        }}
      </GraphqlQuery>
      <Button variant="outlined" color="primary" aria-label="add" onClick={openCreateModal}>
        Add Invoice Group&nbsp;&nbsp;
        <AddIcon />
      </Button>
      {open && (
        <CreateInvoiceGroupModal
          onCancel={closeCreateModal}
          open={open}
          onSubmit={closeCreateModal}
          refetchQueries={[{ query: GROUPS, variables: {} }]}
        />
      )}
      {editModalOpen && (
        <UpdateInvoiceGroupModal
          invoiceGroup={editingIg}
          onCancel={closeEditModal}
          open={editModalOpen}
          onSubmit={closeEditModal}
        />
      )}
    </PageHeading>
  );
};
