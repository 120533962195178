import React from "react";
import { Switch, Route } from "react-router-dom";
import { NurseryOrderPage } from "pages/nursery/Order";
import { LoggedInRoute } from "containers/routes/LoggedInRoute";
import { Nurseries } from "pages/admin/Nurseries";
import { NurserySettings } from "pages/admin/NurserySettings";
import { FourOhFour } from "components/FourOhFour";
import { Sensitivities } from "pages/admin/Sensitivities";
import { SensitivitiesCheck } from "pages/admin/SensitivitiesCheck";
import { MealComponents } from "pages/admin/MealComponents";
import { UserManagement } from "pages/admin/UserManagement";
import { Menus } from "pages/admin/Menus";
import { PastMenus } from "pages/admin/PastMenus";
import { OrdersSummary } from "pages/admin/OrdersSummary";
import { DeliveryFormPage } from "pages/nursery/DeliveryFormPage";
import { PDFHome } from "containers/PDFHome";
import { HungryMonstersSettings } from "pages/admin/HungryMonstersSettings";
import { NurseryDietAdvicePage } from "pages/nursery/NurseryDietAdvicePage";
import { Invoices } from "pages/admin/Invoices";
import { NurseryInvoice } from "pages/admin/NurseryInvoice";
import { NurserySettingsList } from "pages/admin/NurserySettingsList";
import { NurseryOrdersList } from "pages/admin/NurseryOrdersList";
import { NurseryDietAdviceList } from "pages/admin/NurseryDietAdviceList";
import { InvoiceGroups } from "pages/admin/InvoiceGroups";
import { DeliveryFormsPage } from "pages/deliveryDriver/DeliveryFormsPage";
import { PrintableDietAdvice } from "pages/admin/PrintableDietAdvice";
import { Tins } from "pages/admin/Tins";
import { PortionSizes } from "pages/admin/PortionSizes";
import { NurseryPortionInfoList } from "pages/admin/NurseryPortionInfoList";
import { NurseryPortionInfo } from "pages/admin/NurseryPortionInfo";
import { PDFUpload } from "pages/admin/PDFUpload";
import { Faqs } from "pages/nursery/Faqs";
import { SetFaqs } from "pages/admin/SetFaqs";
import { PackingReport } from "pages/deliveryDriver/PackingReport";
import { KitchenDistributionReport } from "pages/deliveryDriver/KitchenDistributionReport";

export const AdminHome = () => {
  return (
    <Switch>
      <LoggedInRoute exact path="/" component={PDFHome} />
      <LoggedInRoute exact path="/upload-pdf" component={PDFUpload} />
      <LoggedInRoute exact path="/nurseries" component={Nurseries} />
      <LoggedInRoute exact path="/sensitivities" component={Sensitivities} />
      <LoggedInRoute exact path="/sensitivities-check" component={SensitivitiesCheck} />
      <LoggedInRoute exact path="/menus" component={Menus} />
      <LoggedInRoute exact path="/tins" component={Tins} />
      <LoggedInRoute
        exact
        path="/kitchen-distribution-report/date/:date"
        component={KitchenDistributionReport}
      />
      <LoggedInRoute exact path="/packing-reports/date/:date" component={PackingReport} />
      <LoggedInRoute exact path="/preview-faqs" component={Faqs} />
      <LoggedInRoute exact path="/faqs" component={SetFaqs} />
      <LoggedInRoute exact path="/portion-sizes" component={PortionSizes} />
      <LoggedInRoute
        exact
        path="/nurseries/portion-information/date/:date"
        component={NurseryPortionInfoList}
      />
      <LoggedInRoute
        exact
        path="/nursery/:nurseryId/portion-information/date/:date"
        component={NurseryPortionInfo}
      />
      <LoggedInRoute exact path="/settings" component={HungryMonstersSettings} />
      <LoggedInRoute exact path="/orders-summary/date/:date" component={OrdersSummary} />
      <LoggedInRoute exact path="/past-menus/date/:date" component={PastMenus} />
      <LoggedInRoute exact path="/meal-components" component={MealComponents} />
      <LoggedInRoute exact path="/nursery/:nurseryId" component={NurserySettings} />
      <LoggedInRoute exact path="/user-management" component={UserManagement} />
      <LoggedInRoute exact path="/delivery-forms/date/:date" component={DeliveryFormsPage} />
      <LoggedInRoute
        exact
        path="/printable-diet-advice/date/:date"
        component={PrintableDietAdvice}
      />
      <LoggedInRoute exact path="/invoice-groups" component={InvoiceGroups} />
      <LoggedInRoute exact path="/invoices/date/:date" component={Invoices} />
      <LoggedInRoute exact path="/nurseries/settings" component={NurserySettingsList} />
      <LoggedInRoute exact path="/nurseries/orders/date/:date" component={NurseryOrdersList} />
      <LoggedInRoute
        exact
        path="/nurseries/diet-advice/date/:date"
        component={NurseryDietAdviceList}
      />
      <LoggedInRoute
        exact
        path="/invoices/nursery/:nurseryId/date/:date"
        component={NurseryInvoice}
      />
      <LoggedInRoute
        exact
        path="/diet-advice/:nurseryId/date/:date"
        component={NurseryDietAdvicePage}
      />
      <LoggedInRoute exact path="/nursery/:nurseryId/date/:date" component={NurseryOrderPage} />
      <LoggedInRoute
        exact
        path="/delivery-forms/:nurseryId/date/:date"
        component={DeliveryFormPage}
      />
      <Route component={FourOhFour} />
    </Switch>
  );
};
