import { gql } from "@apollo/client";
import React from "react";
import styled from "styled-components";
import {
  Typography,
  Card,
  CardActions,
  CardContent,
  FormControl,
  InputLabel,
  Select,
} from "@material-ui/core";
import { NumberInput } from "components/inputs/Inputs";
import { GraphqlMutation } from "graphqlUtils/GraphqlMutation";

const ProviderWrapper = styled.section`
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
`;

const ProviderForm = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 250px;
  width: 310px;
`;

const UPDATE_LUNCH_PROVIDER = gql`
  mutation UpdateLunch($input: UpdateNurseryInput!) {
    updateNursery(input: $input) {
      id
      lunchOrderMinimum
      currentLunchProvider
      defaultLunchCostPerMeal
    }
  }
`;

const UPDATE_TEA_PROVIDER = gql`
  mutation UpdateTea($input: UpdateNurseryInput!) {
    updateNursery(input: $input) {
      id
      currentTeaProvider
      teaOrderMinimum
      defaultTeaCostPerMeal
    }
  }
`;

export const UpdateProvidersForm = (props) => {
  const { nursery, refetchQueries } = props;
  return (
    <GraphqlMutation mutation={UPDATE_LUNCH_PROVIDER} withError>
      {(updateLunchProvider) => {
        const onLunchProviderDetailsChange = async (field, value) => {
          const variables = { input: { id: nursery.id, [field]: value } };
          await updateLunchProvider({ variables, refetchQueries });
        };

        const selectLunchProvider = async (event) => {
          const value = event.target.value === "" ? null : event.target.value;
          const variables = { input: { id: nursery.id, currentLunchProvider: value } };
          await updateLunchProvider({ variables, refetchQueries });
        };

        return (
          <GraphqlMutation mutation={UPDATE_TEA_PROVIDER} withError>
            {(updateTeaProvider) => {
              const onTeaProviderDetailsChange = async (field, value) => {
                const variables = { input: { id: nursery.id, [field]: value } };
                await updateTeaProvider({ variables, refetchQueries });
              };

              const selectTeaProvider = async (event) => {
                // This lets us nullify it in the BE if we want.
                const value = event.target.value === "" ? null : event.target.value;
                const variables = { input: { id: nursery.id, currentTeaProvider: value } };
                await updateTeaProvider({ variables, refetchQueries });
              };

              return (
                <ProviderWrapper>
                  <Providers
                    selectTeaProvider={selectTeaProvider}
                    onTeaProviderDetailsChange={onTeaProviderDetailsChange}
                    selectLunchProvider={selectLunchProvider}
                    onLunchProviderDetailsChange={onLunchProviderDetailsChange}
                    nursery={nursery}
                  />
                </ProviderWrapper>
              );
            }}
          </GraphqlMutation>
        );
      }}
    </GraphqlMutation>
  );
};

export const Providers = (props) => {
  const {
    nursery,
    selectLunchProvider,
    onLunchProviderDetailsChange,
    selectTeaProvider,
    onTeaProviderDetailsChange,
    variant,
  } = props;

  return (
    <React.Fragment>
      <Card variant={variant}>
        <CardContent>
          <Typography variant="h6">Lunch</Typography>
          <CardActions>
            <ProviderForm>
              <FormControl>
                <InputLabel>Provider</InputLabel>
                <Select
                  native
                  value={nursery.currentLunchProvider || ""}
                  onChange={selectLunchProvider}
                >
                  {/* Null option */}
                  <option value={""}></option>
                  <option value="HUNGRY_MONSTERS">Hungry Monsters</option>
                  <option value="HELENS_TEAS">Helen&apos;s Teas</option>
                </Select>
              </FormControl>
              <NumberInput
                label="Daily Minimum Meals"
                value={nursery.lunchOrderMinimum || ""}
                onBlur={(value) => onLunchProviderDetailsChange("lunchOrderMinimum", value)}
              />
              <NumberInput
                label="Cost Per Meal"
                value={nursery.defaultLunchCostPerMeal || ""}
                onBlur={(value) => onLunchProviderDetailsChange("defaultLunchCostPerMeal", value)}
              />
            </ProviderForm>
          </CardActions>
        </CardContent>
      </Card>
      <Card variant={variant}>
        <CardContent>
          <Typography variant="h6">Teas</Typography>
          <CardActions>
            <ProviderForm>
              <FormControl>
                <InputLabel>Provider</InputLabel>
                <Select
                  native
                  value={nursery.currentTeaProvider || ""}
                  onChange={selectTeaProvider}
                >
                  {/* Null option */}
                  <option value={""}></option>
                  <option value="HUNGRY_MONSTERS">Hungry Monsters</option>
                  <option value="HELENS_TEAS">Helen&apos;s Teas</option>
                </Select>
              </FormControl>
              <NumberInput
                label="Daily Minimum Meals"
                value={nursery.teaOrderMinimum || ""}
                onBlur={(value) => onTeaProviderDetailsChange("teaOrderMinimum", value)}
              />
              <NumberInput
                label="Cost Per Meal"
                value={nursery.defaultTeaCostPerMeal || ""}
                onBlur={(value) => onTeaProviderDetailsChange("defaultTeaCostPerMeal", value)}
              />
            </ProviderForm>
          </CardActions>
        </CardContent>
      </Card>
    </React.Fragment>
  );
};
