import { gql } from "@apollo/client";
import React from "react";
import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
import { PageHeading } from "components/PageHeading";
import { GraphqlQuery } from "graphqlUtils/GraphqlQuery";
import { CentredErrorIcon } from "components/CentredErrorIcon";
import { AddMealComponentModal } from "containers/mealComponents/AddMealComponentModal";
import { EditMealComponentModal } from "containers/mealComponents/EditMealComponentModal";
import AddIcon from "@material-ui/icons/Add";
import { SENSITIVITIES, ALL_ACTIVE_MEAL_COMPONENTS } from "pages/admin/Sensitivities";
import { ACTIVE_MEAL_COMPONENTS } from "containers/menus/queries";
import { ACTIVE_SENSITIVITIES } from "containers/orders/EditChildModal";
import {
  Table,
  TableContainer,
  Typography,
  TableCell,
  TableBody,
  TableRow,
  TableHead,
  Link,
  Chip,
  Button,
} from "@material-ui/core";
import { TextInput } from "components/inputs/Inputs";
import { LazyGraphqlQuery } from "graphqlUtils/LazyGraphqlQuery";
import { isEqual } from "lodash";
import { Loading } from "components/Loading";

export const MEAL_COMPONENTS = gql`
  query MealComponents {
    mealComponents {
      id
      name
      active
      sensitivityInformation(present: true) {
        id
        sensitivityName
        present
        sensitivityId
        mealComponentId
      }
    }
  }
`;

// We query for these to merge with the MCI of the MCs in the case that a MC is being re-activated
// after some new sensitivities have been added.
export const ALL_ACTIVE_SENSITIVITIES = gql`
  query ActiveSensitivities($active: Boolean!) {
    sensitivities(active: $active) {
      id
      name
      order
      mealComponentInformation {
        id
        mealName
        present
      }
    }
  }
`;

const TableWrapper = styled.div`
  background-color: white;
  margin-bottom: 30px;
  max-height: 66vh;
`;

const useStyles = makeStyles({
  icon: { height: "20px", position: "relative" },
  info: { marginBottom: "30px" },
});

const Flexbox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-content: center;
  width: 100%;
  margin-bottom: 15px;
`;

const SEARCH_MEAL_COMPONENTS = gql`
  query SearchMealComponents($searchString: String!) {
    searchMealComponents(searchString: $searchString) {
      id
      name
      sensitivityInformation {
        id
        sensitivityName
        present
      }
    }
  }
`;

// We could / should add this to sensitivities too.
const SearchForm = (props) => {
  const { label, disabled, onSearch, isLoading, onSearchReset, setSearchString, searchString } =
    props;

  React.useEffect(() => {
    const listener = (event) => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        event.preventDefault();
        onSearch({ searchString });
      }
    };
    if (!disabled) document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, [onSearch, searchString, disabled]);

  return (
    <Flexbox>
      <TextInput
        style={{ width: "500px" }}
        variant="outlined"
        disabled={isLoading || disabled}
        label={label}
        value={searchString}
        onChange={(e) => {
          if (e.target.value.length >= 50) return;
          setSearchString(e.target.value);
        }}
      />
      <Button
        style={{ marginLeft: "15px" }}
        disabled={isLoading || disabled}
        variant="outlined"
        color="primary"
        onClick={() => onSearch({ searchString })}
      >
        Search
      </Button>
      <Button
        style={{ marginLeft: "15px" }}
        disabled={isLoading || disabled}
        variant="outlined"
        color="primary"
        onClick={() => {
          setSearchString("");
          onSearchReset();
        }}
      >
        Reset Search
      </Button>
    </Flexbox>
  );
};

const MealComponentRow = (props) => {
  const { meal, openEditModal } = props;
  return (
    <TableRow>
      <TableCell>
        <Chip
          clickable={false}
          size="small"
          variant="outlined"
          label={meal.active ? "active" : "inactive"}
          color={meal.active ? "primary" : "secondary"}
        />
      </TableCell>
      <TableCell>
        <Link
          href="#"
          onClick={(event) => {
            event.preventDefault();
            openEditModal(meal);
          }}
        >
          {meal.name}
        </Link>
      </TableCell>
      <TableCell>
        {meal.sensitivityInformation
          .filter(({ present }) => present)
          .map(({ sensitivityName, id }) => {
            return (
              <Chip
                key={id}
                size="small"
                variant="outlined"
                label={sensitivityName}
                color="secondary"
              />
            );
          })}
      </TableCell>
    </TableRow>
  );
};

const OptimizedRow = React.memo(MealComponentRow, (prevProps, nextProps) => {
  // We need to return FALSE if we want to render, and TRUE if we don't want to re-render.
  return (
    prevProps.meal.isActive === nextProps.meal.isActive &&
    prevProps.meal.name === nextProps.meal.name &&
    isEqual(prevProps.meal.sensitivityInformation, nextProps.meal.sensitivityInformation)
  );
});

export const MealComponents = () => {
  const classes = useStyles();
  const [open, setCreateModal] = React.useState(false);
  const [editModalOpen, setEditModal] = React.useState(false);
  const [isSearching, setSearching] = React.useState(false);
  const [editingMealComponent, setMealToEdit] = React.useState("");
  const [searchString, setSearchString] = React.useState("");
  const openEditModal = (mealComponent) => {
    setMealToEdit(mealComponent);
    setEditModal(true);
  };

  const openCreateModal = () => setCreateModal(true);
  const closeCreateModal = () => setCreateModal(false);
  const closeEditModal = () => setEditModal(false);

  const refetchQueries = [
    { query: MEAL_COMPONENTS, variables: {} },
    // This means if we delete mid search we'll see it go from the searched list.
    { query: SEARCH_MEAL_COMPONENTS, variables: { searchString } },

    // I would love to be able to _not_ refetch all of this. I think it could involve
    // removing the cache in places so we'd have to see what works. EG if we go to menu,
    // come here, delete a MC, back to menu, we want to be sure the MC is not available.

    { query: SENSITIVITIES, variables: {} },
    { query: ACTIVE_MEAL_COMPONENTS, variables: { active: true } },
    { query: ACTIVE_SENSITIVITIES, variables: { active: true } },
    { query: ALL_ACTIVE_MEAL_COMPONENTS, variables: { active: true } },
    { query: ALL_ACTIVE_SENSITIVITIES, variables: { active: true } },
  ];
  return (
    <PageHeading maxWidth="lg" heading="Meal Components">
      <Typography variant="body1">
        Meal components are parts of a meal that can be combined into a menu item, like the rice
        in chicken and rice.
      </Typography>
      <Typography variant="body1">
        You can delete a meal component which will delete it completely (leaving orders unaffected
        however).
      </Typography>
      <Typography variant="body1">
        Use the search bar to search for a meal component by name. If you search with an empty
        search bar you will see no results.
      </Typography>
      <Typography variant="body1" className={classes.info}>
        You can reset the search to reveal all meal components again by pressing the &quot;Reset
        Search&quot; button.
      </Typography>
      <LazyGraphqlQuery withLoading={false} query={SEARCH_MEAL_COMPONENTS}>
        {(queryFun, { data: searchResults, error: searchError, loading: loadingSearch }) => {
          if (searchError) return <CentredErrorIcon />;

          return (
            <GraphqlQuery query={MEAL_COMPONENTS} variables={{}} withLoading={false} withError>
              {({ data, error, loading }) => {
                if (error) return <CentredErrorIcon />;

                const componentResponse =
                  isSearching && !loadingSearch
                    ? searchResults?.searchMealComponents
                    : data?.mealComponents;

                return (
                  <>
                    <SearchForm
                      disabled={open || editModalOpen}
                      setSearchString={setSearchString}
                      searchString={searchString}
                      isLoading={loadingSearch || loading}
                      onSearchReset={() => setSearching(false)}
                      label="Search for meal components by name"
                      onSearch={async (variables) => {
                        setSearching(true);
                        await queryFun({ variables });
                      }}
                    />

                    <TableContainer component={TableWrapper}>
                      <Table stickyHeader size="small">
                        <TableHead>
                          <TableRow>
                            <TableCell style={{ width: "56px" }}>Active?</TableCell>
                            <TableCell>Name</TableCell>
                            <TableCell>Contains</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {loading ? (
                            <TableRow>
                              <TableCell align="center" colSpan={3}>
                                <Loading />
                              </TableCell>
                            </TableRow>
                          ) : (
                            componentResponse?.map((meal) => {
                              return (
                                <OptimizedRow
                                  key={meal.id}
                                  meal={meal}
                                  openEditModal={openEditModal}
                                />
                              );
                            })
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <Button
                      disabled={loading}
                      variant="outlined"
                      color="primary"
                      aria-label="add"
                      onClick={openCreateModal}
                    >
                      Add Meal Component&nbsp;&nbsp;
                      <AddIcon />
                    </Button>
                  </>
                );
              }}
            </GraphqlQuery>
          );
        }}
      </LazyGraphqlQuery>
      <GraphqlQuery
        query={ALL_ACTIVE_SENSITIVITIES}
        variables={{ active: true }}
        withLoading={false}
        withError
      >
        {({ data }) => {
          return (
            <>
              {open && (
                <AddMealComponentModal
                  open={open}
                  onCancel={closeCreateModal}
                  onSubmit={closeCreateModal}
                  refetchQueries={refetchQueries}
                  sensitivities={data.sensitivities}
                />
              )}
              {editModalOpen && (
                <EditMealComponentModal
                  id={editingMealComponent.id}
                  open={editModalOpen}
                  onCancel={closeEditModal}
                  onSubmit={closeEditModal}
                  refetchQueries={refetchQueries}
                  sensitivities={data.sensitivities}
                />
              )}
            </>
          );
        }}
      </GraphqlQuery>
    </PageHeading>
  );
};
