import React from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { TextField, Tooltip } from "@material-ui/core";

export const ComponentSelect = (props) => {
  const {
    options,
    className,
    onChange,
    label,
    placeholder,
    variant,
    value,
    // We default these for the create menu where they aren't really relevant.
    updateBorders = (x) => x,
    componentBorders = { children: {}, components: {} },
  } = props;
  const [selected, select] = React.useState(value);
  const [toolTip, setTooltip] = React.useState("");

  React.useEffect(() => {
    const selectedOption = value ? options.find(({ name }) => name === value.name) : value;

    const toolTipLabel = () => {
      if (selectedOption) {
        const sensitivities = selectedOption.sensitivityInformation
          .map(({ sensitivityName }) => sensitivityName)
          .join(", ");
        if (sensitivities) {
          return `Contains these sensitivities:\n${sensitivities}`;
        } else {
          return "Contains no sensitivities!";
        }
      } else {
        return "";
      }
    };

    select(selectedOption);
    setTooltip(toolTipLabel);
  }, [select, value, options, setTooltip]);

  const selectTheThing = (_event, newValue) => {
    const selectedOption = options.find(({ name }) => name === newValue);
    updateBorders(selectedOption.name);
    select(selectedOption);
    onChange(selectedOption);
  };

  const borderBoxes =
    selected && selected.name
      ? (componentBorders.components[selected.name] || []).map((color, index) => {
          return `0 0 0 ${(index + 1) * 2}px ${color}`;
        })
      : [];
  return (
    <Tooltip enterDelay={400} leaveDelay={250} title={toolTip} placement="left-start">
      <Autocomplete
        blurOnSelect
        disableClearable
        filterSelectedOptions
        style={{ boxShadow: borderBoxes }}
        className={className}
        value={(selected && selected.name) || null}
        options={options.map(({ name }) => name)}
        size="small"
        getOptionLabel={(option) => option}
        onChange={selectTheThing}
        renderInput={(params) => {
          return (
            <TextField
              {...params}
              required
              label={label}
              variant={variant}
              placeholder={placeholder}
            />
          );
        }}
      />
    </Tooltip>
  );
};
