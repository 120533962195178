import { getOperationName } from "@apollo/client/utilities";
import React from "react";
import styled from "styled-components";
import moment from "moment";
import { H3 } from "components/typography/H3";
import { InfoNextToText } from "components/InfoNextToText";
import { useTheme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";
import { GraphqlQuery } from "graphqlUtils/GraphqlQuery";
import { GraphqlMutation } from "graphqlUtils/GraphqlMutation";
import { isEmpty, some, uniq, uniqBy } from "lodash";
import { NotResolvedAlert } from "containers/menus/NotResolvedAlert";
import { ChildrenNotServedTable } from "containers/menus/ChildrenNotServedTable";
import { TeaMenu } from "containers/menus/TeaMenu";
import { CentredErrorIcon } from "components/CentredErrorIcon";
import { LunchMenu } from "containers/menus/LunchMenu";
import { KeyboardDatePicker } from "@material-ui/pickers";
import {
  DialogTitle,
  DialogContent,
  DialogActions,
  Dialog,
  Tooltip,
  TextField,
  Typography,
  useMediaQuery,
  Button,
  Grid,
} from "@material-ui/core";
import {
  ACTIVE_MEAL_COMPONENTS,
  UPDATE_SUB_MENU,
  CHILDREN_NOT_SERVED_LUNCH_ON_MENU,
  CHILDREN_NOT_SERVED_TEA_ON_MENU,
  SUB_MENU,
} from "containers/menus/queries";
import { useGraphqlQuery } from "graphqlUtils/useGraphqlQuery";
import { Loading } from "components/Loading";
import { Alerts } from "components/alerts/Alerts";

const useStyles = makeStyles({
  inputStyles: { marginBottom: "15px", width: "100%", maxWidth: "400px" },
  dialog: { padding: "30px 0px 30px 0px" },
  headingGroup: { width: "100%", maxWidth: "750px", margin: "0px 0 15px 0" },
  heading: { marginBottom: "10px" },
  marginBottom: { marginBottom: "15px" },
  unresolvedCount: { textAlign: "center" },
  youGotServedTable: { minHeight: "38px", maxHeight: "150px", overflowY: "scroll" },
});

const Div = styled.div`
  margin-right: 30px;
  margin-top: 30px;
  & button {
    margin-right: 15px;
  }
`;

export const EditSubMenu = (props) => {
  const { subMenuId, open } = props;
  const { dialog } = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      fullScreen={fullScreen}
      fullWidth
      maxWidth="lg"
      open={open}
      PaperProps={{ className: dialog }}
    >
      <GraphqlQuery query={SUB_MENU} variables={{ id: subMenuId }} withError withLoading>
        {({ data, error }) => {
          if (error) {
            return <CentredErrorIcon />;
          }
          const { subMenu } = data;

          return (
            <EditSubMenuModal
              subMenu={subMenu}
              {...props}
              date={moment().startOf("isoWeek").format("YYYY-MM-DD")}
            />
          );
        }}
      </GraphqlQuery>
    </Dialog>
  );
};

// This is the modal if you want to provide your own subMenu.
// Above there is a component that will query for the subMenu for you.

export const EditSubMenuModal = (props) => {
  // If date is in props, then use that otherwise state.
  // Essentially in OrderSummary we only want to check against the week we are on.
  // And never want otherwise, so we disable the date picker for that screen. Bit gash but meh.
  const { onCancel, onSubmit, subMenu, chooseDate = true } = props;
  const [orderDate, setOrderDate] = React.useState(props.date);

  React.useEffect(() => {
    setOrderDate(props.date);
  }, [props.date, setOrderDate]);

  const lunchResults = useGraphqlQuery({
    query: CHILDREN_NOT_SERVED_LUNCH_ON_MENU,
    fetchPolicy: "network-only",
    variables: { date: orderDate, subMenuId: subMenu.id },
  });

  const teaResults = useGraphqlQuery({
    query: CHILDREN_NOT_SERVED_TEA_ON_MENU,
    fetchPolicy: "network-only",
    variables: { date: orderDate, subMenuId: subMenu.id },
  });

  const { loading: teaLoading, error: teaError, data: teaData } = teaResults;
  const { loading: lunchLoading, error: lunchError, data: lunchData } = lunchResults;

  if (teaLoading || lunchLoading) {
    return <Loading />;
  }

  if (teaError) {
    // We probably need to be able to close the modal in this case!
    return (
      <React.Fragment>
        <Alerts.Popup
          title="Error"
          message={teaError.message}
          doAlert={Boolean(teaError.message)}
        />
        <CentredErrorIcon />
      </React.Fragment>
    );
  }

  if (lunchError) {
    // We probably need to be able to close the modal in this case!
    return (
      <React.Fragment>
        <Alerts.Popup
          title="Error"
          message={lunchError.message}
          doAlert={Boolean(lunchError.message)}
        />
        <CentredErrorIcon />
      </React.Fragment>
    );
  }

  const { childrenNotServedForLunchForWeek: childrenNotServedLunch } = lunchData;
  const { childrenNotServedForTeaForWeek: childrenNotServedTea } = teaData;

  const filteredTea = subMenu.meals.filter(({ type }) => type === "TEA");
  const lunchMeals = subMenu.meals.filter(({ type }) => type === "LUNCH");

  return (
    <EditSubMenuModalActual
      onCancel={onCancel}
      onSubmit={onSubmit}
      lunchMeals={lunchMeals}
      teaMeals={filteredTea}
      childrenNotServedLunch={childrenNotServedLunch}
      childrenNotServedTea={childrenNotServedTea}
      date={orderDate}
      subMenu={subMenu}
      setOrderDate={setOrderDate}
      showDatePicker={chooseDate}
    />
  );
};

const EditSubMenuModalActual = (props) => {
  const {
    onCancel,
    onSubmit,
    subMenu,
    teaMeals,
    lunchMeals,
    childrenNotServedLunch,
    childrenNotServedTea,
    date,
    setOrderDate,
    showDatePicker,
  } = props;

  const [lunchMenu, setLunchMenuData] = React.useState({});
  const [teameals, setTeaMeals] = React.useState(teaMeals);
  const [lunchmeals, setLunchMeals] = React.useState(lunchMeals);
  const [name, setMenuName] = React.useState("");
  const [showAlert, setShowAlert] = React.useState(false);
  const [borders, setBorders] = React.useState({ children: {}, components: {} });
  const { inputStyles, headingGroup, heading, unresolvedCount, marginBottom, youGotServedTable } =
    useStyles();

  React.useEffect(() => {
    setTeaMeals(teaMeals);
    setLunchMeals(lunchMeals);
    setLunchMenuData(subMenu);
    setMenuName(subMenu.name);

    const defaultBorders = subMenu.uniqMealComponents.reduce((acc, { name }) => {
      return { ...acc, [name]: [] };
    }, {});

    setBorders({ children: {}, components: defaultBorders });
  }, [subMenu, teaMeals, lunchMeals, setTeaMeals, setLunchMeals, setMenuName, setLunchMenuData]);

  const lunchDaysToHighlight = uniq(
    childrenNotServedLunch.reduce(
      (acc, { datesNotServedLunch }) => [
        ...acc,
        ...datesNotServedLunch.map((date) => moment(date).format("dddd")),
      ],
      [],
    ),
  );

  const onChildHighlight = (childId, componentsToHighlight, colour) => {
    if (borders.children[childId]) {
      //  EXISTS - so turn off highlight
      const newComps = componentsToHighlight.reduce((acc, componentName) => {
        const newBorders = acc[componentName].filter((c) => c !== colour);
        return { ...acc, [componentName]: newBorders };
      }, borders.components);
      // eslint-disable-next-line
      const { [childId]: _deleted, ...newChildren } = borders.children;
      setBorders({ children: newChildren, components: newComps });
    } else {
      // DOES NOT EXIST - turn on highlight
      const newComps = componentsToHighlight.reduce((acc, componentName) => {
        return { ...acc, [componentName]: [...acc[componentName], colour] };
      }, borders.components);

      setBorders({ children: { ...borders.children, [childId]: true }, components: newComps });
    }
  };
  // When we add a new component we need to add it to the potential borders.
  const updateBorders = (newMealComponentName) => {
    setBorders({
      children: borders.children,
      components: { ...borders.components, [newMealComponentName]: [] },
    });
  };

  const teaDaysToHighlight = uniq(
    childrenNotServedTea.reduce(
      (acc, { datesNotServedTea }) => [
        ...acc,
        ...datesNotServedTea.map((date) => moment(date).format("dddd")),
      ],
      [],
    ),
  );

  const shouldHighlightLunch = (dayName) => {
    return some(lunchDaysToHighlight, (d) => d === dayName);
  };

  const shouldHighlightTea = (dayName) => {
    return some(teaDaysToHighlight, (d) => d === dayName);
  };

  const requiredFieldsEmpty =
    !lunchMenu.name ||
    some(lunchmeals, (meal) =>
      some(meal.componentsWithSubstitutions, (component) => {
        if (isEmpty(component.substitutions)) {
          return !component.mealComponent;
        } else {
          return (
            some(component.substitutions, (sub) => !sub.substitution) || !component.mealComponent
          );
        }
      }),
    ) ||
    some(teameals, (meal) =>
      some(meal.componentsWithoutSubstitutions, (component) => {
        return !component.mealComponent;
      }),
    );

  const allNotServed = uniqBy([...childrenNotServedTea, ...childrenNotServedLunch], "id");

  return (
    <React.Fragment>
      <DialogTitle disableTypography>
        <Typography variant="h4" component="h4" style={{ marginBottom: "30px" }}>
          Edit Sub Menu {name}
        </Typography>
        <Grid container>
          <Grid item xs={12} sm={6}>
            <TextField
              className={inputStyles}
              required
              variant="outlined"
              label="Enter sub menu name..."
              value={name}
              onChange={(event) => setMenuName(event.target.value)}
            />
            {showDatePicker && (
              <Tooltip
                title="Choose a date to check this menu against that week's orders. By default we check against the current week."
                placement="bottom-start"
              >
                {/* The span allows the tooltip, for some reason it doesn't like date pickers otherwise */}
                <span>
                  <KeyboardDatePicker
                    clearable
                    allowKeyboardControl
                    className={inputStyles}
                    variant="dialog"
                    label="Check against this week of orders"
                    format="MMMM Do YYYY"
                    value={date}
                    onChange={(date) => {
                      if (date) {
                        setOrderDate(date.startOf("isoWeek").format("YYYY-MM-DD"));
                      } else {
                        setOrderDate(moment().startOf("isoWeek").format("YYYY-MM-DD"));
                      }
                    }}
                  />
                </span>
              </Tooltip>
            )}
          </Grid>
          <Grid item xs={12} sm={6} className={unresolvedCount}>
            <Typography variant="h2">
              {allNotServed.length}
              <span>
                <Typography variant="button">&nbsp;&nbsp;unresolved children</Typography>
              </span>
            </Typography>
            <NotResolvedAlert
              open={showAlert}
              handleClose={() => setShowAlert(false)}
              childCount={allNotServed.length}
            />
          </Grid>
        </Grid>
      </DialogTitle>
      <div style={{ padding: "0px 24px 0px 24px" }}>
        <ChildrenNotServedTable
          date={date}
          highlightedChildren={borders.children}
          onChildHighlight={onChildHighlight}
          tableStyles={youGotServedTable}
          childrenNotServed={allNotServed}
          childrenNotServedTea={childrenNotServedTea}
          childrenNotServedLunch={childrenNotServedLunch}
        />
      </div>
      <DialogContent>
        <GraphqlQuery
          withLoading
          withError
          // Think this could sit higher and be shared between the edit / create. Though
          // what is the actual useage pattern? Will we often want to do both? Probably not

          query={ACTIVE_MEAL_COMPONENTS}
          fetchPolicy="network-only"
          variables={{ active: true }}
        >
          {({ data, error }) => {
            if (error) {
              return <CentredErrorIcon />;
            }

            const { mealComponents } = data;
            return (
              <Grid container>
                <Grid item xs={12} className={headingGroup}>
                  <H3 className={heading}>Lunch</H3>
                  <Typography variant="body2">
                    <InfoNextToText />
                    &nbsp;&nbsp;Meal components work in a waterfall manner. If a child cannot have
                    the meal component we will attempt to give them the substitute below it, and
                    so on until we find one that they can have. That means the order of the
                    substitutions is significant.
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <LunchMenu
                    updateBorders={updateBorders}
                    componentBorders={borders}
                    mondayHighlight={shouldHighlightLunch("Monday")}
                    tuesdayHighlight={shouldHighlightLunch("Tuesday")}
                    wednesdayHighlight={shouldHighlightLunch("Wednesday")}
                    thursdayHighlight={shouldHighlightLunch("Thursday")}
                    fridayHighlight={shouldHighlightLunch("Friday")}
                    options={mealComponents}
                    meals={lunchmeals}
                    onChange={(stuff) => setLunchMeals(stuff)}
                  />
                </Grid>
                <Grid item xs={12} className={marginBottom} />
                <Grid item xs={12} className={headingGroup}>
                  <H3 className={heading}>Tea</H3>
                  <Typography variant="body2">
                    <InfoNextToText />
                    &nbsp;&nbsp; The tea menu doesn&apos;t currently have substitutes, but you can
                    specify the teas available below.
                  </Typography>
                </Grid>
                <TeaMenu
                  updateBorders={updateBorders}
                  componentBorders={borders}
                  mondayHighlight={shouldHighlightTea("Monday")}
                  tuesdayHighlight={shouldHighlightTea("Tuesday")}
                  wednesdayHighlight={shouldHighlightTea("Wednesday")}
                  thursdayHighlight={shouldHighlightTea("Thursday")}
                  fridayHighlight={shouldHighlightTea("Friday")}
                  meals={teameals}
                  options={mealComponents}
                  onChange={(stuff) => setTeaMeals(stuff)}
                />
              </Grid>
            );
          }}
        </GraphqlQuery>
      </DialogContent>
      <DialogActions>
        <GraphqlMutation mutation={UPDATE_SUB_MENU} withError>
          {(updateSubMenu, { loading }) => {
            const submit = async () => {
              const teaMealsParams = teameals.map(
                ({ id, dayName, type, componentsWithoutSubstitutions }) => {
                  const components = componentsWithoutSubstitutions.map(
                    ({ id, mealComponent }, index) => {
                      return {
                        id,
                        columnNumber: index,
                        mealComponentId: mealComponent.id,
                        substitutions: [],
                      };
                    },
                  );
                  // Bit confusing but on read they are componentsWithoutSubstitutions but for speed
                  // of development the input remains componentsWithSubstitutions
                  return { id, dayName, type, componentsWithSubstitutions: components };
                },
              );

              const lunchMealsParams = lunchmeals.map(
                ({ id, dayName, type, componentsWithSubstitutions }) => {
                  const components = componentsWithSubstitutions.map(
                    ({ id, mealComponent, substitutions }, index) => {
                      const subs = substitutions.map(({ id, order, substitutionId }) => ({
                        id,
                        order,
                        substitutionId,
                      }));
                      return {
                        id,
                        columnNumber: index,
                        mealComponentId: mealComponent.id,
                        substitutions: subs,
                      };
                    },
                  );

                  return { id, dayName, type, componentsWithSubstitutions: components };
                },
              );

              const input = {
                menuId: subMenu.menuId,
                id: lunchMenu.id,
                order: subMenu.order,
                name,
                meals: [...lunchMealsParams, ...teaMealsParams],
              };
              const variables = { input };
              const result = await updateSubMenu({
                variables,
                awaitRefetchQueries: true,
                refetchQueries: [
                  // We only have to refetch the queries for the week, as the ones for the
                  // day wont change unless we generate diet advice.
                  getOperationName(CHILDREN_NOT_SERVED_LUNCH_ON_MENU),
                  getOperationName(CHILDREN_NOT_SERVED_TEA_ON_MENU),
                ],
              });
              if (result.data) {
                setShowAlert(true);
              }
            };

            return (
              <Div>
                <Button disabled={loading} variant="outlined" onClick={onCancel} color="primary">
                  Cancel
                </Button>

                {loading ? (
                  <Loading />
                ) : (
                  <Button
                    disabled={requiredFieldsEmpty || loading}
                    variant="outlined"
                    onClick={submit}
                    color="secondary"
                  >
                    Save And Check Unresolved
                  </Button>
                )}
                <Button disabled={loading} variant="outlined" color="primary" onClick={onSubmit}>
                  Finish
                </Button>
              </Div>
            );
          }}
        </GraphqlMutation>
      </DialogActions>
    </React.Fragment>
  );
};
