import { useQuery } from "@apollo/client";

/**
A custom hook to easily make queries to the BE over graphql. This has been extracted in case Apollo decides
to change their whole API again, and if we need to put any shit in there like error handling / auth
stuff so all graphql queries get the benefits.

It's a hook because why have all that nesting aye?

### Examples

  useGraphqlQuery({query: MY_QUERY, variables: {id: "123" }})
**/

export function useGraphqlQuery(args) {
  const { query, variables, fetchPolicy } = args;
  const result = useQuery(query, { variables, fetchPolicy });
  return result;
}
