// these sizes are arbitrary and you can set them to whatever you wish
import { css } from "styled-components/macro";

// The default Material UI Breakpoints are:
// sm: 600px
// md: 960px
// lg: 1280px
// xl: 1920px
export const breakpoints = {
  xs: 480,
  sm: 600,
  md: 960,
  lg: 1280,
  xl: 1920,
};

// iterate through the sizes and create a media template
// use it like this:

//   const H1 = styled.h1`
//    // Styles here apply to any size and up.
//       margin: 20px 0;
//     ${media.tablet`
//    // Styles here apply to tablet and up.
//       margin: 40px 0;
//     `}
//     ${media.desktop`
//    // Styles here apply to desktop and up.
//       margin: 80px 0;
//     `}
//   `

export const media = Object.keys(breakpoints).reduce((accumulator, label) => {
  /*
   * use em in breakpoints to work properly cross-browser and support users
   * changing their browsers font-size: https://zellwk.com/blog/media-query-units/
   */
  const emSize = breakpoints[label] / 16;

  const rule = (...args) => css`
    @media (min-width: ${emSize}em) {
      ${css(...args)};
    }
  `;
  return {
    ...accumulator,
    [label]: rule,
  };
}, {});
