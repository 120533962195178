import React from "react";
import { useTheme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";
import { GraphqlQuery } from "graphqlUtils/GraphqlQuery";
import { CentredErrorIcon } from "components/CentredErrorIcon";
import { EditSubMenuModal } from "containers/menus/EditSubMenu";
import { Dialog, useMediaQuery } from "@material-ui/core";
import { CURRENT_MENU } from "containers/menus/queries";

const useStyles = makeStyles({
  dialog: { padding: "30px 0px 30px 0px" },
});

export const ResolveUnresolvedModal = (props) => {
  const { date, open } = props;
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { dialog } = useStyles();

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      fullScreen={fullScreen}
      fullWidth
      maxWidth="lg"
      open={open}
      PaperProps={{ className: dialog }}
    >
      <GraphqlQuery
        fetchPolicy="network-only"
        query={CURRENT_MENU}
        variables={{ date }}
        withError
      >
        {({ data, error }) => {
          if (error) {
            return <CentredErrorIcon />;
          }

          const { currentSubMenu: subMenu } = data;
          return <EditSubMenuModal subMenu={subMenu} {...props} date={date} chooseDate={false} />;
        }}
      </GraphqlQuery>
    </Dialog>
  );
};
