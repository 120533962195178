import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { GraphqlMutation } from "graphqlUtils/GraphqlMutation";
import { DragAndDropTable } from "components/DragAndDropTable";
import OpenWithIcon from "@material-ui/icons/OpenWith";
import AddIcon from "@material-ui/icons/Add";
import { AddSubMenu } from "containers/menus/AddSubMenu";
import { EditSubMenu } from "containers/menus/EditSubMenu";
import { Icons } from "icons/Icons";
import {
  Link,
  Table,
  Typography,
  TableCell,
  TableBody,
  IconButton,
  TableRow,
  Button,
} from "@material-ui/core";
import { ORDER_SUB_MENUS, SUB_MENUS, DELETE_SUB_MENU } from "containers/menus/queries";
import pink from "@material-ui/core/colors/pink";

const useStyles = makeStyles({
  moveIcon: {
    height: "15px",
    position: "relative",
    top: "4px",
    cursor: "move",
  },
  icon: { height: "20px", position: "relative" },
  space: { marginBottom: "15px" },
  table: { marginTop: "15px", marginBottom: "20px" },
  btn: { padding: "1px" },
});

export const SubMenus = (props) => {
  const { subMenus, refetchQueries, menuId } = props;
  const { moveIcon, space, icon, btn } = useStyles();
  const [modalOpen, setModalOpen] = React.useState(false);
  const [editModalOpen, setEditModalOpen] = React.useState(false);
  const [editingSubMenuId, setSubMenuToEdit] = React.useState();

  const openEditModal = (subMenuId) => {
    setSubMenuToEdit(subMenuId);
    setEditModalOpen(true);
  };

  return (
    <React.Fragment>
      <Table size="small" className={space}>
        <GraphqlMutation mutation={ORDER_SUB_MENUS}>
          {(setOrder) => {
            const saveOrder = async (newSubMenus) => {
              const variables = {
                input: {
                  id: menuId,
                  subMenus: newSubMenus.map(({ id }) => ({ id })),
                },
              };
              await setOrder({ variables, refetchQueries });
            };
            return (
              <DragAndDropTable
                cardType={`subMenu-${menuId}`}
                data={subMenus}
                dropComponent={TableBody}
                saveOrder={saveOrder}
              >
                {({ datum: subMenu, isDragging, dragRef }) => {
                  const { name: subMenuName, currentlyActive } = subMenu;
                  const opacity = isDragging ? 0 : 1;

                  return (
                    <TableRow
                      hover
                      style={{ border: currentlyActive ? `${pink[200]} dashed 2px` : "none" }}
                    >
                      <TableCell ref={dragRef} style={{ cursor: "move", opacity, width: "54px" }}>
                        <OpenWithIcon className={moveIcon} />
                      </TableCell>
                      <TableCell>
                        <Typography>
                          <Link
                            href="#"
                            onClick={(e) => {
                              e.preventDefault();
                              openEditModal(subMenu.id);
                            }}
                          >
                            {subMenuName}
                          </Link>
                        </Typography>
                      </TableCell>
                      <TableCell align="left">
                        <GraphqlMutation mutation={DELETE_SUB_MENU}>
                          {(mutation) => {
                            return (
                              <IconButton
                                className={btn}
                                aria-label="delete"
                                onClick={() => {
                                  const confirm = window.confirm(
                                    `This will delete the sub menu ${subMenu.name}, are you sure?`,
                                  );
                                  if (confirm) {
                                    mutation({ variables: { id: subMenu.id }, refetchQueries });
                                  }
                                }}
                              >
                                <Icons.DeleteIcon className={icon} />
                              </IconButton>
                            );
                          }}
                        </GraphqlMutation>
                      </TableCell>
                    </TableRow>
                  );
                }}
              </DragAndDropTable>
            );
          }}
        </GraphqlMutation>
      </Table>
      <Button
        variant="outlined"
        color="primary"
        aria-label="add"
        onClick={() => setModalOpen(true)}
      >
        Create Sub Menu&nbsp;&nbsp;
        <AddIcon />
      </Button>
      {modalOpen && (
        <AddSubMenu
          refetchQueries={[{ query: SUB_MENUS, variables: { id: menuId } }]}
          open={modalOpen}
          onSubmit={(subMenuId) => {
            setModalOpen(false);
            openEditModal(subMenuId);
          }}
          onCancel={() => setModalOpen(false)}
          menuId={menuId}
        />
      )}
      {editModalOpen && (
        <EditSubMenu
          open={editModalOpen}
          onSubmit={() => setEditModalOpen(false)}
          onCancel={() => setEditModalOpen(false)}
          menuId={menuId}
          subMenuId={editingSubMenuId}
        />
      )}
    </React.Fragment>
  );
};
