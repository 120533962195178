import React from "react";
import { gql } from "@apollo/client";
import styled from "styled-components";
import { PageHeading } from "components/PageHeading";
import { WarningNextToText } from "components/WarningNextToText";
import { GraphqlQuery } from "graphqlUtils/GraphqlQuery";
import { CentredErrorIcon } from "components/CentredErrorIcon";
import AddIcon from "@material-ui/icons/Add";
import { NumberInput } from "components/inputs/Inputs";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Icons } from "icons/Icons";
import { sortBy, find } from "lodash";
import {
  Table,
  TableContainer,
  Typography,
  TableCell,
  TableRow,
  TableBody,
  IconButton,
  TextField,
  TableHead,
  Button,
  Select,
  FormControl,
  InputLabel,
} from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";
import { GraphqlMutation } from "graphqlUtils/GraphqlMutation";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  useMediaQuery,
} from "@material-ui/core";

const PORTIONS = gql`
  query PortionedMealComponents {
    portionedMealComponents {
      id
      name
      portions {
        id
        tinId
        tinType
        tinName
        portionSize
        portionUnit
        count
        mealComponentId
      }
    }
  }
`;

const TINS = gql`
  query Tins {
    tins {
      id
      name
    }
  }
`;

const NON_PORTIONED_MEAL_COMPONENTS = gql`
  query NonPortionedMealComponents {
    nonPortionedMealComponents {
      id
      name
    }
  }
`;

const DELETE_PORTIONS = gql`
  mutation DeletePortion($mealComponentId: ID!) {
    deletePortion(mealComponentId: $mealComponentId) {
      id
    }
  }
`;

const UPDATE_PORTION_COMPONENT = gql`
  mutation UpdatePortion($input: UpdatePortionInput!) {
    updatePortion(input: $input) {
      id
      count
      tinId
      tinName
      mealComponentId
    }
  }
`;

const UPDATE_TIN_TYPE = gql`
  mutation UpdateTinType($input: [UpdateTinTypeInput!]!) {
    updateTinType(input: $input) {
      id
      count
      tinId
      mealComponentId
      tinType
    }
  }
`;

const PORTION_COMPONENT = gql`
  mutation PortionMealComponent($input: [PortionMealComponentInput!]!) {
    portionMealComponent(input: $input) {
      id
      count
      tinId
      tinName
      mealComponentId
    }
  }
`;

const PORTION_UNITS = gql`
  query PortionUnits {
    portionUnits {
      id
      name
    }
  }
`;

const UPDATE_PORTION_SIZE = gql`
  mutation UpdateMealComponentPortion($input: PortionSizeInput!) {
    updateMealComponentPortion(input: $input) {
      id
    }
  }
`;

const TableWrapper = styled.div`
  background-color: white;
  margin-bottom: 15px;
  max-height: 66vh;
`;

const useStyles = makeStyles({
  dialogContent: { display: "flex", flexDirection: "column" },
  inputStyle: { width: "100%", maxWidth: "350px", marginBottom: "15px" },
  heading: { marginLeft: "30px", marginBottom: "15px" },
});

const TheSelect = (props) => {
  const [selected, selectComponent] = React.useState(props.value);
  const { onChange, style = {}, autoFocus = false, label = "Select the meal component" } = props;

  React.useEffect(() => {
    selectComponent(props.value);
  }, [selectComponent, props.value]);

  return (
    <Autocomplete
      style={style}
      disableClearable
      blurOnSelect
      filterSelectedOptions
      value={selected}
      options={props.options}
      size="small"
      ChipProps={{ style: { display: "none" } }}
      getOptionSelected={(option, value) => {
        if ({}.hasOwnProperty.call(option, "id")) {
          return option.id === value.id;
        } else {
          return option === value;
        }
      }}
      getOptionLabel={(option) => {
        if ({}.hasOwnProperty.call(option, "name")) {
          return option.name;
        } else {
          return option;
        }
      }}
      onChange={(_event, values) => {
        onChange(values);
      }}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            required
            autoFocus={autoFocus}
            label={label}
            variant="outlined"
            placeholder="Type to filter the list"
          />
        );
      }}
    />
  );
};

const PortionMeal = (props) => {
  const { open, onCancel, refetchQueries, onSubmit, tins, mealComponents } = props;
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { dialogContent } = useStyles();
  const [formState, setFormState] = React.useState({ tins: {} });

  const resetForm = () => {
    setFormState({ tins: {} });
  };

  const missingRequiredFields =
    !formState.mealComponentId ||
    Object.values(formState.tins).filter(({ count }) => count).length !== tins.length;
  return (
    <Dialog
      scroll="paper"
      disableBackdropClick
      disableEscapeKeyDown
      fullScreen={fullScreen}
      fullWidth
      open={open}
    >
      <DialogTitle disableTypography>
        <Typography style={{ marginBottom: "15px" }} variant="h4" component="h4">
          Portion A Meal Component
        </Typography>
        <Typography variant="body2" component="p">
          Choose a meal component from the dropdown and specify how many portions can fit in each
          of the tins below. Ensure you specify the portion sizes for all tins for the best
          results.
        </Typography>
      </DialogTitle>
      <DialogContent className={dialogContent}>
        <TheSelect
          autoFocus
          style={{ marginBottom: "15px" }}
          options={mealComponents}
          onChange={(mealComponent) => {
            setFormState({ ...formState, mealComponentId: mealComponent.id });
          }}
        />
        {tins.map((tin) => {
          const { id: tinId } = tin;
          const onBlur = (count) => {
            setFormState({
              ...formState,
              tins: { ...formState.tins, [tinId]: { count, tinId } },
            });
          };
          return (
            <NumberInput
              required
              style={{ marginBottom: "10px" }}
              key={tinId}
              value={formState.tins.tinId}
              label={`${tin.name} Portions`}
              onBlur={onBlur}
            />
          );
        })}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            resetForm();
            onCancel();
          }}
          color="primary"
        >
          Cancel
        </Button>
        <GraphqlMutation mutation={PORTION_COMPONENT} withError>
          {(portionComponent) => {
            const submit = async () => {
              const input = Object.values(formState.tins).map(({ count, tinId }) => {
                return { count, tinId, mealComponentId: formState.mealComponentId };
              });
              const result = await portionComponent({ variables: { input }, refetchQueries });
              if (result.data) {
                resetForm();
                onSubmit();
              }
            };

            return (
              <Button disabled={missingRequiredFields} onClick={submit} color="primary">
                Create
              </Button>
            );
          }}
        </GraphqlMutation>
      </DialogActions>
    </Dialog>
  );
};

const SlowPortionRow = (props) => {
  const { tins, portioned, refetchQueries, portionUnits } = props;
  // Right now they should all be the same so we can just get the first one.
  const firstPortion = portioned.portions[0];

  return (
    <React.Fragment>
      <TableCell style={{ width: "150px" }}>
        <GraphqlMutation mutation={UPDATE_PORTION_SIZE}>
          {(mutation, { loading }) => {
            const onBlur = (portionSize) => {
              const variables = { input: { mealComponentId: portioned.id, portionSize } };

              mutation({
                variables,
                refetchQueries,
                optimisticResponse: {
                  __typename: "Mutation",
                  updateMealComponentPortion: {
                    ...portioned,
                    portionSize,
                  },
                },
              });
            };
            return (
              <NumberInput
                disabled={loading}
                label="Portion Size"
                error={firstPortion.portionSize !== 0 && !firstPortion.portionSize}
                color={firstPortion.portionSize ? "primary" : "secondary"}
                value={firstPortion.portionSize}
                onBlur={onBlur}
              />
            );
          }}
        </GraphqlMutation>
      </TableCell>
      <TableCell style={{ width: "200px" }}>
        <GraphqlMutation mutation={UPDATE_PORTION_SIZE}>
          {(update, { loading }) => {
            const onBlur = (portionUnit) => {
              if (portionUnit === null || portionUnit === "" || portionUnit === undefined) {
                return;
              }
              const variables = {
                input: {
                  mealComponentId: portioned.id,
                  portionUnit: portionUnit.name,
                },
              };
              update({
                variables,
                refetchQueries,
                optimisticResponse: {
                  __typename: "Mutation",
                  updateMealComponentPortion: {
                    ...portioned,
                    portionUnit: portionUnit.name,
                  },
                },
              });
            };
            return (
              <TheSelect
                disabled={loading}
                options={portionUnits}
                value={firstPortion.portionUnit}
                onChange={onBlur}
                label="Unit"
              />
            );
          }}
        </GraphqlMutation>
      </TableCell>
      <TableCell style={{ width: "160px" }}>
        <GraphqlMutation mutation={UPDATE_TIN_TYPE} withError withLoading>
          {(update, { loading }) => {
            const onChange = (e) => {
              const input = portioned.portions.map((p) => {
                return { tinType: e.target.value, id: p.id };
              });

              update({
                variables: { input },
                refetchQueries,
              });
            };
            return (
              <FormControl>
                <InputLabel required>Tin Type</InputLabel>
                {/* We get the first as it is assumed that the tin type is the same for */}
                {/* all tins - because it should be. */}
                <Select
                  disabled={loading}
                  native
                  value={firstPortion.tinType || ""}
                  onChange={onChange}
                >
                  <option value="round_up">Round Up</option>
                  <option value="exact">Exact</option>
                </Select>
              </FormControl>
            );
          }}
        </GraphqlMutation>
      </TableCell>
      {tins.map(({ id }) => {
        const portion = find(portioned.portions, (portion) => portion.tinId === id);
        if (portion) {
          return (
            <TableCell key={id} style={{ width: "150px" }}>
              <GraphqlMutation mutation={UPDATE_PORTION_COMPONENT}>
                {(portionComponent, { loading }) => {
                  const onBlur = async (updatedCount) => {
                    const variables = {
                      input: {
                        id: portion.id,
                        count: updatedCount,
                      },
                    };
                    await portionComponent({
                      variables,
                      refetchQueries,
                      // These mean it rollsback in case of
                      // failure. Pretty hacky but meh!
                      optimisticResponse: {
                        __typename: "Mutation",
                        updatePortion: {
                          ...portion,
                          count: updatedCount,
                        },
                      },
                    });
                  };
                  return (
                    <NumberInput
                      disabled={loading}
                      label="Portion Count"
                      value={portion.count}
                      onBlur={onBlur}
                    />
                  );
                }}
              </GraphqlMutation>
            </TableCell>
          );
        } else {
          return (
            <TableCell key={id} style={{ width: "150px" }}>
              <GraphqlMutation mutation={PORTION_COMPONENT}>
                {(portionComponent, { loading }) => {
                  const onBlur = async (updatedCount) => {
                    if (
                      updatedCount === null ||
                      updatedCount === "" ||
                      updatedCount === undefined
                    ) {
                      return;
                    }
                    const variables = {
                      input: {
                        tinId: id,
                        mealComponentId: portioned.id,
                        count: updatedCount,
                      },
                    };
                    await portionComponent({
                      variables,
                      refetchQueries,
                    });
                  };
                  return (
                    <NumberInput
                      error
                      color="secondary"
                      disabled={loading}
                      label="Portion Count"
                      onBlur={onBlur}
                    />
                  );
                }}
              </GraphqlMutation>
            </TableCell>
          );
        }
      })}
    </React.Fragment>
  );
};

const PortionRow = React.memo(SlowPortionRow, (prevProps, nextProps) => {
  // We need to return FALSE if we want to render, and TRUE if we don't want to re-render.
  return (
    prevProps.tins === nextProps.tins &&
    prevProps.portioned === nextProps.portioned &&
    prevProps.portionUnits === nextProps.portionUnits
  );
});

export const PortionSizes = () => {
  const [open, setModalOpen] = React.useState(false);
  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);
  const refetchQueries = [{ query: NON_PORTIONED_MEAL_COMPONENTS }, { query: PORTIONS }];

  return (
    <GraphqlQuery query={PORTION_UNITS}>
      {({ data: unitData, error: unitError }) => {
        if (unitError) return <CentredErrorIcon />;
        const { portionUnits } = unitData;
        return (
          <GraphqlQuery query={NON_PORTIONED_MEAL_COMPONENTS}>
            {({ data, error }) => {
              if (error) return <CentredErrorIcon />;
              const { nonPortionedMealComponents: mealComponents } = data;
              return (
                <GraphqlQuery query={TINS}>
                  {({ data: tinData, error: tinError }) => {
                    if (tinError) return <CentredErrorIcon />;
                    const tins = sortBy(tinData.tins, (t) => t.name);
                    return (
                      <PageHeading maxWidth="lg" heading="Tin Portion Sizes">
                        <Typography variant="body1" style={{ marginBottom: "10px" }}>
                          Here we can specify both the portion size for a meal component and how
                          many portions of a meal component fit into each of the standard tins. On
                          the day of delivery this information is used to determine how many
                          standard tins a nursery may expect.
                        </Typography>
                        <Typography
                          variant="body1"
                          component="p"
                          style={{ marginBottom: "20px" }}
                        >
                          <WarningNextToText /> Only specify portion count for meal components
                          that are served in a standard tin! You may however provide a portion
                          size for any component.
                        </Typography>
                        <Typography variant="body1" style={{ marginBottom: "30px" }}>
                          Only components that have a portion count specified for at least one tin
                          will appear in the Nursery&apos;s Portion Information. To help show
                          which components need a portion count click below to see a list of all
                          components that currently appear in a regular meal, but which have not
                          yet been portioned.
                        </Typography>
                        <GraphqlQuery query={PORTIONS} variables={{}} withLoading withError>
                          {({ data: portionData, error: portionError }) => {
                            if (portionError) return <CentredErrorIcon />;

                            const { portionedMealComponents } = portionData;

                            return (
                              <TableContainer component={TableWrapper}>
                                <Table stickyHeader size="small">
                                  <TableHead>
                                    <TableRow>
                                      <TableCell>Meal Component</TableCell>
                                      <TableCell>Portion Size</TableCell>
                                      <TableCell>Portion Unit</TableCell>
                                      <TableCell>Tin Type</TableCell>
                                      {tins.map(({ name, id }) => {
                                        return <TableCell key={id}>{name}</TableCell>;
                                      })}
                                      <TableCell></TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {portionedMealComponents.map((portioned) => {
                                      return (
                                        <TableRow key={portioned.id}>
                                          <TableCell>
                                            <Typography variant="body2">
                                              {portioned.name}
                                            </Typography>
                                          </TableCell>
                                          <PortionRow
                                            portionUnits={portionUnits}
                                            tins={tins}
                                            portioned={portioned}
                                            refetchQueries={refetchQueries}
                                          />
                                          <TableCell style={{ width: "50px" }}>
                                            <GraphqlMutation
                                              mutation={DELETE_PORTIONS}
                                              withError
                                              withLoading
                                            >
                                              {(mutation) => {
                                                return (
                                                  <IconButton
                                                    aria-label="delete"
                                                    onClick={async () => {
                                                      await mutation({
                                                        variables: {
                                                          mealComponentId: portioned.id,
                                                        },
                                                        refetchQueries,
                                                      });
                                                    }}
                                                  >
                                                    <Icons.DeleteIcon />
                                                  </IconButton>
                                                );
                                              }}
                                            </GraphqlMutation>
                                          </TableCell>
                                        </TableRow>
                                      );
                                    })}
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            );
                          }}
                        </GraphqlQuery>
                        <Button
                          variant="outlined"
                          color="primary"
                          aria-label="add"
                          onClick={openModal}
                        >
                          Portion a Meal Component&nbsp;&nbsp;
                          <AddIcon />
                        </Button>
                        <PortionMeal
                          tins={tinData.tins}
                          open={open}
                          onCancel={closeModal}
                          onSubmit={closeModal}
                          mealComponents={mealComponents}
                          refetchQueries={refetchQueries}
                        />
                      </PageHeading>
                    );
                  }}
                </GraphqlQuery>
              );
            }}
          </GraphqlQuery>
        );
      }}
    </GraphqlQuery>
  );
};
