import { gql } from "@apollo/client";
import React from "react";
import { useTheme } from "@material-ui/core/styles";
import { TextInput } from "components/inputs/Inputs";
import { makeStyles } from "@material-ui/core/styles";
import { GraphqlMutation } from "graphqlUtils/GraphqlMutation";
import {
  Dialog,
  DialogTitle,
  Button,
  DialogContent,
  DialogActions,
  Typography,
  useMediaQuery,
} from "@material-ui/core";

const CREATE_INVOICE_GROUP = gql`
  mutation CreateInvoiceGroup($input: CreateInvoiceGroupInput!) {
    createInvoiceGroup(input: $input) {
      id
      name
      nurseries {
        id
        name
      }
    }
  }
`;

const useStyles = makeStyles({
  inputStyle: { width: "100%", maxWidth: "350px", marginBottom: "15px" },
  text: { marginBottom: "30px" },
});

export const CreateInvoiceGroupModal = (props) => {
  const { open, onCancel, refetchQueries, onSubmit } = props;
  const theme = useTheme();
  const { inputStyle, text } = useStyles();
  const [name, updateName] = React.useState("");
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Dialog
      scroll="paper"
      disableBackdropClick
      disableEscapeKeyDown
      fullScreen={fullScreen}
      fullWidth
      open={open}
    >
      <DialogTitle disableTypography>
        <Typography variant="h4" component="h4">
          Create Invoice Group
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Typography className={text} variant="body1" component="p">
          The name of the invoice group should exactly match the name of the nursery in Xero, i.e.
          the name of the nursery that would appear on the invoice.
        </Typography>
        <TextInput
          required
          variant="outlined"
          className={inputStyle}
          label="Name..."
          value={name}
          onChange={(event) => updateName(event.target.value)}
        />
        <DialogActions>
          <Button onClick={() => onCancel()} color="primary">
            Cancel
          </Button>
          <GraphqlMutation mutation={CREATE_INVOICE_GROUP} withError>
            {(createIg, { loading }) => {
              const variables = {
                input: { name },
              };

              const submit = async () => {
                const result = await createIg({ variables, refetchQueries });
                if (result.data) {
                  onSubmit();
                }
                return result;
              };

              return (
                <Button disabled={loading || name === ""} onClick={submit} color="primary">
                  Save
                </Button>
              );
            }}
          </GraphqlMutation>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};
