import { isPlainObject, isArray } from "lodash";

export const GraphqlUtils = {
  transformGraphqlError: (error) => {
    if (error && error.message) {
      const cut = error.message.split(": ");
      cut.shift();
      return cut.join(": ");
    }
  },
};

export const filterTypename = (data) => {
  if (isPlainObject(data)) {
    return Object.entries(data).reduce((acc, [key, value]) => {
      if (key === "__typename") {
        return acc;
      } else {
        if (isPlainObject(value)) {
          return { ...acc, [key]: filterTypename(value) };
        } else if (isArray(value)) {
          return { ...acc, [key]: value.map(filterTypename) };
        } else {
          return { ...acc, [key]: value };
        }
      }
    }, {});
  } else if (isArray(data)) {
    return data.map(filterTypename);
  }
};
