import { gql } from "@apollo/client";
import React from "react";
import moment from "moment";
import { PageHeading } from "components/PageHeading";
import { StartEndDateSelector } from "components/StartEndDateSelector";
import { H3 } from "components/typography/H3";
import { CentredErrorIcon } from "components/CentredErrorIcon";
import { Icons } from "icons/Icons";
import {
  Typography,
  Grid,
  Chip,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import { GraphqlMutation } from "graphqlUtils/GraphqlMutation";
import { GraphqlQuery } from "graphqlUtils/GraphqlQuery";
import { groupBy } from "lodash";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import { KeyboardDatePicker, KeyboardTimePicker } from "@material-ui/pickers";
import { NumberInput } from "components/inputs/Inputs";
import { makeStyles } from "@material-ui/core/styles";

const CLOSE_HM = gql`
  mutation SpecifyClosure($startDate: Date!, $endDate: Date!) {
    specifyHmClosures(startDate: $startDate, endDate: $endDate) {
      id
      date
      weekId
    }
  }
`;

const REOPEN = gql`
  mutation RemoveClosure($date: Date!) {
    removeHmClosure(date: $date) {
      id
      date
      weekId
    }
  }
`;

const CLOSURES = gql`
  query HMClosures {
    hmClosures {
      id
      date
      weekId
    }
  }
`;

const DEFAULT = gql`
  query Default {
    defaultOrderDeadline {
      id
      dayOffset
      deadlineTime
    }
  }
`;
const SET_DEFAULT_DEADLINE = gql`
  mutation SetDefaultOrderDeadline($input: SetDefaultOrderDeadlineInput!) {
    setDefaultOrderDeadline(input: $input) {
      id
      dayOffset
      deadlineTime
    }
  }
`;

const SET_ORDER_DEADLINE = gql`
  mutation SetOrderDeadline($input: SetOrderDeadlineInput!) {
    setOrderDeadline(input: $input) {
      id
      deadlineDate
      deadlineTime
    }
  }
`;

const CONFIGURED_WEEKS = gql`
  query AllDeadlines {
    allConfiguredWeeks {
      id
      startDate
      deadline {
        id
        deadlineDate
        deadlineTime
      }
    }
  }
`;

const DELETE_DEADLINE = gql`
  mutation DeleteOrderDeadline($id: ID!) {
    deleteOrderDeadline(id: $id) {
      id
      deadlineDate
      deadlineTime
    }
  }
`;

const useStyles = makeStyles({
  timePickerStyles: { marginLeft: "25px", width: "150px" },
  dayOffsetStyles: { marginLeft: "25px", width: "90px" },
  weekBegStyles: { width: "200px" },
  btnStyles: { marginRight: "15px", marginLeft: "10px" },
  deleteBtn: { paddingTop: "0px", paddingBottom: "0px" },
  icon: { height: "20px", position: "relative" },
});

const ClosureDates = (props) => {
  const { dates } = props;
  // This means we have to sort it later by meh.
  const groupedDates = groupBy(dates, ({ date }) => moment(date).format("MMMM YYYY"));

  return Object.entries(groupedDates)
    .sort((left, right) => {
      // We can compare any date in each as we care about comparing the month and year only
      // Just so vile. Fuck you javascript with your fucking shit sorting capabilities.
      return moment(left[1][0].date).isSameOrBefore(moment(right[1][0].date)) ? 1 : -1;
    })
    .map(([groupName, datesClosed]) => {
      return (
        <Grid style={{ marginBottom: "15px" }} item xs={12} key={groupName}>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandLessIcon />}>
              <Typography variant="button">{groupName}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid item>
                {datesClosed
                  .sort((left, right) => {
                    // Just so vile. Fuck you javascript with your fucking shit sorting capabilities.
                    return moment(left.date).isAfter(moment(right.date), "day") ? -1 : 1;
                  })
                  .map(({ date, id }) => {
                    return (
                      <GraphqlMutation key={id} mutation={REOPEN}>
                        {(deleteThing, { loading }) => {
                          const variables = { date };
                          const onDelete = async () => {
                            await deleteThing({
                              variables,
                              refetchQueries: [{ query: CLOSURES, variables: {} }],
                            });
                          };

                          return (
                            <Chip
                              disabled={loading}
                              clickable={!loading}
                              style={{
                                marginRight: "15px",
                                marginBottom: "15px",
                                backgroundColor: "white",
                              }}
                              variant="outlined"
                              color="primary"
                              label={moment(date).format("MMMM Do YYYY")}
                              onDelete={onDelete}
                            />
                          );
                        }}
                      </GraphqlMutation>
                    );
                  })}
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>
      );
    });
};

const DefaultOrderDeadline = (props) => {
  const [dayOffset, setDayOffset] = React.useState();
  const [deadlineTime, setDeadlineTime] = React.useState();
  const { dayOffsetStyles, timePickerStyles } = useStyles();
  const dayOffsetError = dayOffset && dayOffset <= 0;
  const showButton = props.dayOffset !== dayOffset || props.deadlineTime !== deadlineTime;

  React.useEffect(() => {
    setDeadlineTime(props.deadlineTime);
    setDayOffset(props.dayOffset);
  }, [props.dayOffset, props.deadlineTime, setDayOffset, setDeadlineTime]);

  const onCancel = () => {
    setDeadlineTime(props.deadlineTime);
    setDayOffset(props.dayOffset);
  };

  return (
    <GraphqlMutation mutation={SET_DEFAULT_DEADLINE}>
      {(setDefault, { loading }) => {
        const onClick = async () => {
          const time = deadlineTime.format("HH:mm:ss");
          await setDefault({
            variables: { input: { dayOffset, deadlineTime: time } },
            refetchQueries: [{ query: DEFAULT, variables: {} }],
          });
        };
        const tooltip =
          "Changing the deadline time requires changing when the auto-submit job runs which is a manual change for now. Please reach out to app support to do this.";
        return (
          <React.Fragment>
            <H3 style={{ marginBottom: "15px" }}>Default Deadline</H3>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginBottom: "30px",
              }}
            >
              <NumberInput
                disabled={loading}
                className={dayOffsetStyles}
                label="Day Offset"
                value={dayOffset || ""}
                error={dayOffsetError}
                onBlur={(value) => setDayOffset(value)}
                helperText={dayOffsetError ? "Must be a positive number" : ""}
              />
              <Tooltip enterDelay={400} leaveDelay={0} title={tooltip} placement="bottom">
                <div>
                  <KeyboardTimePicker
                    // This is disabled because to edit the deadline time we also have to edit the
                    // Auto-submit cron job execution time. That right now is done via app.yaml in
                    // hungry_monsters. To allow self-serve we'd have to make the cron dynamic which would
                    // be a bit trickier.
                    disabled
                    className={timePickerStyles}
                    label="Deadline Time"
                    value={deadlineTime || null}
                    InputLabelProps={{ shrink: true }}
                    onChange={(time) => setDeadlineTime(time)}
                  />
                </div>
              </Tooltip>
            </div>
            {showButton && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  paddingRight: "30px",
                  marginBottom: "30px",
                }}
              >
                <Button disabled={loading} onClick={onCancel} variant="outlined" color="primary">
                  Cancel
                </Button>
                <Button
                  disabled={loading}
                  style={{ marginLeft: "15px" }}
                  onClick={onClick}
                  variant="outlined"
                  color="secondary"
                >
                  Confirm
                </Button>
              </div>
            )}
          </React.Fragment>
        );
      }}
    </GraphqlMutation>
  );
};

export const HungryMonstersSettings = () => {
  const [closureExpanded, setClosureExpanded] = React.useState(false);
  const [deadlinesExpanded, setDeadlinesExpanded] = React.useState(false);
  const [dates, setDates] = React.useState({ deadlineDate: null, startDate: null });
  const [deadlineTime, setDeadlineTime] = React.useState();
  const { btnStyles, timePickerStyles, weekBegStyles, deleteBtn, icon } = useStyles();

  return (
    <PageHeading maxWidth="md" heading="Settings">
      <Accordion expanded={closureExpanded} style={{ marginBottom: "30px" }}>
        <AccordionSummary
          onClick={() => setClosureExpanded(!closureExpanded)}
          expandIcon={<ExpandLessIcon />}
        >
          {closureExpanded ? (
            <H3>Hungry Monsters Closures</H3>
          ) : (
            <Typography variant="button">Hungry Monsters Closures</Typography>
          )}
        </AccordionSummary>
        <AccordionDetails>
          <Grid container>
            <Typography variant="body1" component="p">
              Below you can specify days that Hungry Monsters are closed. Doing so will:
            </Typography>
            <ul style={{ marginBottom: "30px" }}>
              <li>
                <Typography variant="body1" component="p">
                  Prevent anyone from being able to create orders for that day (including auto
                  submissions).
                </Typography>
              </li>
              <li>
                <Typography variant="body1" component="p">
                  Prevent Diet Advice and Deliveries from being created for that day.
                </Typography>
              </li>
              <li>
                <Typography variant="body1" component="p">
                  Show the date on the Nursery home page, so they are aware of the closure.
                </Typography>
              </li>
            </ul>
            <Grid style={{ marginBottom: "30px" }} item xs={12}>
              <Typography variant="button">Days Closed</Typography>
              <GraphqlMutation mutation={CLOSE_HM} withError withLoading>
                {(closeTheBizness) => {
                  const onSubmit = async (dates) => {
                    const result = await closeTheBizness({
                      variables: dates,
                      refetchQueries: [{ query: CLOSURES, variables: {} }],
                    });
                    return result;
                  };
                  return <StartEndDateSelector onSubmit={onSubmit} />;
                }}
              </GraphqlMutation>
            </Grid>
            <GraphqlQuery query={CLOSURES} variables={{}} withError withLoading>
              {({ data, error }) => {
                if (error) {
                  return <CentredErrorIcon />;
                }

                return <ClosureDates dates={data.hmClosures} />;
              }}
            </GraphqlQuery>
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={deadlinesExpanded}>
        <AccordionSummary
          onClick={() => setDeadlinesExpanded(!deadlinesExpanded)}
          expandIcon={<ExpandLessIcon />}
        >
          {deadlinesExpanded ? (
            <H3>Configure Deadlines</H3>
          ) : (
            <Typography variant="button">Configure Deadlines</Typography>
          )}
        </AccordionSummary>
        <AccordionDetails>
          <Grid container>
            <Grid item xs={12} style={{ marginBottom: "30px" }}>
              <Typography variant="body1" style={{ marginBottom: "15px" }}>
                Most weeks will have the same deadline. It will be a certain number of days before
                the week begins (day offset), at a certain time. Below is that default deadline,
                you may configure it and it will apply to all weeks which do not have a specific
                deadline applied to it.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <GraphqlQuery query={DEFAULT} variables={{}}>
                {({ data, error }) => {
                  if (error) {
                    return <CentredErrorIcon />;
                  }
                  const {
                    defaultOrderDeadline: { dayOffset, deadlineTime },
                  } = data;
                  return (
                    <DefaultOrderDeadline
                      dayOffset={dayOffset}
                      deadlineTime={moment(deadlineTime, "HH:mm:ss")}
                    />
                  );
                }}
              </GraphqlQuery>
            </Grid>
            <Grid item xs={12} style={{ marginBottom: "30px" }}>
              <Typography variant="body1" style={{ marginBottom: "15px" }}>
                You can also configure specific weeks to have a different deadline. This is useful
                at Christmas for example when you may wish to have the new year’s orders submitted
                before the christmas break.
              </Typography>
              <Typography variant="body1" style={{ marginBottom: "15px" }}>
                To do so select the start date of the week you wish to configure, then select the
                deadline date that you wish to give it.
              </Typography>
            </Grid>
            <Grid item xs={12} container justifyContent="center">
              <KeyboardDatePicker
                clearable
                label="Week beginning"
                allowKeyboardControl
                variant="dialog"
                className={weekBegStyles}
                minDate={moment()}
                format="MMMM Do YYYY"
                value={dates.startDate || null}
                onChange={(d) => {
                  if (d) {
                    setDates({ ...dates, startDate: d.startOf("isoWeek").format("YYYY-MM-DD") });
                  } else {
                    setDates({ ...dates, startDate: d });
                  }
                }}
                KeyboardButtonProps={{ "aria-label": "change date" }}
              />
              <KeyboardDatePicker
                clearable
                className={weekBegStyles}
                disabled={!dates.startDate}
                style={{ marginLeft: "15px", marginBottom: "15px" }}
                label="Deadline Date"
                allowKeyboardControl
                variant="dialog"
                maxDate={moment(dates.startDate).subtract(1, "days")}
                format="MMMM Do YYYY"
                value={dates.deadlineDate || null}
                onChange={(d) => {
                  if (d) {
                    setDates({
                      ...dates,
                      deadlineDate: d.startOf("isoWeek").format("YYYY-MM-DD"),
                    });
                  } else {
                    setDates({ ...dates, deadlineDate: d });
                  }
                }}
                KeyboardButtonProps={{ "aria-label": "change date" }}
              />
              <KeyboardTimePicker
                disabled
                className={timePickerStyles}
                label="Deadline Time"
                value={deadlineTime || null}
                InputLabelProps={{ shrink: true }}
                onChange={(time) => setDeadlineTime(time)}
              />
            </Grid>
            <Grid item xs={12} container justifyContent="flex-end">
              {dates.startDate && dates.deadlineDate && deadlineTime && (
                <GraphqlMutation mutation={SET_ORDER_DEADLINE}>
                  {(setDeadline, { loading }) => {
                    const onClick = async () => {
                      const variables = {
                        input: {
                          weekStartDate: dates.startDate,
                          deadlineDate: dates.deadlineDate,
                          deadlineTime: deadlineTime.format("HH:mm:ss"),
                        },
                      };

                      const result = await setDeadline({
                        variables,
                        refetchQueries: [{ query: CONFIGURED_WEEKS, variables: {} }],
                      });

                      if (result.data) {
                        setDates({ startDate: null, deadlineDate: null });
                        setDeadlineTime(null);
                      }
                    };
                    return (
                      <React.Fragment>
                        <Button
                          disabled={loading}
                          variant="outlined"
                          color="primary"
                          onClick={() => {
                            setDates({ startDate: null, deadlineDate: null });
                          }}
                        >
                          Cancel
                        </Button>
                        <Button
                          disabled={loading}
                          className={btnStyles}
                          variant="outlined"
                          color="secondary"
                          onClick={onClick}
                        >
                          Save Deadline
                        </Button>
                      </React.Fragment>
                    );
                  }}
                </GraphqlMutation>
              )}
            </Grid>
            <H3 style={{ margin: "15px 0px" }}>Configured Weeks</H3>
            <Grid item xs={12} container justifyContent="center">
              <GraphqlQuery query={CONFIGURED_WEEKS} variables={{}}>
                {({ data, error }) => {
                  if (error) {
                    return <CentredErrorIcon />;
                  }
                  const { allConfiguredWeeks } = data;

                  return (
                    <Table size="small" style={{ maxWidth: "400px" }}>
                      <TableHead>
                        <TableRow>
                          <TableCell style={{ width: "156px" }}>Week Beginning</TableCell>
                          <TableCell style={{ width: "156px" }}>Deadline Date</TableCell>
                          <TableCell style={{ width: "56px" }}></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {allConfiguredWeeks.map((week) => {
                          const { startDate, deadline } = week;

                          return (
                            <TableRow key={week.id}>
                              <TableCell>
                                <Chip
                                  variant="outlined"
                                  size="small"
                                  label={moment(startDate, "YYYY-MM-DD").format("Do MMM YYYY")}
                                />
                              </TableCell>
                              <TableCell>
                                <Chip
                                  size="small"
                                  variant="outlined"
                                  label={moment(deadline.deadlineDate, "YYYY-MM-DD").format(
                                    "Do MMM YYYY",
                                  )}
                                />
                              </TableCell>
                              <TableCell>
                                <GraphqlMutation mutation={DELETE_DEADLINE}>
                                  {(deleteTheDeadline, { loading }) => {
                                    return (
                                      <IconButton
                                        disabled={loading}
                                        className={deleteBtn}
                                        onClick={() => {
                                          const confirm = window.confirm(
                                            "Delete deadline for week?",
                                          );
                                          if (confirm) {
                                            deleteTheDeadline({
                                              variables: { id: deadline.id },
                                              refetchQueries: [
                                                { query: CONFIGURED_WEEKS, variables: {} },
                                              ],
                                            });
                                          }
                                        }}
                                      >
                                        <Icons.DeleteIcon className={icon} />
                                      </IconButton>
                                    );
                                  }}
                                </GraphqlMutation>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  );
                }}
              </GraphqlQuery>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </PageHeading>
  );
};
