import styled from "styled-components";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";

/**
 * The default icon sits a little higher than the text. This just makes it
 * so the icon can sit next to the text horizontally no problem.
 */

export const InfoNextToText = styled(InfoOutlinedIcon)`
  position: relative;
  top: 6px;
`;
