import { gql } from "@apollo/client";
import { getOperationName } from "@apollo/client/utilities";
import React from "react";
import moment from "moment";
import { uniqBy } from "lodash";
import styled from "styled-components";
import { useParams } from "react-router";
import { H3 } from "components/typography/H3";
import { makeStyles } from "@material-ui/core/styles";
import { GraphqlQuery } from "graphqlUtils/GraphqlQuery";
import { GraphqlMutation } from "graphqlUtils/GraphqlMutation";
import { PageHeading } from "components/PageHeading";
import { DatePickerSection } from "components/DatePickerSection";
import { CentredErrorIcon } from "components/CentredErrorIcon";
import { ResolveUnresolvedModal } from "containers/menus/ResolveUnresolvedModal";
import { NotResolvedAlert } from "containers/menus/NotResolvedAlert";
import { SensitivitiesSearch } from "containers/ordersSummary/SensitivitiesSearch";
import { useGraphqlSubscription } from "graphqlUtils/useGraphqlSubscription";
import {
  Paper,
  Button,
  TableContainer,
  TableBody,
  Table,
  TableHead,
  Chip,
  TableRow,
  TableCell,
  Grid,
  Typography,
} from "@material-ui/core";
import {
  CHILDREN_NOT_SERVED_LUNCH_FOR_DAY,
  CHILDREN_NOT_SERVED_TEA_FOR_DAY,
} from "containers/menus/queries";
import { isEmpty } from "lodash";
import { useGraphqlQuery } from "graphqlUtils/useGraphqlQuery";
import { Loading } from "components/Loading";
import { Alerts } from "components/alerts/Alerts";
import { PAST_MENU } from "pages/admin/PastMenus";
import { NURSERIES_WITH_DIET_ADVICE } from "pages/admin/NurseryDietAdviceList";

const useNotServedStyles = makeStyles({
  icon: { height: "20px", position: "relative" },
  info: { marginBottom: "30px" },
  chip: { marginRight: "10px", marginTop: "5px", marginBottom: "5px" },
  moveIcon: {
    height: "15px",
    position: "relative",
    top: "4px",
    cursor: "move",
  },
});

const GENERATE_DIET_ADVICE = gql`
  mutation GenerateDietAdvice($date: Date!) {
    generateDietAdvice(date: $date) {
      id
      name
    }
  }
`;

const TableWrapper = styled.div`
  margin-bottom: 30px;
  max-height: 450px;
`;

const Flexin = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
`;

const Buttons = styled.div`
  width: 430px;
  margin-top: 15px;
`;

const HM_CLOSED = gql`
  query IsClosed($date: Date!) {
    isHmClosed(date: $date)
  }
`;

const HM_CLOSED_SUB = gql`
  subscription IsHmClosedSubscription($date: Date!) {
    isHmClosedSubscription(date: $date)
  }
`;

const LUNCH_NUMBERS_CSV = gql`
  mutation DownloadLunchNumbers($date: Date!) {
    downloadLunchNumbers(date: $date)
  }
`;

const COLD_LUNCH_LABELS = gql`
  mutation DownloadColdLunchLabels($date: Date!) {
    downloadColdLunchLabels(date: $date)
  }
`;

const COLD_TEA_LABELS = gql`
  mutation DownloadColdTeaLabels($date: Date!) {
    downloadColdTeaLabels(date: $date)
  }
`;

const TEA_NUMBERS_CSV = gql`
  mutation DownloadTeaNumbers($date: Date!) {
    downloadTeaNumbers(date: $date)
  }
`;

const SPECIAL_NUMBERS_CSV = gql`
  mutation SpecialMealNumbers($date: Date!) {
    specialMealNumbers(date: $date)
  }
`;

const SPECIAL_DIET_LABELS = gql`
  mutation SpecialDietLabels($date: Date!) {
    specialDietLabels(date: $date)
  }
`;

const CHILDREN_WITH_TWO_OR_MORE_HOT_SUBSTITUTES = gql`
  mutation ChildrenWithTwoOrMoreHotSubstitutes($date: Date!) {
    childrenWithTwoOrMoreHotSubstitutes(date: $date)
  }
`;

const PUBLISH_DIET_ADVICE = gql`
  mutation PublishDietAdviceForWeek($date: Date!) {
    publishDietAdviceForWeek(date: $date)
  }
`;

// On Orders summary day it's confusing because we are on the single day, but
// it shows the options for the rest of them. We need to disable the buttons
// that you can't use (the days) and only show the filter by type...
const ChildrenNotServedTable = (props) => {
  const { childrenNotServedLunch, childrenNotServedTea, childrenNotServed, tableStyles, date } =
    props;
  const { chip, table } = useNotServedStyles();
  const [lunch, setLunch] = React.useState(false);
  const [tea, setTea] = React.useState(false);

  useGraphqlSubscription({
    subscription: HM_CLOSED_SUB,
    variables: { date },
    onSubscriptionData: ({ client, subscriptionData }) => {
      client.writeQuery({
        query: HM_CLOSED,
        variables: { date },
        data: { isHmClosed: subscriptionData.data.isHmClosedSubscription },
      });
    },
  });

  let filteredChildren;
  if (lunch === false && tea === true) {
    filteredChildren = childrenNotServedTea;
  } else if (lunch === true && tea === false) {
    filteredChildren = childrenNotServedLunch;
  } else {
    filteredChildren = childrenNotServed;
  }

  return (
    <React.Fragment>
      {childrenNotServed.length > 0 && (
        <div style={{ display: "flex", alignItems: "center", marginBottom: "15px" }}>
          <Typography variant="button" style={{ marginRight: "15px", marginTop: "0px" }}>
            Filter By Meal Type
          </Typography>
          <Button
            onClick={() => {
              setTea(false);
              setLunch(!lunch);
            }}
            variant="outlined"
            color={lunch ? "secondary" : "primary"}
          >
            Show Lunch Only
          </Button>
          <Button
            onClick={() => {
              setLunch(false);
              setTea(!tea);
            }}
            variant="outlined"
            color={tea ? "secondary" : "primary"}
            style={{ marginLeft: "10px", marginRight: "15px" }}
          >
            Show Tea Only
          </Button>
        </div>
      )}
      <TableContainer className={tableStyles} component={TableWrapper}>
        <Table stickyHeader size="small" className={table}>
          <TableHead>
            <TableRow>
              <TableCell style={{ width: "145px" }}>Nursery</TableCell>
              <TableCell style={{ width: "120px" }}>Name</TableCell>
              <TableCell>Sensitivities</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredChildren.map((child) => {
              const { id, name, nursery, sensitivities } = child;
              return (
                <TableRow key={id}>
                  <TableCell>{nursery.name}</TableCell>
                  <TableCell>{name}</TableCell>
                  <TableCell>
                    {sensitivities.map((sensitivity) => {
                      return (
                        <Chip
                          size="small"
                          className={chip}
                          key={sensitivity.id}
                          color="secondary"
                          variant="outlined"
                          label={sensitivity.name}
                        />
                      );
                    })}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </React.Fragment>
  );
};

const useStyles = makeStyles({
  paperStyles: { padding: "30px" },
  spiel: { marginBottom: "15px" },
  subHeading: { marginBottom: "15px" },
  fixBtn: { marginBottom: "15px" },
  buttons: { marginBottom: "30px" },
  marginTop: { marginTop: "30px", padding: "30px" },
  btn: { marginBottom: "10px", width: "100%" },
});

const NotServedChildrenForDay = (props) => {
  const { fixBtn } = useStyles();
  const { date, setModalOpen, modalOpen } = props;

  const lunchResults = useGraphqlQuery({
    query: CHILDREN_NOT_SERVED_LUNCH_FOR_DAY,
    variables: { date },
  });

  const teaResults = useGraphqlQuery({
    query: CHILDREN_NOT_SERVED_TEA_FOR_DAY,
    variables: { date },
  });

  const { loading: teaLoading, error: teaError, data: teaData } = teaResults;
  const { loading: lunchLoading, error: lunchError, data: lunchData } = lunchResults;

  if (teaLoading || lunchLoading) {
    return <Loading />;
  }

  if (teaError) {
    return (
      <React.Fragment>
        <Alerts.Popup
          title="Error"
          message={teaError.message}
          doAlert={Boolean(teaError.message)}
        />
        <CentredErrorIcon />
      </React.Fragment>
    );
  }

  if (lunchError) {
    return (
      <React.Fragment>
        <Alerts.Popup
          title="Error"
          message={lunchError.message}
          doAlert={Boolean(lunchError.message)}
        />
        <CentredErrorIcon />
      </React.Fragment>
    );
  }

  const { pastChildrenNotServedForLunchForDay: childrenNotServedLunch } = lunchData;
  const { pastChildrenNotServedForTeaForDay: childrenNotServedTea } = teaData;
  const allNotServed = uniqBy([...childrenNotServedTea, ...childrenNotServedLunch], "id");

  return (
    <React.Fragment>
      {allNotServed.length > 0 && (
        <Button
          disabled={moment().isAfter(moment(date, "YYYY-MM-DD"))}
          variant="contained"
          color="secondary"
          className={fixBtn}
          onClick={() => setModalOpen(true)}
        >
          Fix unresolved
        </Button>
      )}
      <ChildrenNotServedTable
        date={date}
        childrenNotServed={allNotServed}
        childrenNotServedTea={childrenNotServedTea}
        childrenNotServedLunch={childrenNotServedLunch}
      />
      <ResolveUnresolvedModal
        open={modalOpen}
        date={date}
        onCancel={() => setModalOpen(false)}
        onSubmit={() => setModalOpen(false)}
      />
    </React.Fragment>
  );
};

/*
Example:

<DownloadButton
  mutation={TEA_NUMBERS_CSV}
  dataKey="downloadTeaNumbers"
  variables={{ date }}
  label="Download Regular Tea Numbers"
  btnProps={{
    color: "primary",
    variant: "outlined",
    className: btn,
  }}
/>
*/
const DownloadButton = (props) => {
  const { mutation, variables, btnProps, label, dataKey } = props;
  return (
    <GraphqlMutation mutation={mutation} withError>
      {(download, { loading }) => {
        const onClick = async () => {
          const result = await download({ variables });
          if (!result.data) {
            return;
          } else {
            const url = `${process.env.REACT_APP_BASE_BACKEND_URL}${result.data[dataKey]}`;
            var a = document.createElement("a");
            a.href = url;
            a.target = "_blank";
            a.style = { display: "none" };
            a.download = true;
            // we need to append the element to the dom -> otherwise it will not work in firefox
            document.body.appendChild(a);
            a.click();
            a.remove();
          }
        };

        return (
          <Button onClick={onClick} {...btnProps} disabled={props.disabled || loading}>
            {label}
          </Button>
        );
      }}
    </GraphqlMutation>
  );
};

export const OrdersSummary = () => {
  const { paperStyles, spiel, subHeading, marginTop, buttons, btn } = useStyles();
  const { date } = useParams();
  const [modalOpen, setModalOpen] = React.useState(false);
  const [showAlert, setShowAlert] = React.useState(false);
  const [childrenNotServed, setChildrenCount] = React.useState(0);
  const [publishedPopup, setPublishedPopup] = React.useState(false);

  if (moment(date).isoWeekday() === 6 || moment(date).isoWeekday() === 7) {
    return (
      <PageHeading heading="Orders Summary">
        <DatePickerSection
          type="daily"
          disableArrowKeys={modalOpen}
          maxDate={moment().add(3, "month").startOf("isoWeek").format("YYYY-MM-DD")}
        />
        <Paper elevation={1} square className={paperStyles}>
          <H3 className={subHeading}>Hungry Monsters are closed on weekends</H3>
        </Paper>
      </PageHeading>
    );
  }

  return (
    <PageHeading heading="Orders Summary">
      <DatePickerSection
        type="daily"
        disableArrowKeys={modalOpen}
        maxDate={moment().add(3, "month").startOf("isoWeek").format("YYYY-MM-DD")}
      />
      <GraphqlQuery withLoading withError query={HM_CLOSED} variables={{ date }}>
        {({ data, error }) => {
          if (error) {
            return <CentredErrorIcon />;
          }

          return (
            <React.Fragment>
              {data.isHmClosed ? (
                <Paper elevation={1} square className={paperStyles}>
                  <H3 className={subHeading}>Hungry Monsters is closed today.</H3>
                </Paper>
              ) : (
                <Paper elevation={1} square className={paperStyles}>
                  <H3 style={{ marginBottom: "15px" }}>Order Actions</H3>
                  <Flexin>
                    <div>
                      <Typography variant="button">Diet Advice Actions</Typography>
                      <Buttons>
                        <GraphqlMutation mutation={GENERATE_DIET_ADVICE} withError withLoading>
                          {(generateDietAdvice, { loading }) => {
                            const onClick = async () => {
                              const result = await generateDietAdvice({
                                variables: { date },
                                awaitRefetchQueries: true,
                                refetchQueries: [
                                  getOperationName(CHILDREN_NOT_SERVED_LUNCH_FOR_DAY),
                                  getOperationName(CHILDREN_NOT_SERVED_TEA_FOR_DAY),
                                  getOperationName(PAST_MENU),
                                ],
                              });

                              // Result seems to be undefined if the mutation runs and errors.
                              // in that case we want to NAT continue
                              if (!result) {
                                return;
                              }
                              if (!result.data) {
                                return;
                              }

                              if (isEmpty(result.data.generateDietAdvice)) {
                                // If no children are returned, then they can all eat and we are laughing.
                                return;
                              } else {
                                // not sure if good, or if refetch will be enough TBH
                                setChildrenCount(result.data.generateDietAdvice.length);
                                setShowAlert(true);
                              }
                            };

                            return (
                              <Button
                                color="primary"
                                variant="outlined"
                                onClick={onClick}
                                className={btn}
                                disabled={
                                  moment(date, "YYYY-MM-DD").isBefore(moment(), "day") || loading
                                }
                              >
                                Generate Diet Advice
                              </Button>
                            );
                          }}
                        </GraphqlMutation>
                        <GraphqlMutation
                          mutation={PUBLISH_DIET_ADVICE}
                          variables={{ date }}
                          withError
                          withLoading
                        >
                          {(generateDietAdvice, { loading }) => {
                            const onClick = async () => {
                              const result = await generateDietAdvice({
                                variables: { date },
                                refetchQueries: [getOperationName(NURSERIES_WITH_DIET_ADVICE)],
                              });
                              if (result.data) {
                                setPublishedPopup(true);
                              }
                            };
                            return (
                              <Button
                                color="primary"
                                variant="outlined"
                                onClick={onClick}
                                className={btn}
                                disabled={loading}
                              >
                                Publish Diet Advice
                              </Button>
                            );
                          }}
                        </GraphqlMutation>
                      </Buttons>
                    </div>
                    <div>
                      <Typography variant="button">CSV Downloads</Typography>
                      <Buttons>
                        <DownloadButton
                          mutation={LUNCH_NUMBERS_CSV}
                          dataKey="downloadLunchNumbers"
                          variables={{ date }}
                          label="Regular Lunch Numbers"
                          btnProps={{
                            color: "primary",
                            variant: "outlined",
                            className: btn,
                          }}
                        />
                        <DownloadButton
                          mutation={TEA_NUMBERS_CSV}
                          dataKey="downloadTeaNumbers"
                          variables={{ date }}
                          label="Regular Tea Numbers"
                          btnProps={{
                            color: "primary",
                            variant: "outlined",
                            className: btn,
                          }}
                        />
                        <DownloadButton
                          mutation={SPECIAL_NUMBERS_CSV}
                          dataKey="specialMealNumbers"
                          variables={{ date }}
                          label="Special Diet Report"
                          btnProps={{
                            color: "primary",
                            variant: "outlined",
                            className: btn,
                          }}
                        />
                        <DownloadButton
                          mutation={SPECIAL_DIET_LABELS}
                          dataKey="specialDietLabels"
                          variables={{ date }}
                          label="Special Diet Labels"
                          btnProps={{
                            color: "primary",
                            variant: "outlined",
                            className: btn,
                          }}
                        />
                        <DownloadButton
                          mutation={COLD_LUNCH_LABELS}
                          dataKey="downloadColdLunchLabels"
                          variables={{ date }}
                          label="Cold Lunch Labels"
                          btnProps={{
                            color: "primary",
                            variant: "outlined",
                            className: btn,
                          }}
                        />
                        <DownloadButton
                          mutation={COLD_TEA_LABELS}
                          dataKey="downloadColdTeaLabels"
                          variables={{ date }}
                          label="Cold Tea Labels"
                          btnProps={{
                            color: "primary",
                            variant: "outlined",
                            className: btn,
                          }}
                        />
                        <DownloadButton
                          mutation={CHILDREN_WITH_TWO_OR_MORE_HOT_SUBSTITUTES}
                          dataKey="childrenWithTwoOrMoreHotSubstitutes"
                          variables={{ date }}
                          label="Children With 2 Or More Hot Substitutes"
                          btnProps={{
                            color: "primary",
                            variant: "outlined",
                            className: btn,
                          }}
                        />
                      </Buttons>
                    </div>
                  </Flexin>
                  <H3 className={subHeading}>Children Not Currently Served</H3>
                  <Typography variant="body1" className={spiel}>
                    The children listed below cannot eat either any of the meals on the lunch
                    menu, or any meals on the tea menu for today. You can edit the menu to
                    accomodate them.
                  </Typography>
                  <Grid
                    container
                    direction="column"
                    alignItems="flex-end"
                    justifyContent="center"
                    className={buttons}
                  >
                    <Grid item xs={4}>
                      <NotResolvedAlert
                        open={showAlert}
                        handleClose={() => setShowAlert(false)}
                        childCount={childrenNotServed}
                      />
                    </Grid>
                  </Grid>
                  <NotServedChildrenForDay
                    date={date}
                    setModalOpen={setModalOpen}
                    modalOpen={modalOpen}
                  />
                </Paper>
              )}
            </React.Fragment>
          );
        }}
      </GraphqlQuery>
      {publishedPopup && (
        <Alerts.Popup
          onClose={() => setPublishedPopup(false)}
          severity="success"
          title="Diet Advice Published"
          doAlert={publishedPopup}
          autoHideDuration={3000}
          message="Diet Advice has been successfully published!"
        />
      )}
      <Paper elevation={1} square className={marginTop}>
        <SensitivitiesSearch date={date} />
      </Paper>
    </PageHeading>
  );
};
