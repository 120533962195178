import { gql } from "@apollo/client";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { PageHeading } from "components/PageHeading";
import { useParams } from "react-router-dom";
import { GraphqlQuery } from "graphqlUtils/GraphqlQuery";
import { H3 } from "components/typography/H3";
import { NurseryContacts } from "containers/nurserySettings/NurseryContacts";
import { UpdateProvidersForm } from "containers/nurserySettings/Providers";
import { Contract } from "containers/nurserySettings/Contract";
import { PasswordReset } from "containers/nurserySettings/PasswordReset";
import { NurseryDetails } from "containers/nurserySettings/NurseryDetails";
import { Typography, Chip, Button } from "@material-ui/core";
import { GraphqlMutation } from "graphqlUtils/GraphqlMutation";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";

const NURSERY = gql`
  query nursery($id: ID!) {
    nursery(id: $id) {
      id
      name
      address
      isActive
      phoneNumber
      contractType
      lunchOrderMinimum
      teaOrderMinimum
      currentLunchProvider
      currentTeaProvider
      defaultLunchCostPerMeal
      defaultTeaCostPerMeal
      nurseryContacts {
        id
        userId
        name
        email
        shouldReceiveReminders
        agreedToReceiveReminders
      }
      daysClosed {
        id
        date
        closedFor
      }
      daysOpen {
        id
        date
        openFor
      }
    }
  }
`;

const TOGGLE_ACTIVE = gql`
  mutation updateNurseryContact($input: UpdateNurseryInput!) {
    updateNursery(input: $input) {
      id
      isActive
    }
  }
`;

const useStyles = makeStyles({ h3Style: { marginBottom: "15px" } });

export const NurserySettings = () => {
  const { nurseryId } = useParams();
  const { h3Style } = useStyles();

  const refetchQueries = [{ query: NURSERY, variables: { id: nurseryId } }];

  return (
    <GraphqlQuery query={NURSERY} variables={{ id: nurseryId }} withError withLoading>
      {({ data }) => {
        if (!data) return null;
        const { nursery } = data;

        return (
          <PageHeading
            maxWidth="md"
            heading={
              <>
                {nursery.name} Settings{" "}
                <span style={{ marginLeft: "10px" }}>
                  {/* be good to make this success when we update material */}
                  {nursery.isActive ? (
                    <Chip
                      label="ACTIVE"
                      variant="outlined"
                      color="primary"
                      icon={<CheckIcon />}
                    />
                  ) : (
                    <Chip
                      label="INACTIVE"
                      variant="outlined"
                      color="secondary"
                      icon={<ClearIcon />}
                    />
                  )}
                </span>
              </>
            }
          >
            <NurseryDetails nursery={nursery} />
            <NurseryContacts
              nurseryId={nurseryId}
              refetchQueries={refetchQueries}
              nurseryContacts={nursery.nurseryContacts}
            />
            <PasswordReset />
            <H3 className={h3Style}>Admin Actions</H3>
            <UpdateProvidersForm nursery={nursery} refetchQueries={refetchQueries} />
            <Contract nursery={nursery} refetchQueries={refetchQueries} />
            <H3 className={h3Style}>Nursery Activation </H3>
            <Typography className={h3Style} variant="body1">
              Deactivating a nursery will put them on a temporary contract, prevent all of the
              nursery&apos;s users from logging into the app and will hide the nursery throughout
              the app - but will keep any existing or past orders. This means if you reactivate
              them later you may need to put them back on a permanent contract and reactivate
              users.
            </Typography>

            <GraphqlMutation mutation={TOGGLE_ACTIVE} withError>
              {(updateNurseryContact) => {
                const variables = {
                  input: { id: nursery.id, isActive: !nursery.isActive },
                };
                const onChange = async () => {
                  await updateNurseryContact({ variables, refetchQueries });
                };

                if (nursery.isActive) {
                  return (
                    <Button size="large" color="primary" variant="outlined" onClick={onChange}>
                      Deactivate
                    </Button>
                  );
                } else {
                  return (
                    <Button size="large" color="secondary" variant="outlined" onClick={onChange}>
                      Activate
                    </Button>
                  );
                }
              }}
            </GraphqlMutation>
          </PageHeading>
        );
      }}
    </GraphqlQuery>
  );
};
