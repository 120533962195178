import { useMutation } from "@apollo/client";

/**
A custom hook that is a thin wrapper around Apollo. It ensures that we can capture errors though so
we don't have to do that in every component andmeans if Apollo change their API (again) we can just
update here.

See also the GraphqlMutation component which uses this and optionally renders an error and a loading
state.

### Examples

  [mutationFun, results] = useMutation({ mutation: MY_QUERY, onError: (e) => console.log(e) })
**/

export function useGraphqlMutation(args, graphqlMutationHook = useMutation) {
  const { onError = (error) => error, mutation } = args;
  const results = graphqlMutationHook(mutation, { onError });
  return results;
}
