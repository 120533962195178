import React from "react";
import { CentredErrorIcon } from "components/CentredErrorIcon";
import moment from "moment";
import { PageHeading } from "components/PageHeading";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import { gql } from "@apollo/client";
import { GraphqlQuery } from "graphqlUtils/GraphqlQuery";
import { groupBy } from "lodash";
import {
  Typography,
  Grid,
  Chip,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";

const CURRENT_AND_FUTURE_CLOSURES = gql`
  query HMClosures($futureOnly: Boolean!) {
    hmClosures(futureOnly: $futureOnly) {
      id
      date
      weekId
    }
  }
`;

export const HMClosures = () => {
  return (
    <PageHeading heading="Hungry Monsters Closures" maxWidth="md">
      <Typography style={{ marginBottom: "15px" }}>
        Hungry Monsters will be closed for the following days. You will not be able to submit
        orders for the days specified below.
      </Typography>
      <GraphqlQuery
        fetchPolicy="network-only"
        query={CURRENT_AND_FUTURE_CLOSURES}
        variables={{ futureOnly: true }}
        withError
        withLoading
      >
        {({ data, error }) => {
          if (error) {
            return <CentredErrorIcon />;
          }
          // This means we have to sort it later by meh.
          const groupedDates = groupBy(data.hmClosures, ({ date }) =>
            moment(date).format("MMMM YYYY"),
          );

          return Object.entries(groupedDates)
            .sort((left, right) => {
              // We can compare any date in each as we care about comparing the month and year only
              // Just so vile. Fuck you javascript with your fucking shit sorting capabilities.
              return moment(left[1][0].date).isSameOrBefore(moment(right[1][0].date)) ? 1 : -1;
            })
            .map(([groupName, datesClosed]) => {
              return (
                <Grid style={{ marginBottom: "15px" }} item xs={12} key={groupName}>
                  <Accordion>
                    <AccordionSummary expandIcon={<ExpandLessIcon />}>
                      <Typography variant="button">{groupName}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid item>
                        {datesClosed
                          .sort((left, right) => {
                            // Just so vile. Fuck you javascript with your fucking shit sorting capabilities.
                            return moment(left.date).isAfter(moment(right.date), "day") ? -1 : 1;
                          })
                          .map(({ date, id }) => {
                            return (
                              <Chip
                                clickable={false}
                                key={id}
                                style={{ marginRight: "15px", marginBottom: "15px" }}
                                variant="outlined"
                                color="primary"
                                label={moment(date).format("MMMM Do YYYY")}
                              />
                            );
                          })}
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                </Grid>
              );
            });
        }}
      </GraphqlQuery>
    </PageHeading>
  );
};
