import React from "react";
import { Switch, Router } from "react-router-dom";
import { ThemeProvider } from "@material-ui/core/styles";
import { ApolloSetup } from "apollo_client.js";
import { HomePage } from "pages/Home";
import { theme } from "theme";
import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { LoggedInRoute } from "containers/routes/LoggedInRoute";
import { Auth0Provider } from "./auth0";
import history from "./history";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { TouchBackend } from "react-dnd-touch-backend";
import { isMobile, isTablet } from "react-device-detect";
import { CssBaseline } from "@material-ui/core";

const onRedirectCallback = (appState) => {
  history.push(appState && appState.targetUrl ? appState.targetUrl : window.location.pathname);
};

const auth0Domain = process.env.REACT_APP_AUTH0_DOMAIN;
const auth0ClientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
const auth0Audience = process.env.REACT_APP_AUTH0_AUDIENCE;

// I'm sure this isn't perfect, but it doesn't have to be
const backend = isMobile || isTablet ? TouchBackend : HTML5Backend;

function App() {
  return (
    <Auth0Provider
      domain={auth0Domain}
      client_id={auth0ClientId}
      redirect_uri={window.location.origin}
      audience={auth0Audience}
      onRedirectCallback={onRedirectCallback}
    >
      <ApolloSetup>
        <Router history={history}>
          <ThemeProvider theme={theme}>
            {/* Effectively an opinionated CSS Reset from Material UI  */}
            <CssBaseline />
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <DndProvider backend={backend}>
                <Switch>
                  <LoggedInRoute path="/" component={HomePage} />
                </Switch>
              </DndProvider>
            </MuiPickersUtilsProvider>
          </ThemeProvider>
        </Router>
      </ApolloSetup>
    </Auth0Provider>
  );
}

export default App;
