import React from "react";
import moment from "moment";
import { Switch, Route, Redirect } from "react-router-dom";
import { LoggedInRoute } from "containers/routes/LoggedInRoute";
import { FourOhFour } from "components/FourOhFour";
import { TemperaturesPage } from "pages/deliveryDriver/TemperaturesPage";
import { DeliveryFormsPage } from "pages/deliveryDriver/DeliveryFormsPage";
import { PrintableDietAdvice } from "pages/admin/PrintableDietAdvice";
import { PackingReport } from "pages/deliveryDriver/PackingReport";
import { KitchenDistributionReport } from "pages/deliveryDriver/KitchenDistributionReport";

const RedirectToToday = () => {
  return <Redirect to={`/temperatures/date/${moment().format("YYYY-MM-DD")}`} />;
};

export const DeliveryDriverHome = () => {
  return (
    <Switch>
      <LoggedInRoute exact path="/" component={RedirectToToday} />
      <LoggedInRoute exact path="/temperatures/date/:date" component={TemperaturesPage} />
      <LoggedInRoute exact path="/delivery-forms/date/:date" component={DeliveryFormsPage} />
      <LoggedInRoute
        exact
        path="/kitchen-distribution-report/date/:date"
        component={KitchenDistributionReport}
      />
      <LoggedInRoute exact path="/packing-reports/date/:date" component={PackingReport} />
      <LoggedInRoute
        exact
        path="/printable-diet-advice/date/:date"
        component={PrintableDietAdvice}
      />
      <Route component={FourOhFour} />
    </Switch>
  );
};
