import { gql } from "@apollo/client";
import React from "react";
import { useParams } from "react-router-dom";
import moment from "moment";
import { PageHeading } from "components/PageHeading";
import { DatePickerSection } from "components/DatePickerSection";
import { GraphqlQuery } from "graphqlUtils/GraphqlQuery";
import { CentredErrorIcon } from "components/CentredErrorIcon";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import { makeStyles } from "@material-ui/core/styles";
import { groupBy } from "lodash";
import {
  Typography,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";

export const PAST_MENU = gql`
  query PastMenu($startDate: Date!) {
    pastMenuRegularMeal(startDate: $startDate) {
      id
      date
      type
      pastAvailableMealComponents {
        id
        mealComponent {
          id
          name
        }
      }
    }
  }
`;

const useStyles = makeStyles({
  space: { marginBottom: "15px" },
  heading: { marginBottom: "15px", fontSize: "20px", fontWeight: "400" },
});

export const PastMenus = () => {
  const { date } = useParams();
  const { space } = useStyles();
  return (
    <PageHeading heading="Past Menus">
      <DatePickerSection
        type="weekly"
        disableArrowKeys={false}
        maxDate={moment().endOf("isoWeek").format("YYYY-MM-DD")}
      />
      <Typography variant="body1" className={space}>
        Below you can see the regular meal that was available on the week selected.
      </Typography>
      <GraphqlQuery query={PAST_MENU} withError variables={{ startDate: date }}>
        {({ data, error }) => {
          if (error) {
            return <CentredErrorIcon />;
          }

          if (data.pastMenuRegularMeal.length === 0) {
            return (
              <Typography
                variant="button"
                style={{ fontSize: "16px", display: "block", textAlign: "center" }}
              >
                No Menu was set for that date!
              </Typography>
            );
          }

          return Object.entries(groupBy(data.pastMenuRegularMeal, "date")).map(
            ([date, meals]) => {
              return <Day key={date} label={moment(date).format("dddd")} meals={meals} />;
            },
          );
        }}
      </GraphqlQuery>
    </PageHeading>
  );
};

const Day = (props) => {
  const { label, meals } = props;
  const { heading } = useStyles();
  const teaMeals = meals.filter(({ type }) => type === "tea");
  const lunchMeals = meals.filter(({ type }) => type === "lunch");

  return (
    <Accordion style={{ marginBottom: "15px" }}>
      <AccordionSummary expandIcon={<ExpandLessIcon />}>
        <Typography variant="button">{label}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container direction="column" justify="center" alignItems="center">
          <Typography variant="button" className={heading}>
            Lunch Meals
          </Typography>
          {lunchMeals.map((meal) => {
            const mealParts = meal.pastAvailableMealComponents
              .map(({ mealComponent: { name } }) => name)
              .join(" • ");

            return (
              <Typography
                key={meal.id}
                style={{ fontSize: "16px", textAlign: "center", maxWidth: "600px" }}
                variant="body1"
              >
                {mealParts}
              </Typography>
            );
          })}
        </Grid>
      </AccordionDetails>
      <AccordionDetails>
        <Grid container direction="column" justify="center" alignItems="center">
          <Typography variant="button" className={heading}>
            Tea Meals
          </Typography>
          {teaMeals.map((meal) => {
            const mealParts = meal.pastAvailableMealComponents
              .map(({ mealComponent: { name } }) => name)
              .join(" • ");

            return (
              <Typography
                key={meal.id}
                style={{ fontSize: "16px", textAlign: "center", maxWidth: "600px" }}
                variant="body1"
              >
                {mealParts}
              </Typography>
            );
          })}
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};
