import React from "react";
import { Alerts } from "components/alerts/Alerts";
import { GraphqlUtils } from "./GraphqlUtils";
import { useGraphqlQuery } from "./useGraphqlQuery";
import { Loading } from "components/Loading";

/**
Adds error and loading handling to graphql queries. If you want to customise these probably best to
just use the hook.

### Examples

  <GraphqlQuery query={MY_QUERY} variables={{id: 123}} withLoading withError>
    {({ data })=> (<div>{data}</div>)}
  </GraphqlQuery>

We wont pass data through if it isn't there, meaning you don't have to do a bunch of data ? data.thing : undefined
or whatever. If there is no data then we should be loading or there should be an error.
**/

export const GraphqlQuery = (props) => {
  // Errors here are more likely to be Network Errors...
  const {
    query,
    variables,
    withLoading = true,
    withError = false,
    fetchPolicy,
    nextFetchPolicy,
    children,
  } = props;
  const results = useGraphqlQuery({ query, variables, fetchPolicy, nextFetchPolicy });
  const { loading, error } = results;
  const message = GraphqlUtils.transformGraphqlError(error);

  if (withLoading && loading) {
    return (
      <React.Fragment>
        {withError && error && (
          <Alerts.Popup title="Error" message={message} doAlert={Boolean(message)} />
        )}
        <Loading />
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        {withError && error && (
          <Alerts.Popup title="Error" message={message} doAlert={Boolean(message)} />
        )}
        {/* If we add data && children, we wont be able to 404 when there is no data but is an error */}
        {children({ ...results, errorMessage: message })}
      </React.Fragment>
    );
  }
};
