import { gql } from "@apollo/client";
import React from "react";
import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
import { GraphqlInput } from "components/inputs/Inputs";
import { GraphqlMutation } from "graphqlUtils/GraphqlMutation";
import { FormControl } from "@material-ui/core";

const InfoSummary = styled.div`
  margin-bottom: 30px;
`;

const UPDATE_NURSERY = gql`
  mutation UpdateNursery($input: UpdateNurseryInput!) {
    updateNursery(input: $input) {
      id
      address
      phoneNumber
      contractType
      teaOrderMinimum
      lunchOrderMinimum
      currentLunchProvider
      currentTeaProvider
      defaultLunchCostPerMeal
      defaultTeaCostPerMeal
    }
  }
`;

const useStyles = makeStyles({
  addressStyles: {
    marginBottom: "10px",
    width: "100%",
  },
  addressInput: {
    fontSize: "22px",
    width: "100%",
  },
  inlineField: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
  },
  wide: { width: "100%" },
});

export const NurseryDetails = (props) => {
  const { inlineField, addressInput, addressStyles, wide } = useStyles();
  const { nursery } = props;
  return (
    <InfoSummary>
      <FormControl className={wide}>
        <GraphqlMutation mutation={UPDATE_NURSERY} withError withLoading={false}>
          {(updateNursery) => {
            const onBlur = (key) => async (changes) => {
              const variables = { input: { id: nursery.id, [key]: changes } };
              await updateNursery({ variables });
            };

            return (
              <>
                <GraphqlInput
                  required
                  multiline
                  label="Name"
                  className={addressStyles}
                  inputProps={{ className: addressInput }}
                  value={nursery.name}
                  type="text"
                  onBlur={onBlur("name")}
                />
                <br />
                <GraphqlInput
                  required
                  multiline
                  label="Delivery Address"
                  className={addressStyles}
                  inputProps={{ className: addressInput }}
                  value={nursery.address}
                  type="text"
                  onBlur={onBlur("address")}
                />
              </>
            );
          }}
        </GraphqlMutation>
      </FormControl>
      <FormControl className={inlineField}>
        <GraphqlMutation mutation={UPDATE_NURSERY} withError withLoading={false}>
          {(updateNursery) => {
            const onBlur = async (phoneNumber) => {
              const variables = { input: { id: nursery.id, phoneNumber } };
              await updateNursery({ variables });
            };

            return (
              <GraphqlInput
                required
                label="Phone Number"
                inputProps={{ className: addressInput }}
                className={addressStyles}
                value={nursery.phoneNumber}
                type="text"
                onBlur={onBlur}
              />
            );
          }}
        </GraphqlMutation>
      </FormControl>
    </InfoSummary>
  );
};
