import React from "react";
import { Typography } from "@material-ui/core";

// Styled component variant - easy to do media queries...?
// const H1 = styled.h1`
//   font-size: 2em;
//   ${media.tablet`
//     font-size: 28px;
//   `}
//   ${media.desktop`
//     font-size: 32px;
//   `}
// `;

export const H3 = (props) => {
  const { children, ...rest } = props;
  // How do we make this responsive?
  return (
    // the variant is essentially the styles.
    // All type is like massive for some reason
    <Typography variant="h5" component="h3" {...rest}>
      {children}
    </Typography>
  );
};
