import {
  red,
  blue,
  green,
  pink,
  brown,
  deepPurple,
  blueGrey,
  teal,
  lightBlue,
  yellow,
  indigo,
  amber,
  lightGreen,
  purple,
  cyan,
  lime,
  orange,
  grey,
  deepOrange,
} from "@material-ui/core/colors";

export const COLOURS = [
  blue,
  pink,
  green,
  red,
  brown,
  deepPurple,
  blueGrey,
  teal,
  lightBlue,
  yellow,
  indigo,
  amber,
  lightGreen,
  purple,
  cyan,
  lime,
  orange,
  grey,
  deepOrange,
];
export const SHADES = [600, 500, 400, 300, 200];
