import { gql } from "@apollo/client";
import React from "react";
import moment from "moment";
import { some } from "lodash";
import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
import { useGraphqlSubscription } from "graphqlUtils/useGraphqlSubscription";
import {
  Button,
  TableContainer,
  TableBody,
  Table,
  TableHead,
  Chip,
  TableRow,
  Switch,
  TableCell,
  Typography,
} from "@material-ui/core";
import { COLOURS, SHADES } from "containers/menus/colours";
import { withStyles } from "@material-ui/core/styles";

const TableWrapper = styled.div`
  margin-bottom: 30px;
  max-height: 450px;
`;

const useNotServedStyles = makeStyles({
  icon: { height: "20px", position: "relative" },
  info: { marginBottom: "30px" },
  chip: { marginRight: "10px", marginTop: "5px", marginBottom: "5px" },
  moveIcon: {
    height: "15px",
    position: "relative",
    top: "4px",
    cursor: "move",
  },
  alignTop: { verticalAlign: "top" },
});

const HM_CLOSED_SUB = gql`
  subscription IsHmClosedSubscription($date: Date!) {
    isHmClosedSubscription(date: $date)
  }
`;

const HM_CLOSED = gql`
  query IsClosed($date: Date!) {
    isHmClosed(date: $date)
  }
`;

const ColouredSwitch = withStyles({
  switchBase: {
    color: (props) => props.colour,
    "&$checked": {
      color: (props) => props.colour,
    },
    "&$checked + $track": {
      backgroundColor: (props) => props.colour,
    },
  },
  checked: {
    color: (props) => props.colour,
  },
  track: {
    color: (props) => props.colour,
  },
})(Switch);

/*
  This is explicitly for when creating / editing a menu, we want to be able to
  see how many unserved children there would be for a given menu, given the
  orders for that given week. This uses the query "what would it look like"
  for a given menu for the week for a given day. We look at the available meals
  on the menu as say if there are any non-served people.

*/

export const ChildrenNotServedTable = (props) => {
  const {
    childrenNotServedLunch,
    childrenNotServedTea,
    childrenNotServed,
    tableStyles,
    onChildHighlight,
    highlightedChildren,
    date,
  } = props;
  const { chip, table, alignTop } = useNotServedStyles();
  const [lunch, setLunch] = React.useState(false);
  const [tea, setTea] = React.useState(false);
  const defaultDays = {
    Monday: true,
    Tuesday: true,
    Wednesday: true,
    Thursday: true,
    Friday: true,
  };
  // If all days are
  const [days, setDays] = React.useState(defaultDays);

  useGraphqlSubscription({
    subscription: HM_CLOSED_SUB,
    variables: { date },
    onSubscriptionData: ({ client, subscriptionData }) => {
      client.writeQuery({
        query: HM_CLOSED,
        variables: { date },
        data: { isHmClosed: subscriptionData.data.isHmClosedSubscription },
      });
    },
  });

  let filteredChildren;
  if (lunch === false && tea === true) {
    filteredChildren = childrenNotServedTea.filter((child) => {
      const { datesNotServedTea } = child;
      return some(datesNotServedTea, (d) => {
        return days[moment(d).format("dddd")];
      });
    });
  } else if (lunch === true && tea === false) {
    filteredChildren = childrenNotServedLunch.filter((child) => {
      const { datesNotServedLunch } = child;
      return some(datesNotServedLunch, (d) => days[moment(d).format("dddd")]);
    });
  } else {
    filteredChildren = childrenNotServed.filter((child) => {
      if (child.datesNotServedTea) {
        const { datesNotServedTea } = child;
        return some(datesNotServedTea, (d) => days[moment(d).format("dddd")]);
      } else {
        const { datesNotServedLunch } = child;
        return some(datesNotServedLunch, (d) => days[moment(d).format("dddd")]);
      }
    });
  }

  return (
    <React.Fragment>
      {childrenNotServed.length > 0 && (
        <div style={{ display: "flex", alignItems: "center", marginBottom: "15px" }}>
          <Typography variant="button" style={{ marginRight: "15px", marginTop: "0px" }}>
            Filter By Meal Type
          </Typography>
          <Button
            onClick={() => {
              setTea(false);
              setLunch(!lunch);
            }}
            variant="outlined"
            color={lunch ? "secondary" : "primary"}
          >
            Show Lunch Only
          </Button>
          <Button
            onClick={() => {
              setLunch(false);
              setTea(!tea);
            }}
            variant="outlined"
            color={tea ? "secondary" : "primary"}
            style={{ marginLeft: "10px", marginRight: "15px" }}
          >
            Show Tea Only
          </Button>

          <Typography variant="button" style={{ marginRight: "15px", marginTop: "0px" }}>
            Filter By Day
          </Typography>
          <Button
            onClick={() => {
              setDays({ ...days, Monday: !days["Monday"] });
            }}
            variant="outlined"
            color={days["Monday"] ? "secondary" : "primary"}
          >
            Mon
          </Button>
          <Button
            onClick={() => {
              setDays({ ...days, Tuesday: !days["Tuesday"] });
            }}
            variant="outlined"
            color={days["Tuesday"] ? "secondary" : "primary"}
            style={{ marginLeft: "10px" }}
          >
            Tues
          </Button>
          <Button
            onClick={() => {
              setDays({ ...days, Wednesday: !days["Wednesday"] });
            }}
            variant="outlined"
            color={days["Wednesday"] ? "secondary" : "primary"}
            style={{ marginLeft: "10px" }}
          >
            Weds
          </Button>
          <Button
            onClick={() => {
              setDays({ ...days, Thursday: !days["Thursday"] });
            }}
            variant="outlined"
            color={days["Thursday"] ? "secondary" : "primary"}
            style={{ marginLeft: "10px" }}
          >
            Thurs
          </Button>
          <Button
            onClick={() => {
              setDays({ ...days, Friday: !days["Friday"] });
            }}
            variant="outlined"
            color={days["Friday"] ? "secondary" : "primary"}
            style={{ marginLeft: "10px" }}
          >
            Fri
          </Button>
        </div>
      )}
      <TableContainer className={tableStyles} component={TableWrapper}>
        <Table stickyHeader size="small" className={table}>
          <TableHead>
            <TableRow>
              <TableCell style={{ width: "145px" }}>Nursery</TableCell>
              <TableCell style={{ width: "120px" }}>Name</TableCell>
              <TableCell>Sensitivities</TableCell>
              <TableCell>Highlight Inedible Components</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredChildren.map((child, index) => {
              const { id, name, nursery, sensitivities, inedibleComponentsForChildOnMenu } =
                child;
              // This essentially cycles through the list of colours, so even if two children
              // had the same colour they would be spaced by other colors in between. Like a rainbow
              const colourIndex = index % COLOURS.length;
              const shadesIndex = index % SHADES.length;
              const childColour = COLOURS[colourIndex][SHADES[shadesIndex]];
              const onClick = () => {
                onChildHighlight(
                  id,
                  inedibleComponentsForChildOnMenu.map(({ name }) => name),
                  childColour,
                );
              };
              return (
                <TableRow key={id}>
                  <TableCell className={alignTop}>{nursery.name}</TableCell>
                  <TableCell className={alignTop}>{name}</TableCell>
                  <TableCell>
                    {sensitivities.map((sensitivity) => {
                      return (
                        <Chip
                          size="small"
                          className={chip}
                          key={sensitivity.id}
                          color="secondary"
                          variant="outlined"
                          label={sensitivity.name}
                        />
                      );
                    })}
                  </TableCell>
                  <TableCell align="left" className={alignTop}>
                    <ColouredSwitch
                      colour={childColour}
                      checked={Boolean(highlightedChildren[id])}
                      onClick={onClick}
                    />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </React.Fragment>
  );
};
