import { gql } from "@apollo/client";
import React from "react";
import { GraphqlQuery } from "graphqlUtils/GraphqlQuery";
import styled from "styled-components";
import { GraphqlMutation } from "graphqlUtils/GraphqlMutation";
import { InfoNextToText } from "components/InfoNextToText";
import { Typography, Button } from "@material-ui/core";
import { useGraphqlSubscription } from "graphqlUtils/useGraphqlSubscription";
import { Loading } from "components/Loading";

const Center = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 50vh;
`;

const Info = styled.div`
  margin-top: 30px;
  max-width: 350px;
`;

const CAN_CREATE_ORDER = gql`
  query CanCreateLunchOrders($date: Date!, $nurseryId: ID!) {
    canCreateLunchOrders(date: $date, nurseryId: $nurseryId) {
      can
      reason
    }
  }
`;

const CAN_CREATE_SUBSCRIPTION = gql`
  subscription CanCreateLunchOrdersSubscription($date: Date!, $nurseryId: ID!) {
    canCreateLunchOrdersSubscription(date: $date, nurseryId: $nurseryId) {
      can
      reason
    }
  }
`;

const CREATE_ORDERS = gql`
  mutation CreateLunchOrders($input: WeekDateNurseryInput!) {
    createLunchOrders(input: $input) {
      id
      childrenWithSensitivities {
        id
        name
        sensitivities {
          id
          name
          order
        }
      }
      lunchOrders {
        ... on EditableLunchOrder {
          id
          date
          regularMeals
          specialMeals
          totalMeals
          closed
          attendingChildren {
            name
            childWithSensitivityId
          }
        }
      }
    }
  }
`;

export const CreateNewLunchOrder = (props) => {
  const { refetchQueries, params } = props;
  useGraphqlSubscription({
    subscription: CAN_CREATE_SUBSCRIPTION,
    variables: { nurseryId: params.nurseryId, date: params.date },
    onSubscriptionData: ({ client, subscriptionData }) => {
      client.writeQuery({
        query: CAN_CREATE_ORDER,
        variables: { nurseryId: params.nurseryId, date: params.date },
        data: {
          canCreateLunchOrders: subscriptionData.data.canCreateLunchOrdersSubscription,
        },
      });
    },
  });
  return (
    <Center>
      <GraphqlMutation withError mutation={CREATE_ORDERS}>
        {(mutation, { loading }) => {
          if (loading) {
            return <Loading />;
          }

          const onClick = () => {
            const variables = {
              input: { startDate: params.date, nurseryId: params.nurseryId },
            };
            mutation({ variables, refetchQueries });
          };

          return (
            <GraphqlQuery query={CAN_CREATE_ORDER} variables={params} withLoading withError>
              {({ data }) => {
                if (data && data.canCreateLunchOrders.can) {
                  return (
                    <React.Fragment>
                      <Button
                        disabled={loading}
                        color="primary"
                        variant="outlined"
                        onClick={onClick}
                      >
                        Click here to create a new order
                      </Button>
                      <Info>
                        <Typography variant="body2" component="p">
                          <InfoNextToText /> New orders will use the previous week’s order as a
                          template.
                        </Typography>
                      </Info>
                    </React.Fragment>
                  );
                } else {
                  return (
                    <Info>
                      <Typography variant="body2" component="p">
                        <InfoNextToText />
                        &nbsp;{data.canCreateLunchOrders.reason}
                      </Typography>
                    </Info>
                  );
                }
              }}
            </GraphqlQuery>
          );
        }}
      </GraphqlMutation>
    </Center>
  );
};
