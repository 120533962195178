import React from "react";
import { Alert, AlertTitle } from "@material-ui/lab";
import { Snackbar } from "@material-ui/core";

/**
All components from Labs are more experimental so we should test this and possibly visually test
them if possible.

The error Alert Accepts an error message to display, a boolean indicating if there is an error
and an autoHideDuration which is in milliseconds.

### Example
A good example is if we do a mutation and get an error returned:

<Mutation mutation={MY_QUERY}>
  {(mutation, { message }) => {
    return (
      <Alerts.Popup error={message} doAlert={Boolean(message)} autoHideDuration={6000} >
      </Alerts.Popup>
    )
  }}
</Mutation>

Optional Props:
  - autoHideDuration (defaults to 0 and therefore never autohides)
**/

const Popup = (props) => {
  const {
    message,
    doAlert,
    autoHideDuration,
    title,
    onClose = (x) => x,
    severity = "error",
  } = props;
  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    setOpen(doAlert);
  }, [doAlert, setOpen]);

  const close = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
    onClose();
  };

  return (
    <Snackbar
      data-testid="lookin-like-a-snack"
      open={open}
      autoHideDuration={autoHideDuration}
      onClose={close}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
    >
      <Alert
        style={{ overflowY: "auto", maxHeight: "500px" }}
        data-testid="error-alert"
        onClose={close}
        severity={severity}
      >
        {title && <AlertTitle>{title}</AlertTitle>}
        {/* Allows newlines in the message */}
        <span style={{ whiteSpace: "pre-line" }}>{message}</span>
      </Alert>
    </Snackbar>
  );
};

export const Alerts = {
  Popup,
};
