import { gql } from "@apollo/client";
import React from "react";
import moment from "moment";
import { makeStyles } from "@material-ui/core/styles";
import { PageHeading } from "components/PageHeading";
import { GraphqlQuery } from "graphqlUtils/GraphqlQuery";
import { CentredErrorIcon } from "components/CentredErrorIcon";
import AddIcon from "@material-ui/icons/Add";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import { AddMenu } from "containers/menus/AddMenu";
import { SubMenus } from "containers/menus/SubMenus";
import { EditMenuModal } from "containers/menus/EditMenuModal";
import {
  Typography,
  Link,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  AccordionActions,
  Button,
} from "@material-ui/core";

const MENUS = gql`
  query Menus {
    menus {
      id
      name
      activeFrom
      activeUntil
      subMenus {
        id
        name
        currentlyActive
      }
    }
  }
`;

const useStyles = makeStyles({
  dateStyles: { marginLeft: "15px" },
  space: { marginBottom: "15px" },
  summary: { alignItems: "center" },
  spiel: { marginBottom: "30px" },
  firstSpiel: { marginBottom: "15px" },
  expansionPanel: { paddingBottom: "0px" },
  details: { paddingTop: "0px" },
});

export const Menus = () => {
  const { dateStyles, space, summary, spiel, firstSpiel, expansionPanel, details } = useStyles();
  const [modalOpen, setModalOpen] = React.useState(false);
  const [editModalOpen, setEditModalOpen] = React.useState(false);
  const [editingMenu, setMenuToEdit] = React.useState();

  const openEditMenu = (menu) => {
    setMenuToEdit(menu);
    setEditModalOpen(true);
  };

  const refetchQueries = [{ query: MENUS, variables: {} }];
  return (
    <PageHeading heading="Menus">
      <Typography variant="body1" className={firstSpiel}>
        Menus are the foods that are available for a given group of weeks. They cycle through
        their sub menus for the entire time they are active and each sub menu lasts a week.
      </Typography>
      <Typography variant="body1" className={firstSpiel}>
        At the end of each week, the currently active menu is saved and cannot be edited. You can
        view all past menus in the{" "}
        <Link href={`/past-menus/date/${moment().format("YYYY-MM-DD")}`}>
          past menus section.
        </Link>{" "}
      </Typography>
      <Typography variant="body1" className={spiel}>
        If a child cannot be served by the current menu, you may edit it below without affecting
        past menus. The currently active menu for today is highlighted in pink.
      </Typography>
      <GraphqlQuery query={MENUS} variables={{}} withLoading withError>
        {({ data, error }) => {
          if (error) {
            return <CentredErrorIcon />;
          }
          const { menus } = data;

          return menus.map((menu) => {
            const { name, activeFrom, activeUntil, subMenus } = menu;
            return (
              <Accordion key={menu.id} className={space}>
                <AccordionSummary className={summary} expandIcon={<ExpandLessIcon />}>
                  <Grid container direction="row" alignItems="center">
                    <Grid item xs={2}>
                      <Typography variant="button">{name}</Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography className={dateStyles} variant="body2">
                        {moment(activeFrom).format("DD/MM/YY")} until{" "}
                        {moment(activeUntil).format("DD/MM/YY")}
                      </Typography>
                    </Grid>
                  </Grid>
                </AccordionSummary>
                <AccordionDetails className={expansionPanel}>
                  <Grid container>
                    <Grid item xs={12}>
                      <Typography variant="button">Sub Menus</Typography>
                    </Grid>
                    <Grid item xs={12} className={space}>
                      <Typography variant="body1">
                        The order the menus appear here is the order that the menus will cycle.
                        I.e. the first menu will be the first week, the second the second week and
                        so on.
                      </Typography>
                    </Grid>
                    <SubMenus
                      subMenus={subMenus}
                      menuId={menu.id}
                      refetchQueries={refetchQueries}
                    />
                  </Grid>
                </AccordionDetails>
                <AccordionActions className={details}>
                  <Button
                    aria-label="delete"
                    color="primary"
                    variant="outlined"
                    onClick={(e) => {
                      e.preventDefault();
                      openEditMenu(menu);
                    }}
                  >
                    Edit Menu
                  </Button>
                </AccordionActions>
              </Accordion>
            );
          });
        }}
      </GraphqlQuery>
      <Button
        variant="outlined"
        color="primary"
        aria-label="add"
        onClick={() => setModalOpen(true)}
      >
        Create Menu&nbsp;&nbsp;
        <AddIcon />
      </Button>
      {modalOpen && (
        <AddMenu
          refetchQueries={refetchQueries}
          open={modalOpen}
          onSubmit={() => setModalOpen(false)}
          onCancel={() => setModalOpen(false)}
        />
      )}
      {editModalOpen && (
        <EditMenuModal
          open={editModalOpen}
          onSubmit={() => setEditModalOpen(false)}
          onCancel={() => setEditModalOpen(false)}
          refetchQueries={refetchQueries}
          menu={editingMenu}
        />
      )}
    </PageHeading>
  );
};
