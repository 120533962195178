import React, { useState, useCallback } from "react";
import { useDrag, useDrop } from "react-dnd";
import update from "immutability-helper";

/*
An abstract component which provides the ability to drag and drop to re-arrange
elements. It requires you provide it some data, a drop component and some cards
and a function to run when we save it to the backend.
Check out the Sensitivities table for an example of how to use it.
*/

export const Drag = (props) => {
  const { children, findCard, moveCard, dropCard, id, cardType } = props;
  const [{ isDragging }, drag] = useDrag({
    type: cardType,
    item: { id },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    end: (dropResult, monitor) => {
      const { id: droppedId } = monitor.getItem();
      const didDrop = monitor.didDrop();
      if (didDrop) {
        // We dropped in a valid drop zone, so let's save that...
        const { index: overIndex } = findCard(dropResult.id);
        dropCard(droppedId, overIndex);
      }
    },
  });
  const [, drop] = useDrop({
    accept: cardType,
    canDrop: () => false,
    hover(itemBeingDragged) {
      const { id: draggedId } = itemBeingDragged;
      if (draggedId !== id) {
        const { index: overIndex } = findCard(id);
        moveCard(draggedId, overIndex);
      }
    },
  });
  return children({ isDragging, dragRef: (node) => drag(drop(node)) });
};

export const DragAndDropTable = (props) => {
  const {
    data,
    saveOrder,
    dropComponent: Component,
    cardType,
    renderAfterList = () => null,
    ...rest
  } = props;
  const [cards, setCards] = useState(data);
  React.useLayoutEffect(() => {
    setCards(data);
  }, [data]);

  const findCard = useCallback(
    (id) => {
      const card = cards.filter((c) => `${c.id}` === id)[0];
      return {
        card,
        index: cards.indexOf(card),
      };
    },
    [cards],
  );

  const dropCard = useCallback(
    (id, atIndex) => {
      const { card, index } = findCard(id);
      const newCards = update(cards, {
        $splice: [
          [index, 1],
          [atIndex, 0, card],
        ],
      });
      setCards(newCards);
      saveOrder(newCards);
    },
    [cards, findCard, saveOrder],
  );

  const moveCard = useCallback(
    (id, atIndex) => {
      const { card, index } = findCard(id);
      setCards(
        update(cards, {
          $splice: [
            [index, 1],
            [atIndex, 0, card],
          ],
        }),
      );
    },
    [cards, findCard],
  );

  const [, drop] = useDrop({ accept: cardType });
  return (
    <Component ref={drop} {...rest}>
      {cards.map((datum, index) => (
        <Drag
          key={datum.id}
          id={`${datum.id}`}
          moveCard={moveCard}
          findCard={findCard}
          dropCard={dropCard}
          cardType={cardType}
        >
          {({ isDragging, dragRef }) => {
            return props.children({ dragRef, isDragging, datum, index });
          }}
        </Drag>
      ))}
      {renderAfterList()}
    </Component>
  );
};
