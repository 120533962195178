import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { ChildrenWithSensitivitiesTable } from "containers/orders/ChildrenWithSensitivitiesTable";
import { AddChildModal } from "containers/orders/AddChildModal";
import AddIcon from "@material-ui/icons/Add";
import { Button } from "@material-ui/core";

const useStyles = makeStyles({ addBtn: { marginTop: "15px" } });

export const ChildrenWithSensitivities = (props) => {
  const {
    params,
    loading,
    oneWeeksOrders,
    refetchQueries,
    onOpenModal,
    onCloseModal,
    onAttendanceUpdate,
    allPast,
    rows,
  } = props;
  const { addBtn } = useStyles();
  const [openAddChildModal, setOpenModal] = React.useState(false);

  return (
    <React.Fragment>
      <ChildrenWithSensitivitiesTable
        params={params}
        rows={rows}
        loading={loading}
        onModalOpen={onOpenModal}
        onModalClose={onCloseModal}
        refetchQueries={refetchQueries}
        oneWeeksOrders={oneWeeksOrders}
        onAttendanceUpdate={onAttendanceUpdate}
        allPast={allPast}
      />
      <Button
        disabled={allPast}
        className={addBtn}
        variant="outlined"
        color="primary"
        aria-label="add"
        onClick={() => {
          onOpenModal();
          setOpenModal(true);
        }}
      >
        Add Child&nbsp;&nbsp;
        <AddIcon />
      </Button>
      {/* If you change this to not re-render you need to reset the forms on cancel / submit */}
      {openAddChildModal && (
        <AddChildModal
          date={params.date}
          nurseryId={params.nurseryId}
          open={openAddChildModal}
          onCancel={() => {
            onCloseModal();
            setOpenModal(false);
          }}
          onSubmit={() => {
            onCloseModal();
            setOpenModal(false);
          }}
          refetchQueries={refetchQueries}
        />
      )}
    </React.Fragment>
  );
};
