import React from "react";
import { PageHeading } from "components/PageHeading";
import { Link } from "react-router-dom";
import { Link as MaterialUiLink, Typography, Input, Button } from "@material-ui/core";
import styled from "styled-components";
import { GraphqlQuery } from "graphqlUtils/GraphqlQuery";
import { gql } from "@apollo/client";
import { CentredErrorIcon } from "components/CentredErrorIcon";
import { Loading } from "components/Loading";
import { Alerts } from "components/alerts/Alerts";

const Form = styled.form`
  max-width: 250px;
  margin: 0 auto;
`;

const UPLOAD_URL = gql`
  query UploadUrl {
    uploadHomePdfUrl
  }
`;

const FileInput = (props) => {
  const { accept, onChange } = props;

  return <Input type="file" accept={accept} onChange={onChange} />;
};

export const PDFUpload = () => {
  const [file, setFile] = React.useState();
  const [uploadError, setError] = React.useState();
  const [success, setSuccess] = React.useState();
  const [uploading, setUploading] = React.useState(false);

  return (
    <PageHeading heading="Upload Homepage PNG">
      <Typography variant="body2" style={{ marginBottom: "30px" }}>
        Here you can select a png to set as the homepage for clients. The png will be set to the
        homepage immediately. You can see what the Nurseries will see as their homepage&nbsp;
        <Link to="/">
          <MaterialUiLink component="span">here</MaterialUiLink>
        </Link>
      </Typography>
      <GraphqlQuery query={UPLOAD_URL} variables={{}}>
        {({ data, loading, error }) => {
          if (error) return <CentredErrorIcon />;
          if (loading) return <Loading />;

          const { uploadHomePdfUrl } = data;

          return (
            <React.Fragment>
              {uploadError && (
                <Alerts.Popup
                  title="Error"
                  message="There was an error uploading please try again. Ensure the file is a PDF"
                  doAlert={true}
                />
              )}
              {success && (
                <Alerts.Popup
                  title="Success"
                  severity="success"
                  message="Upload Successful!"
                  doAlert={true}
                />
              )}

              <Form>
                <FileInput accept=".png" onChange={(e) => setFile(e.target.files[0])} />
                <Button
                  disabled={!file || uploading}
                  style={{ display: "block", margin: "15px auto 0 auto" }}
                  variant="outlined"
                  color="primary"
                  onClick={() => {
                    setError(null);
                    setSuccess(null);
                    setUploading(true);

                    const formData = new FormData();
                    formData.append("homepdf", file);

                    const url = `${process.env.REACT_APP_BASE_BACKEND_URL}${uploadHomePdfUrl}`;

                    fetch(url, { method: "POST", body: formData })
                      .then((response) => {
                        if (response.status === 201 || response.status === 200) {
                          setFile(null);
                          setUploading(false);
                          setSuccess(true);
                        } else {
                          setSuccess(false);
                          setUploading(false);
                          setError(response.statusText);
                        }
                      })
                      .catch((error) => {
                        setSuccess(false);
                        setUploading(false);
                        setError(error);
                      });
                  }}
                >
                  Upload
                </Button>
              </Form>
            </React.Fragment>
          );
        }}
      </GraphqlQuery>
    </PageHeading>
  );
};
