import { gql } from "@apollo/client";
import React from "react";
import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
import { InfoNextToText } from "components/InfoNextToText";
import { H3 } from "components/typography/H3";
import { useTheme } from "@material-ui/core/styles";
import { GraphqlQuery } from "graphqlUtils/GraphqlQuery";
import { GraphqlMutation } from "graphqlUtils/GraphqlMutation";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { useGraphqlSubscription } from "graphqlUtils/useGraphqlSubscription";
import { Alerts } from "components/alerts/Alerts";
import {
  Container,
  DialogTitle,
  DialogContent,
  DialogActions,
  Dialog,
  TextField,
  Typography,
  Chip,
  Tooltip,
  useMediaQuery,
  Button,
  IconButton,
} from "@material-ui/core";

const MEAL_COMPONENTS = gql`
  query MealComponents($active: Boolean) {
    mealComponents(active: $active) {
      id
      name
      sensitivityInformation {
        id
        sensitivityName
        present
      }
    }
  }
`;

const CREATE_SENSITIVITY = gql`
  mutation CreateSensitivity($input: CreateSensitivityInput!) {
    createSensitivity(input: $input) {
      id
      name
      order
    }
  }
`;

const MEAL_COMPONENTS_SUBSCRIPTION = gql`
  subscription Meals {
    mealComponentsSubscription {
      id
      name
      active
      sensitivityInformation {
        id
        present
        sensitivityName
      }
    }
  }
`;

const useStyles = makeStyles({
  inputStyles: { marginBottom: "15px", width: "66%" },
  nameBlurb: { marginBottom: "20px" },
  container: { paddingTop: "30px", maxWidth: "750px" },
  noBtn: { marginLeft: "50px", maxHeight: "35px" },
  yesBtn: { marginRight: "50px", maxHeight: "35px" },
  chip: { marginRight: "15px", marginBottom: "10px" },
});

const Form = styled.div`
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: center;
`;

const NextPrev = styled.div`
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: space-between;
  margin-top: 30px;
  margin-bottom: 30px;
`;

export const AddSensitivityModal = (props) => {
  const { open, onCancel, onSubmit, refetchQueries } = props;
  return (
    <GraphqlQuery
      query={MEAL_COMPONENTS}
      fetchPolicy="network-only"
      variables={{ active: true }}
      withLoading
      withError
    >
      {({ data }) => {
        return (
          data && (
            <SensitivityModal
              onCancel={onCancel}
              onSubmit={onSubmit}
              mealComponents={data.mealComponents}
              open={open}
              refetchQueries={refetchQueries}
            />
          )
        );
      }}
    </GraphqlQuery>
  );
};

const SensitivityModal = (props) => {
  const { open, mealComponents, onCancel, onSubmit, refetchQueries } = props;
  const { nameBlurb, inputStyles, container, noBtn, yesBtn, chip } = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [mealIndex, setMealIndex] = React.useState(0);
  const [moreMealsToCheck, setNewMealToCheck] = React.useState(false);
  const [sensitivity, updateName] = React.useState("");
  const [isReadOnly, setReadOnly] = React.useState(false);
  const [mealComponentList, setMealComponentList] = React.useState(mealComponents);
  const [mealComponentInformation, setMealComponentInformation] = React.useState({});
  const [focused, setFocused] = React.useState(false);

  useGraphqlSubscription({
    subscription: MEAL_COMPONENTS_SUBSCRIPTION,
    variables: {},
    onSubscriptionData: ({ client, subscriptionData }) => {
      setNewMealToCheck(false);
      setNewMealToCheck(true);
      client.writeQuery({
        query: MEAL_COMPONENTS,
        variables: { active: true },
        data: { mealComponents: subscriptionData.data.mealComponentsSubscription },
      });
    },
  });

  React.useEffect(() => {
    setMealComponentList(mealComponents);
  }, [mealComponents, setMealComponentList]);

  // eslint-disable-next-line
  const activeMeal = mealComponentList[mealIndex] || {};
  const checked =
    mealComponentInformation[activeMeal.id] && mealComponentInformation[activeMeal.id].present;

  const setActiveComponent = React.useCallback(
    (present) => {
      const mealInfo = {
        [activeMeal.id]: {
          mealComponentId: activeMeal.id,
          mealComponentName: activeMeal.name,
          present,
        },
      };

      setMealComponentInformation({ ...mealComponentInformation, ...mealInfo });
    },
    [setMealComponentInformation, mealComponentInformation, activeMeal],
  );

  const yes = React.useCallback(() => {
    setActiveComponent(true);
  }, [setActiveComponent]);

  const no = React.useCallback(() => {
    setActiveComponent(false);
  }, [setActiveComponent]);

  const maxIndex = mealComponentList.length - 1;
  const haveNotAnsweredCurrent = React.useCallback(() => {
    if (mealComponentList.length === 0) {
      return false;
    } else {
      return (
        mealComponentInformation[activeMeal.id] === null ||
        mealComponentInformation[activeMeal.id] === undefined
      );
    }
  }, [mealComponentList, mealComponentInformation, activeMeal]);

  const onLastPage = mealComponentList.length === 0 || mealIndex === maxIndex;

  const next = React.useCallback(() => {
    if (mealIndex >= maxIndex || haveNotAnsweredCurrent()) {
      return;
    }
    const newIndex = mealIndex + 1;
    if (newIndex < maxIndex) {
      setMealIndex(newIndex);
    } else {
      setMealIndex(maxIndex);
    }
  }, [mealIndex, setMealIndex, maxIndex, haveNotAnsweredCurrent]);

  const previous = React.useCallback(() => {
    if (mealIndex <= 0) {
      return;
    }
    const minIndex = 0;
    const newIndex = mealIndex - 1;
    if (newIndex > minIndex) {
      setMealIndex(newIndex);
    } else {
      setMealIndex(minIndex);
    }
  }, [setMealIndex, mealIndex]);

  // Add event listeners for key presses so we can left / right arrow through orders
  React.useEffect(() => {
    function downHandler({ key }) {
      if (focused) {
        return;
      }
      if (key === "ArrowLeft") {
        previous();
      }
      if (key === "ArrowRight") {
        next();
      }
      if (key === "y") {
        yes();
      }
      if (key === "n") {
        no();
      }
    }

    if (open) {
      window.addEventListener("keydown", downHandler);
      // Remove event listeners on cleanup
      return () => {
        window.removeEventListener("keydown", downHandler);
      };
    }
  }, [next, previous, open, yes, no, focused]);

  const resetForm = () => {
    setMealComponentInformation({});
    updateName("");
    setMealIndex(0);
  };

  const mealInformation = Object.entries(mealComponentInformation).map((key_value) => {
    return key_value[1];
  });
  const readOnlyTip =
    "Read only sensitivities will be checked for when generating diet advice, but nurseries wont be able to select it as an option; only admins can";
  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      fullScreen={fullScreen}
      fullWidth
      maxWidth="md"
      open={open}
    >
      <Container className={container}>
        <DialogTitle disableTypography>
          <Typography variant="h4" component="h4">
            Add a Sensitivity
          </Typography>
        </DialogTitle>
        <DialogContent>
          <TextField
            className={inputStyles}
            required
            autoFocus
            onFocus={() => setFocused(true)}
            label="Enter sensitivity name..."
            value={sensitivity}
            onChange={(event) => updateName(event.target.value)}
            onBlur={() => {
              setFocused(false);
            }}
          />
          <Tooltip title={readOnlyTip} placement="bottom-start">
            <Button
              style={{ marginLeft: "5px" }}
              onClick={() => setReadOnly(!isReadOnly)}
              variant="outlined"
              color={isReadOnly ? "secondary" : "primary"}
            >
              Read Only
            </Button>
          </Tooltip>
        </DialogContent>
        <DialogContent className={nameBlurb}>
          <H3>Meal Components</H3>
          <Typography variant="body2" component="p">
            <InfoNextToText /> When you add a sensitivity for a given week, you must check all of
            the meal components that are available that week, and tell us whether or not they
            contain the new sensitivity.
          </Typography>
          <Typography variant="body2" component="p">
            You can use the arrow keys to move through the list.
          </Typography>
        </DialogContent>
        <DialogContent>
          {mealInformation
            .filter(({ present }) => present)
            .map(({ mealComponentId, mealComponentName }) => {
              return (
                <Chip
                  clickable={false}
                  className={chip}
                  key={mealComponentId}
                  variant="outlined"
                  color="primary"
                  label={mealComponentName}
                />
              );
            })}
        </DialogContent>
        <DialogContent>
          <Form>
            <H3>
              Does {activeMeal.name} contain {sensitivity ? sensitivity : "________"}?
            </H3>
          </Form>
        </DialogContent>
        <DialogContent>
          <NextPrev>
            <IconButton disabled={mealIndex <= 0} onClick={previous}>
              <ArrowBackIosIcon />
            </IconButton>
            <Button
              onClick={no}
              disabled={mealComponentList.length === 0}
              variant="contained"
              className={noBtn}
              color={checked === false ? "secondary" : "primary"}
            >
              No
            </Button>
            <Button
              onClick={yes}
              disabled={mealComponentList.length === 0}
              variant="contained"
              className={yesBtn}
              color={checked ? "secondary" : "primary"}
            >
              Yes
            </Button>
            <IconButton
              disabled={mealIndex >= maxIndex || haveNotAnsweredCurrent()}
              onClick={next}
            >
              <ArrowForwardIosIcon />
            </IconButton>
          </NextPrev>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              resetForm();
              onCancel();
            }}
            color="primary"
          >
            Cancel
          </Button>
          <GraphqlMutation mutation={CREATE_SENSITIVITY} withError>
            {(createSens, { loading }) => {
              const variables = {
                input: {
                  name: sensitivity,
                  readOnly: isReadOnly,
                  mealComponentInformation: mealInformation.map(
                    ({ mealComponentId, present }) => ({ mealComponentId, present }),
                  ),
                },
              };
              const submit = async () => {
                const data = await createSens({ variables, refetchQueries });
                if (!data.data) {
                  return;
                }
                resetForm();
                onSubmit();
              };
              const warningMessage = `It looks like more meals where added while you were adding this sensitivity.\
 They have been added to the list of meals to check please provide allergy information for them`;
              return (
                <React.Fragment>
                  <Button
                    disabled={haveNotAnsweredCurrent() || !onLastPage || loading}
                    onClick={submit}
                    color="primary"
                  >
                    Submit
                  </Button>
                  <Alerts.Popup
                    message={warningMessage}
                    doAlert={moreMealsToCheck}
                    autoHideDuration={12000}
                    severity="warning"
                  />
                </React.Fragment>
              );
            }}
          </GraphqlMutation>
        </DialogActions>
      </Container>
    </Dialog>
  );
};
