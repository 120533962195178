import { gql } from "@apollo/client";
import React from "react";
import moment from "moment";
import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
import { NumberInput } from "components/inputs/Inputs";
import { GraphqlMutation } from "graphqlUtils/GraphqlMutation";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";

const TableWrapper = styled.div`
  background-color: #fafafa;
  margin-bottom: 30px;
`;

const Div = styled.div`
  padding-top: 30px;
`;

const useStyles = makeStyles({
  tableStyles: {
    maxWidth: "750px",
  },
  inputStyles: {
    maxWidth: "43px",
    textAlign: "center",
  },
});

const UPDATE_REGULAR_NUMBERS = gql`
  mutation UpdateRegularNumbers($input: UpdateRegularTeaNumbersInput!) {
    updateRegularTeaNumbers(input: $input) {
      ... on EditableTeaOrder {
        id
        regularMeals
        specialMeals
        totalMeals
      }
      ... on NonEditableTeaOrder {
        id
        date
        regularMeals
        specialMeals
        totalMeals
      }
    }
  }
`;

// This is always regularMeals as that's the only one we input data into.
const InputCell = (props) => {
  const { teaOrder } = props;
  const { closed, regularMeals } = teaOrder;
  // We need to disable if submitted. Both editable and non editable can be submitted/
  switch (teaOrder.__typename) {
    case "EditableTeaOrder":
      return teaOrder.submittedDatetime ? (
        <NumberCell closed={closed} value={regularMeals} />
      ) : (
        <EditableRegularTeaMeals teaOrder={teaOrder} />
      );
    case "NonEditableTeaOrder":
      return <NumberCell closed={closed} value={regularMeals} />;
    default:
      return null;
  }
};

const NumberCell = (props) => {
  const { value, closed } = props;

  return (
    <TableCell>
      {closed ? <Typography>&nbsp;-</Typography> : <Typography>{value}</Typography>}
    </TableCell>
  );
};

const EditableRegularTeaMeals = (props) => {
  const { teaOrder } = props;
  const { inputStyles } = useStyles();
  const { closed, regularMeals, id } = teaOrder;

  if (closed) {
    return (
      <TableCell>
        <Typography>Closed</Typography>
      </TableCell>
    );
  }

  return (
    <TableCell>
      <GraphqlMutation withError mutation={UPDATE_REGULAR_NUMBERS}>
        {(mutation, { loading }) => {
          return (
            <NumberInput
              dataTestid={`regular-meals-input-${id}`}
              margin="dense"
              disabled={loading}
              className={inputStyles}
              value={regularMeals}
              onBlur={(state) => {
                return mutation({
                  optimisticResponse: {
                    __typename: "Mutation",
                    updateRegularTeaNumbers: {
                      // the old one...
                      ...teaOrder,
                      // Plus the new from state. But where is the state?
                      // It's in the number component, gets passed to the onBlur
                      regularMeals: state,
                    },
                  },
                  variables: {
                    input: {
                      teaOrderId: id,
                      regularMeals: state,
                    },
                  },
                });
              }}
            />
          );
        }}
      </GraphqlMutation>
    </TableCell>
  );
};

export const TeaNumbersTable = (props) => {
  const {
    oneWeeksOrders: { teaOrders },
  } = props;

  const { tableStyles } = useStyles();

  return (
    <Div>
      <TableContainer component={TableWrapper} className={tableStyles}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>&nbsp;</TableCell>
              {teaOrders.map((teaOrder) => {
                return (
                  <TableCell key={teaOrder.id}>
                    {moment(teaOrder.date, "YYYY-MM-DD").format("ddd")}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow hover>
              <TableCell>Regular Meals</TableCell>
              {teaOrders.map((teaOrder) => {
                return <InputCell key={teaOrder.id} teaOrder={teaOrder} />;
              })}
            </TableRow>
            <TableRow>
              <TableCell>Special Meals</TableCell>
              {teaOrders.map((teaOrder) => {
                return (
                  <NumberCell
                    key={teaOrder.id}
                    closed={teaOrder.closed}
                    value={teaOrder.specialMeals}
                  />
                );
              })}
            </TableRow>
            <TableRow>
              <TableCell>Total Meals</TableCell>
              {teaOrders.map((teaOrder) => {
                return (
                  <NumberCell
                    key={teaOrder.id}
                    closed={teaOrder.closed}
                    value={teaOrder.totalMeals}
                  />
                );
              })}
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Div>
  );
};
