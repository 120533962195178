import { gql } from "@apollo/client";
import React from "react";
import moment from "moment";
import styled from "styled-components";
import { PageHeading } from "components/PageHeading";
import { GraphqlQuery } from "graphqlUtils/GraphqlQuery";
import { Link } from "react-router-dom";
import { DatePickerSection } from "components/DatePickerSection";
import { CentredErrorIcon } from "components/CentredErrorIcon";
import { useParams } from "react-router-dom";
import { Loading } from "components/Loading";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Chip,
  Typography,
  Link as MaterialUiLink,
} from "@material-ui/core";
import { FilterableNurseriesTable } from "containers/nurseries/FilterableNurseriesTable";

const TableWrapper = styled.div`
  background-color: white;
  margin-bottom: 30px;
  height: 650px;
  overflow-y: scroll;
`;

const NURSERIES = gql`
  query Nurseries($date: Date!) {
    nurseriesWithDeliveriesForDay(date: $date) {
      id
      name
      isActive
      teaStatus(date: $date)
      lunchStatus(date: $date)
    }
  }
`;

export const NurseryPortionInfoList = () => {
  const { date } = useParams();

  return (
    <PageHeading maxWidth="md" heading="Nursery Portion Information">
      <DatePickerSection
        type="daily"
        disableArrowKeys={false}
        maxDate={moment().add(6, "month").startOf("month").format("YYYY-MM-DD")}
      />
      <Typography style={{ marginBottom: "30px" }}>
        Below you can see a summary of the orders for the given week. The status indicates whether
        a nursery has submitted their order for the week. Click the nursery&apos;s name below to
        go to their order for the given week.
      </Typography>
      <GraphqlQuery query={NURSERIES} variables={{ date }} withError withLoading={false}>
        {({ data, error, loading }) => {
          if (error) return <CentredErrorIcon />;
          if (loading) return <Loading />;
          const monday = moment(date, "YYYY-MM-DD", true).startOf("isoWeek").format("YYYY-MM-DD");
          const { nurseriesWithDeliveriesForDay: nurseries } = data;

          return (
            <FilterableNurseriesTable data={nurseries}>
              {({ nurseriesList }) => {
                return (
                  <TableContainer component={TableWrapper}>
                    <Table stickyHeader size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell
                            style={{
                              borderTop: "1px solid rgb(224, 224, 224)",
                              borderLeft: "1px solid rgb(224, 224, 224)",
                            }}
                          >
                            Nursery
                          </TableCell>
                          <TableCell
                            style={{
                              borderTop: "1px solid rgb(224, 224, 224)",
                              borderRight: "1px solid rgb(224, 224, 224)",
                            }}
                          >
                            Active?
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {nurseries.length <= 0 ? (
                          <TableRow hover>
                            <TableCell align="center" colSpan={2}>
                              <Typography>No Nurseries have deliveries today</Typography>
                            </TableCell>
                          </TableRow>
                        ) : (
                          nurseriesList.map((nursery) => {
                            const { name, id } = nursery;

                            return (
                              <TableRow key={id} hover>
                                <TableCell>
                                  <Link
                                    key={id}
                                    to={`/nursery/${id}/portion-information/date/${monday}`}
                                  >
                                    <MaterialUiLink component="p">{name}</MaterialUiLink>
                                  </Link>
                                </TableCell>
                                <TableCell>
                                  <Chip
                                    variant="outlined"
                                    size="small"
                                    color={nursery.isActive ? "primary" : "secondary"}
                                    label={nursery.isActive ? "Active" : "Inactive"}
                                  />
                                </TableCell>
                              </TableRow>
                            );
                          })
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                );
              }}
            </FilterableNurseriesTable>
          );
        }}
      </GraphqlQuery>
    </PageHeading>
  );
};
