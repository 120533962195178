import React from "react";
import { Alerts } from "components/alerts/Alerts";
import { useGraphqlMutation } from "./useGraphqlMutation";
import { Loading } from "components/Loading";

/**
A component which captures graphql errors and alerts them to the user. Optionally shows a loading
state whilst the query is being fetched. Bear in mind, if using something like optimistic updates
a loading state may be pointless.

This mainly adds the error component and the loading state (if asked for), if you need to customise
them or not use them, just use the hook on its own.

### Examples

  <GraphqlMutation mutation={MY_QUERY} withError withLoading onError={(e) => console.error(e)}>
    {(update, {loading, error, data}) => {
      return (
        <button onClick={()=> update({variables: {id: "123"}})}>
          {data}
        </button>
      )
    }}
  </GraphqlMutation>
**/

export const GraphqlMutation = (props) => {
  const {
    onError = (error) => error,
    mutation,
    children,
    withError = true,
    withLoading = false,
  } = props;
  const [mutateFun, results] = useGraphqlMutation({ mutation, onError });
  const { loading, error } = results;
  const message = error ? error.message : null;

  if (withLoading && loading) {
    return (
      <React.Fragment>
        {withError && error && (
          <Alerts.Popup title="Error." message={message} doAlert={Boolean(message)} />
        )}
        <Loading />
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        {withError && error && (
          <Alerts.Popup title="Error." message={message} doAlert={Boolean(message)} />
        )}
        {children(mutateFun, { ...results, errorMessage: message })}
      </React.Fragment>
    );
  }
};
