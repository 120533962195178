import { gql } from "@apollo/client";
import React from "react";
import styled from "styled-components";
import { PageHeading } from "components/PageHeading";
import { GraphqlQuery } from "graphqlUtils/GraphqlQuery";
import { Link } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Chip,
  Link as MaterialUiLink,
} from "@material-ui/core";
import { FilterableNurseriesTable } from "containers/nurseries/FilterableNurseriesTable";

const TableWrapper = styled.div`
  background-color: white;
  margin-bottom: 30px;
  height: 650px;
  overflow-y: scroll;
`;

const NURSERIES = gql`
  query Nurseries {
    nurseries {
      id
      name
      isActive
      address
      phoneNumber
    }
  }
`;

export const NurserySettingsList = () => {
  const TableHeadBorder = "1px solid rgb(224, 224, 224)";

  return (
    <PageHeading maxWidth="md" heading="Nurseries">
      <Typography style={{ marginBottom: "30px" }}>
        Click the nursery&apos;s name below to go to their settings page. You can filter the
        nurseries by name using the text box. By default we exclude inactive nurseries but you an
        click the checkbox to include them.
      </Typography>
      <GraphqlQuery query={NURSERIES} withError withLoading={false}>
        {({ data, loading }) => {
          if (loading) return;
          if (!data) return null;
          return (
            <FilterableNurseriesTable data={data.nurseries}>
              {({ nurseriesList }) => {
                return (
                  <TableContainer component={TableWrapper}>
                    <Table stickyHeader size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell
                            style={{ borderTop: TableHeadBorder, borderLeft: TableHeadBorder }}
                          >
                            Nursery
                          </TableCell>
                          <TableCell style={{ borderTop: TableHeadBorder }}>Active?</TableCell>
                          <TableCell style={{ borderTop: TableHeadBorder }}>Address</TableCell>
                          <TableCell
                            style={{ borderTop: TableHeadBorder, borderRight: TableHeadBorder }}
                          >
                            Phone Number
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {nurseriesList.map((nursery) => {
                          const { name, id, address, phoneNumber } = nursery;
                          return (
                            <TableRow key={name} hover>
                              <TableCell>
                                <Link key={id} to={`/nursery/${id}`}>
                                  <MaterialUiLink component="p">{name}</MaterialUiLink>
                                </Link>
                              </TableCell>
                              <TableCell>
                                <Chip
                                  variant="outlined"
                                  size="small"
                                  color={nursery.isActive ? "primary" : "secondary"}
                                  label={nursery.isActive ? "Active" : "Inactive"}
                                />
                              </TableCell>
                              <TableCell>{address}</TableCell>
                              <TableCell>{phoneNumber}</TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                );
              }}
            </FilterableNurseriesTable>
          );
        }}
      </GraphqlQuery>
    </PageHeading>
  );
};
