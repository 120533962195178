import { gql } from "@apollo/client";
import React from "react";
import styled from "styled-components";
import moment from "moment";
import { GraphqlQuery } from "graphqlUtils/GraphqlQuery";
import { PageHeading } from "components/PageHeading";
import { CentredErrorIcon } from "components/CentredErrorIcon";
import { DatePickerSection } from "components/DatePickerSection";
import { Link } from "react-router-dom";
import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  Chip,
  TableRow,
  Link as MaterialUiLink,
} from "@material-ui/core";
import { FilterableNurseriesTable } from "containers/nurseries/FilterableNurseriesTable";

const NURSERIES_WITH_INVOICES = gql`
  query NurseriesWithInvoices($date: Date!) {
    nurseriesWithInvoices(date: $date) {
      id
      name
      isActive
    }
  }
`;

const TableWrapper = styled.div`
  background-color: white;
  margin-bottom: 30px;
  height: 650px;
  overflow-y: scroll;
`;

export const Invoices = (props) => {
  const {
    match: { params },
  } = props;
  const TableHeadBorder = "1px solid rgb(224, 224, 224)";

  return (
    <PageHeading maxWidth="md" heading="Invoices">
      <DatePickerSection
        type="monthly"
        disableArrowKeys={false}
        maxDate={moment().add(6, "month").startOf("month").format("YYYY-MM-DD")}
      />
      <GraphqlQuery query={NURSERIES_WITH_INVOICES} variables={params} withError withLoading>
        {({ data, error }) => {
          if (error) return <CentredErrorIcon />;
          const { nurseriesWithInvoices } = data;
          return (
            <FilterableNurseriesTable data={nurseriesWithInvoices}>
              {({ nurseriesList }) => {
                return (
                  <TableContainer component={TableWrapper}>
                    <Table stickyHeader size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell
                            style={{ borderTop: TableHeadBorder, borderLeft: TableHeadBorder }}
                          >
                            Nursery
                          </TableCell>
                          <TableCell
                            style={{ borderTop: TableHeadBorder, borderRight: TableHeadBorder }}
                          >
                            Active?
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {nurseriesList.map((nursery) => {
                          const { name, id, isActive } = nursery;
                          return (
                            <TableRow key={name} hover>
                              <TableCell>
                                <Link key={id} to={`/invoices/nursery/${id}/date/${params.date}`}>
                                  <MaterialUiLink component={Typography}>{name}</MaterialUiLink>
                                </Link>
                              </TableCell>
                              <TableCell>
                                <Chip
                                  variant="outlined"
                                  size="small"
                                  color={isActive ? "primary" : "secondary"}
                                  label={isActive ? "Active" : "Inactive"}
                                />
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                );
              }}
            </FilterableNurseriesTable>
          );
        }}
      </GraphqlQuery>
    </PageHeading>
  );
};
